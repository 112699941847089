import React from "react";
import {BrowserRouter, Route} from 'react-router-dom'
import routes from './components/pages/index'
import ReactGA from 'react-ga'

const TRACKING_ID = "G-6GC2BHVDRH"; // Google Analytics: derrickjfreeman@gmail.com - https://next.linestar.tech
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <BrowserRouter>
      {
        routes.map((data,idx) => (
          <Route exact path={data.path} component={data.component} key={idx}></Route>
        ))
      }
    </BrowserRouter>
  );
}


export default App;
