import React, { Component } from 'react';

class ContentBlog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blogs: [
                {
                    id: 1,
                    articles: 'hentry data-effect',
                    classnames:'post-media has-effect-icon offset-v-25 offset-h-24 data-effect-item clerafix',
                    srcimg: '/assets/img/gallery/3eba07b0de45b4513b2ca58b2a505b4ec7ca45ec467dfc1ac78687167c8429f6.jpg',
                    title: 'DATA CENTER HPC CONTROL AND COOLING WITH ROBOTIC BLADE CHANGER',
                    day: '30',
                    month: 'MAY ',
                    author: ' Frank Zeiler',
                    comments: '3 ',
                    descriptions: 'Data centers are way rad.',
                    link: '/blog/data-center-hpc-control-cooling-with-robotic-blade-changer'
                },
                {
                    id: 2,
                    srcimg: '/assets/img/gallery/040ca2c2212653c94ca7e222bf549797b6811414b34ca053cc64e94195a4e433.jpg',
                    articles: 'hentry data-effect',
                    classnames:'post-media data-effect-item has-effect-icon offset-v-25 offset-h-24clerafix',
                    title: 'POWER DISTRIBUTION, SAFETY, AND PROCESS CONTROL',
                    day: '29',
                    month: 'APR ',
                    author: ' Frank Zeiler',
                    comments: '4 ',
                    descriptions: 'Things I have learned working 40+ years in the automation industry about power distribution, safety, and process control.',
                    link: '/blog/power-distribution-safety-process-control'
                },
                {
                    id: 3,
                    srcimg: '/assets/img/gallery/57ad3b1b3d48ac02acad56e91b31c3bb5a63afc68e1baf7352a5b4e4e49eb6bb.jpg',
                    articles: 'hentry margin-top-33 padding-top-45 data-effect',
                    classnames:'post-media data-effect-item has-effect-icon offset-v-25 offset-h-24 clerafix',
                    title: 'BUILD A MICROGRID FOR UNDER $10,000',
                    day: '14',
                    month: 'MAR ',
                    author: ' Frank Zeiler',
                    comments: '10 ',
                    descriptions: 'How to build a microgrid on the cheap.',
                    link: '/blog/microgrid-under-10-thousand-dollars'
                }
            ]
        }
    }
    
    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    {
                        this.state.blogs.map(data =>(
                            <article className={data.articles} key={data.id}>
                                <div className={data.classnames}>
                                    <a href={data.link}><img src={data.srcimg} alt="altimage" /></a>
                                    <div className="post-calendar">
                                        <span className="inner">
                                            <span className="entry-calendar">
                                                <span className="day">{data.day}</span>
                                                <span className="month">{data.month}</span>
                                            </span>
                                        </span>
                                    </div>
                                    <div className="overlay-effect bg-color-1" />
                                    <div className="elm-a">
                                        <a href={data.link} className="icon-1" />
                                    </div>
                                </div>
                                <div className="post-content-wrap clearfix">
                                    <h2 className="post-title">
                                        <span className="post-title-inner">
                                            <a href={data.link}>{data.title}</a>
                                        </span>
                                    </h2>
                                    <div className="post-meta">
                                        <div className="post-meta-content">
                                            <div className="post-meta-content-inner">
                                                <span className="post-date item"><span className="inner"><span className="entry-date">{data.month}  
                                                    {data.day}, 2022</span></span></span>
                                                <span className="post-by-author item"><span className="inner"><a href={data.link}>By: 
                                                     {data.author}</a></span></span>
                                                {/* <span className="comment item"><span className="inner"><a href={data.link}>{data.comments}
                                                             Comments</a></span></span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-content post-excerpt">
                                        <p>{data.descriptions}</p>
                                    </div>
                                    <div className="post-read-more">
                                        <div className="post-a">
                                            <a href={data.link}>READ MORE</a>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        ))
                    }
                    <div className="themesflat-pagination clearfix">
                        <ul>
                            <li><a href="/blog" className="page-numbers prev"><span className="fa fa-angle-left" /></a></li>
                            <li><a href="/blog" className="page-numbers">1</a></li>
                            <li><a href="/blog/2" className="page-numbers current">2</a></li>
                            <li><a href="/blog/2" className="page-numbers next"><span className="fa fa-angle-right" /></a></li>
                        </ul>
                    </div>
                </div>
                <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
            </div>
        );
    }
}

export default ContentBlog;