import React, { Component } from 'react';
//import { Link } from 'react-router-dom';

class Project extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: [
                { //images best if 440x280
                    id: 1,
                    srcimg: 'assets/img/gallery/a1327e1e00a5de6a82caff13d41d4be8eb3966f26bdadd197c8a7311eb7a8f25.jpg',
                    heading: '6 AXIS VULCAN ROBOT',
                    meta: 'Control System',
                    metalink: '/services/control-systems',
                    text: 'Motion Controls',
                    textlink: '/services/motion-controls',
                    link: '/projects/3d-printer'
                },
                {
                    id: 2,
                    srcimg: 'assets/img/gallery/47aa2d63ccc1dbb4471163fbe2333e47cbd166eadfecee7d2d7ef9030ed821a7.jpg',
                    heading: 'ROBOTIC TESTING COBOTS',
                    meta: 'Motion Controls',
                    metalink: '/services/motion-controls',
                    text: 'Data Acquisition',
                    textlink: '/services/data-logging',
                    link: '/projects/cobots'
                },
                {
                    id: 3,
                    srcimg: 'assets/img/gallery/16594589d1a61fe144ac0acd823255b724bcb26debea1ac8cc0735052dc89335.jpg',
                    heading: 'HOLOGRAPHIC IMAGING',
                    meta: 'Power Distribution',
                    metalink: '/services/power-distribution',
                    text: 'Communications Interface',
                    textlink: '/services/communications-interfaces',
                    link: '/projects/zebra-imaging'
                },
                {
                    id: 4,
                    srcimg: 'assets/img/gallery/427035ae6c9a4dec209ead90a0e27356e0b075781a9612831ae445f29ea2bed8.jpg',
                    heading: 'MEDICAL LASER',
                    meta: 'Power Distribution',
                    metalink: '/services/power-distribution',
                    text: 'Data Acquisition',
                    textlink: '/services/data-logging',
                    link: '/projects/medical-laser'
                },
                {
                    id: 5,
                    srcimg: 'assets/img/gallery/27a8f81cf0427255e18c9291a26ca721d593b05de4a1e170aae0024bf90a5290.jpg',
                    heading: 'ROBOTIC INSPECTION SYSTEM',
                    meta: 'Control System',
                    metalink: '/services/control-systems',
                    text: 'Communications Interface',
                    textlink: '/services/communications-interfaces',
                    link: '/projects/inspection-system'
                },
                {
                    id: 6,
                    srcimg: 'assets/img/gallery/537440e75e5218ea51a6f6c1b6241e98004a0d969853e30c89e1b9a87bb0d03b.jpg',
                    heading: 'LASER NOTCHING MACHINE',
                    meta: 'Motion Controls',
                    metalink: '/services/motion-controls',
                    text: 'Safety Systems',
                    textlink: '/services/safety-systems',
                    link: '/projects/laser-notching'
                },
                {
                    id: 7,
                    srcimg: 'assets/img/gallery/a9dd432d893857798478ec9bfbfcdb27f2535d513dd63a554f144569911f0c29.jpg',
                    heading: 'ROBOTIC INSPECTION CELLS',
                    meta: 'Motion Controls',
                    metalink: '/services/motion-controls',
                    text: 'Data Acquisition',
                    textlink: '/services/data-logging',
                    link: '/projects/robotic-wafer'
                },
                {
                    id: 8,
                    srcimg: 'assets/img/gallery/efa49e7f80f04eca13016353ead86b94a35e74bdb068917681bc77dbe21d7350.jpg',
                    heading: '12 KWH INDUSTRIAL UPS SYSTEM',
                    meta: 'Energy Storage',
                    metalink: '/services/energy-storage',
                    text: 'Microgrid Controls',
                    textlink: '/services/microgrid-controls',
                    link: '/projects/ups-system'
                }
            ]
        }
    }
    render() {
        return (
            <div className="themesflat-carousel-box clearfix" data-gap={30} data-column={4} data-column2={2} data-column3={1}
                data-auto="false">
                <div className="owl-carousel owl-theme">
                    {
                        this.state.data.map(data => (
                            <div className="themesflat-project style-1 data-effect  clearfix" key={data.id}>
                                <div className="project-item">
                                    <div className="inner">
                                        <div className="thumb data-effect-item has-effect-icon w40 offset-v-43 offset-h-46">
                                            <img src={data.srcimg} alt="altimage" />
                                            <div className="text-wrap text-center">
                                                <h5 className="heading"><a href={data.link}>{data.heading}</a></h5>
                                                <div className="elm-meta">
                                                    <span>
                                                        <a href={data.metalink}>{data.meta}</a></span>
                                                    <span>
                                                        <a href={data.textlink}>{data.text}</a></span>
                                                </div>
                                            </div>
                                            <div className="elm-link">
                                                <a href={data.link} className="icon-1 icon-search"></a>
                                                <a href={data.link} className="icon-1"></a>
                                            </div>
                                            <div className="overlay-effect bg-color-3" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default Project;