import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/c8c2b35702b12223e154bcde543c0d85e5ad1ccfc2338fbb98040b4d72a58007.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/09c8ea0d565bcc7c5c2bb62b108dabd95fc35e7514dc62537660fdf1435db790.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/1451fe13987817439f836d0ca083a60d316538cfa02591d86874a3bc178a2cd7.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/e9424e7e500a114ca2fba1ef4ebed5e4c941c8c5e39a631477bcfac6b0e3c82b.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">AUTOMATED MAGNET MOVER</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">This is an automated magnet mover.
                        It moves a magnet from one location to another, and then back again, according to the user's instructions. It is operated via an API.
                    </p>
                    <p>This device was designed for a client who was using a manual process involving a human operator moving a magnetic wand. However, with
                        the automation this device brings, they were able to scale their product worldwide much cheaper without training operators.</p>
                        
                    <p>Also, the precision that an automated device like this brings is much higher than a human operator could ever achieve.</p>
                        
                </div>
            </div>
        );
    }
}

export default ContentProject;