import React, { Component } from 'react';

class SiteContent extends Component {
    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                    <div className="themesflat-carousel-box data-effect clearfix" data-gap={0} data-column={1} data-column2={1}
                        data-column3={1} data-auto="true">
                        <div className="owl-carousel owl-theme">
                            <div className="themesflat-gallery style-1 clearfix">
                                <div className="gallery-item">
                                    <div className="inner">
                                        <div className="thumb">
                                            <img src="assets/img/gallery/a46f150362d1d19c36d01145f611740dd7216d279e591a1a12a13d46b73a2409.jpg" alt="imagealt" />
                                            <div className="text-wrap">
                                                <span>We need you </span>
                                                <h5 className="heading">to join our team !</h5>
                                                <span>Apply now!</span>
                                                <a href="https://www.linkedin.com/in/frank-zeiler-b027985b" className="themesflat-button bg-accent">APPLY NOW</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="themesflat-gallery style-1 clearfix">
                                <div className="gallery-item">
                                    <div className="inner">
                                        <div className="thumb">
                                            <img src="assets/img/gallery/bdc960e60d5dc602ac51a5bf86fb61988cc52f2486bec09ba89d42c6b041a961.jpg" alt="imagealt" />
                                            <div className="text-wrap">
                                                <span>We need you </span>
                                                <h5 className="heading">to join our team !</h5>
                                                <span>Apply now!</span>
                                                <a href="https://www.linkedin.com/in/frank-zeiler-b027985b" className="themesflat-button bg-accent">APPLY NOW</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={29} data-mobile={35} data-smobile={35} />
                    <div className="themesflat-headings style-2 clearfix">
                        <h2 className="heading">WE ARE HIRING</h2>
                        <div className="sep has-width w80 accent-bg clearfix" />
                        <p className="sub-heading font-size-16 line-height-28 text-666 margin-top-27 letter-spacing-01">Linestar is 
                            a great place to work for people who are engineering-minded, creative types who build things and invent 
                            new ways of solving problems with robots and automated machines. We also employ software developers to 
                            write code that analyzes machines output and productivity.</p>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={22} data-mobile={22} data-smobile={22} />
                    <div className="themesflat-accordions style-4 has-icon icon-right iconstyle-1 clearfix">
                        <div className="accordion-item active">
                            <h3 className="accordion-heading"><span className="inner">SALES ACCOUNT</span></h3>
                            <div className="accordion-content clearfix">
                                <div className="themesflat-row clearfix">
                                    <div className="span_1_of_6">
                                        <div className="themesflat-content-box clearfix" data-margin="0 22px 0 0px"
                                            data-mobilemargin="0 22px 0 0px">
                                            <h6 className="name">Linestar Sales Rep</h6>
                                            <p>Linestar helps businesses make more money by automating processes, protect their staff
                                                and machinery investments, and stay competitive by lowering costs.</p>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <h6 className="name">We also offer the follow benefits:</h6>
                                            <div className="themesflat-list has-icon style-2 icon-left clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Bonus program for the shift teams</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left  clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Flexible working hours</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Employer contribution for 401k</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Compensation for travel expenses</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="span_1_of_6">
                                        <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0}
                                            data-smobile={35} />
                                        <div className="themesflat-content-box clearfix" data-margin="0 12px 0 0px"
                                            data-mobilemargin="0 0 0 0">
                                            <h6 className="name">Requirements</h6>
                                            <div className="themesflat-list has-icon style-2 icon-left align-top clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">1-4 years of experience in Sales or Management</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left align-top clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Tech-oriented. You don’t need to be a developer, but
                                                            have to be passionate about tech, robotics, automation, and all its
                                                            applications.</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left align-top clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Excellent communication and negotiation skills.
                                                            Clear and convincing when you speak. Good listener.</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left align-top clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">You enjoy talking to clients, understanding their
                                                            problems, and closing deals.</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h3 className="accordion-heading"><span className="inner">BUSINESS MANAGER</span></h3>
                            <div className="accordion-content clearfix">
                                <div className="themesflat-row clearfix">
                                    <div className="span_1_of_6">
                                        <div className="themesflat-content-box clearfix" data-margin="0 22px 0 0px"
                                            data-mobilemargin="0 22px 0 0px">
                                            <h6 className="name">Linestar Team Lead</h6>
                                            <p>Seeking a professional who is responsible for leading and supervising staff
                                                to ensure productivity, efficiency of operations, and provide direction on
                                                how best to handle different tasks while maintaining customer satisfaction.</p>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <h6 className="name">We also offer the follow benefits:</h6>
                                            <div className="themesflat-list has-icon style-2 icon-left clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Bonus program</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left  clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Flexible working hours</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Employer contribution for 401k
                                                            </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Compensation for transportation</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="span_1_of_6">
                                        <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0}
                                            data-smobile={35} />
                                        <div className="themesflat-content-box clearfix" data-margin="0 12px 0 0px"
                                            data-mobilemargin="0 0 0 0">
                                            <h6 className="name">Requirements</h6>
                                            <div className="themesflat-list has-icon style-2 icon-left align-top clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">1-4 years of experience in Management</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left align-top clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Tech-oriented. You don’t need to be a developer, but
                                                            have to be passionate about tech, robotics, engineering, and all its
                                                            applications.</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left align-top clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Excellent communication and time management skills.
                                                            Compassionate and empathetic toward fellow teammates.</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left align-top clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">You enjoy analyzing large tasks and breaking them 
                                                            down into various components to manage team success.</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h3 className="accordion-heading"><span className="inner">SALES MANAGER</span></h3>
                            <div className="accordion-content clearfix">
                                <div className="themesflat-row clearfix">
                                    <div className="span_1_of_6">
                                        <div className="themesflat-content-box clearfix" data-margin="0 22px 0 0px"
                                            data-mobilemargin="0 22px 0 0px">
                                            <h6 className="name">Linestar Sales Lead</h6>
                                            <p>Lead the Linestar sales team of 3-4 people, provide guidance, training, and
                                                mentorship, setting sales quotas and goals, creating sales plans, analyzing
                                                data, assigning sales territories and building the team.</p>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <h6 className="name">We also offer the follow benefits:</h6>
                                            <div className="themesflat-list has-icon style-2 icon-left clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Bonus program</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left  clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Flexible working hours</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Employer contribution for 401k
                                                            </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Compensation for transportation</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="span_1_of_6">
                                        <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0}
                                            data-smobile={35} />
                                        <div className="themesflat-content-box clearfix" data-margin="0 12px 0 0px"
                                            data-mobilemargin="0 0px 0 0px">
                                            <h6 className="name">Requirements</h6>
                                            <div className="themesflat-list has-icon style-2 icon-left align-top clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">1-4 years of experience in Sales Management</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left align-top clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Experienced or willing to learn about automation
                                                            technologies, standard lingo and jargon used by robotics engineers.
                                                            </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left align-top clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Excellent communication and negotiation skills.
                                                            Fluent in industry terminology. Clear and convincing when you speak.</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left align-top clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">You enjoy talking to clients, understanding their
                                                            problems, and closing deals.</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h3 className="accordion-heading"><span className="inner">TECHNICAL MANAGER</span></h3>
                            <div className="accordion-content clearfix">
                                <div className="themesflat-row clearfix">
                                    <div className="span_1_of_6">
                                        <div className="themesflat-content-box clearfix" data-margin="0 22px 0 0px"
                                            data-mobilemargin="0 22px 0 0px">
                                            <h6 className="name">Linestar Tech Manager</h6>
                                            <p>This is a new position for Linestar. We need someone who can provide support 
                                                and vision for our growing technical needs and working hands-on with projects.</p>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <h6 className="name">We also offer the follow benefits:</h6>
                                            <div className="themesflat-list has-icon style-2 icon-left clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Bonus program</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left  clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Flexible working hours</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Employer contribution for 401k
                                                            </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Reimbursement for work-related travel</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="span_1_of_6">
                                        <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0}
                                            data-smobile={35} />
                                        <div className="themesflat-content-box clearfix" data-margin="0 12px 0 0px"
                                            data-mobilemargin="0 0 0 0">
                                            <h6 className="name">Requirements</h6>
                                            <div className="themesflat-list has-icon style-2 icon-left align-top clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">1-4 years of experience in Automation</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left align-top clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Tech-oriented. You don’t need to be a developer, but
                                                            have to be passionate about tech, web, e-commerce, and all its
                                                            applications.</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left align-top clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">Extremely knowledgeable about the latest technologies.
                                                            Able to recommend efficiency improvements for automation projects.</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10}
                                                data-smobile={10} />
                                            <div className="themesflat-list has-icon style-2 icon-left align-top clearfix">
                                                <div className="inner">
                                                    <span className="item">
                                                        <span className="icon"><i className="fa fa-check" /></span>
                                                        <span className="text">You enjoy learning new ways of doing things, 
                                                            experimenting, and implementing better, faster, smarter solutions.</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={78} data-mobile={60} data-smobile={60} />
                </div>
            </div>
        );
    }
}

export default SiteContent;