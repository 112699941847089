import Home01 from './Home01'
import Home02 from './Home02'
import AboutUs from './AboutUs'
import AboutDetails from './AboutDetails'
import AboutTeam from './AboutTeam'
import AboutCareers from './AboutCareers'
import Services from './Services'
import ServiceDetail from './ServiceDetail'
import ServiceDetailSafetySystems from './Services/ServiceDetailSafetySystems'
import ServiceDetailMotionControls from './Services/ServiceDetailMotionControls'
import ServiceDetailControlSystems from './Services/ServiceDetailControlSystems'
import ServiceDetailBlockchainStorage from './Services/ServiceDetailBlockchainStorage'
import SoftwareServices from './Services/ServiceDetailSoftwareServices'
import ServiceDetailPartsSuppliesDIY from './Services/ServiceDetailPartsSuppliesDIY'
import ServiceDetailPowerDistribution from './Services/ServiceDetailPowerDistribution'
import ServiceDetailDataLogging from './Services/ServiceDetailDataLogging'
import ServiceDetailCommunicationInterfaces from './Services/ServiceDetailCommunicationInterfaces'
import ServiceDetailMicrogridSystems from './Services/ServiceDetailMicrogridSystems'
import ServiceDetailInstallationAssembly from './Services/ServiceDetailInstallationAssembly'
import ServiceDetailCustomRobotics from './Services/ServiceDetailCustomRobotics'
//import Projects from './Projects'
import ProjectFullWidth from './ProjectFullWidth'
import ProjectDetail from './ProjectDetail'
import ProjectDetailZebraImaging from './Projects/ProjectDetailZebraImaging'
import ProjectDetail3DDisplay from './Projects/ProjectDetail3DDisplay'
import ProjectDetailUVCuring from './Projects/ProjectDetailUVCuring'
import ProjectDetailInspectionSystem from './Projects/ProjectDetailInspectionSystem'
import ProjectDetail4Axis from './Projects/ProjectDetail4Axis'
import ProjectDetailNOVTank from './Projects/ProjectDetailNOVTank'
import ProjectDetailEnergySwitch from './Projects/ProjectDetailEnergySwitch'
import ProjectDetailNanogrid from './Projects/ProjectDetailNanogrid'
import ProjectDetailMedicalLaser from './Projects/ProjectDetailMedicalLaser'
import ProjectDetailRoboticWafer from './Projects/ProjectDetailRoboticWafer'
import ProjectDetailLaserNotching from './Projects/ProjectDetailLaserNotching'
import ProjectDetailPowerSafety from './Projects/ProjectDetailPowerSafety'
import ProjectDetailWinderControls from './Projects/ProjectDetailWinderControls'
import ProjectDetailUPSSystem from './Projects/ProjectDetailUPSSystem'
import ProjectDetail3DPrinter from './Projects/ProjectDetail3DPrinter'
import ProjectDetailEdgeBox from './Projects/ProjectDetailEdgeBox'
import ProjectDetailOtto from './Projects/ProjectDetailOtto'
import ProjectDetailMagnetMover from './Projects/ProjectDetailMagnetMover'
import ProjectDetailHPCBlades from './Projects/ProjectDetailHPCBlades'
import ProjectDetail2PhaseCooling from './Projects/ProjectDetail2PhaseCooling'
import ProjectDetailCoBots from './Projects/ProjectDetailCoBots'
import ProjectDetailSolArk from './Projects/ProjectDetailSolArk'
import ProjectDetailHomeGrid from './Projects/ProjectDetailHomeGrid'
import ProjectDetailPowerServer from './Projects/ProjectDetailPowerServer'
import Testimonial from './Testimonial'
import PricePage from './PricePage'
//import Blog from './Blog'
import Blog01 from './Blog01'
import Blog02 from './Blog02'
import BlogSingle from './BlogSingle'
import BlogSingle001 from './BlogSingle001'
import BlogSingle002 from './BlogSingle002'
import BlogSingle003 from './BlogSingle003'
import BlogSingle004 from './BlogSingle004'
import BlogSingle005 from './BlogSingle005'
import BlogSingle006 from './BlogSingle006'
import Contact01 from './Contact01'
import Contact02 from './Contact02'

const routes = [
    { path: '/', component: Home01},
    { path: '/home-02', component: Home02},
    { path: '/about-us', component: AboutUs},
    { path: '/about-detail', component: AboutDetails},
    { path: '/about-team', component: AboutTeam},
    { path: '/about-careers', component: AboutCareers},
    { path: '/services', component: Services},
    { path: '/services-detail', component: ServiceDetail},
    { path: '/services/safety-systems', component: ServiceDetailSafetySystems},
    { path: '/services/motion-controls', component: ServiceDetailMotionControls},
    { path: '/services/control-systems', component: ServiceDetailControlSystems},
    { path: '/services/blockchain-storage', component: ServiceDetailBlockchainStorage},
    { path: '/services/software-services', component: SoftwareServices},
    { path: '/services/parts-supplies-diy', component: ServiceDetailPartsSuppliesDIY},
    { path: '/services/power-distribution', component: ServiceDetailPowerDistribution},
    { path: '/services/data-logging', component: ServiceDetailDataLogging},
    { path: '/services/communication-interfaces', component: ServiceDetailCommunicationInterfaces},
    { path: '/services/microgrid-controls', component: ServiceDetailMicrogridSystems},
    { path: '/services/installation-assembly', component: ServiceDetailInstallationAssembly},
    { path: '/services/custom-robotics', component: ServiceDetailCustomRobotics},
    //{ path: '/projects', component: Projects},
    { path: '/projects', component: ProjectFullWidth},
    { path: '/project-detail', component: ProjectDetail},
    { path: '/projects/zebra-imaging', component: ProjectDetailZebraImaging},
    { path: '/projects/3d-display', component: ProjectDetail3DDisplay},
    { path: '/projects/uv-curing', component: ProjectDetailUVCuring},
    { path: '/projects/inspection-system', component: ProjectDetailInspectionSystem},
    { path: '/projects/4-axis', component: ProjectDetail4Axis},
    { path: '/projects/nov-tank', component: ProjectDetailNOVTank},
    { path: '/projects/energy-switch', component: ProjectDetailEnergySwitch},
    { path: '/projects/nanogrid', component: ProjectDetailNanogrid},
    { path: '/projects/medical-laser', component: ProjectDetailMedicalLaser},
    { path: '/projects/robotic-wafer', component: ProjectDetailRoboticWafer},
    { path: '/projects/laser-notching', component: ProjectDetailLaserNotching},
    { path: '/projects/power-safety', component: ProjectDetailPowerSafety},
    { path: '/projects/winder-controls', component: ProjectDetailWinderControls},
    { path: '/projects/ups-system', component: ProjectDetailUPSSystem},
    { path: '/projects/3d-printer', component: ProjectDetail3DPrinter},
    { path: '/projects/edge-box', component: ProjectDetailEdgeBox},
    { path: '/projects/otto', component: ProjectDetailOtto},
    { path: '/projects/magnet-mover', component: ProjectDetailMagnetMover},
    { path: '/projects/hpc-blades', component: ProjectDetailHPCBlades},
    { path: '/projects/2-phase-cooling', component: ProjectDetail2PhaseCooling},
    { path: '/projects/cobots', component: ProjectDetailCoBots},
    { path: '/projects/sol-ark', component: ProjectDetailSolArk},
    { path: '/projects/homegrid', component: ProjectDetailHomeGrid},
    { path: '/projects/power-server', component: ProjectDetailPowerServer},
    { path: '/page-testimonial', component: Testimonial},
    { path: '/page-pricing', component: PricePage},
    //{ path: '/blog', component: Blog},
    { path: '/blog', component: Blog01},
    { path: '/blog/2', component: Blog02},
    { path: '/blog-single', component: BlogSingle},
    { path: '/blog/microgrid-under-10-thousand-dollars', component: BlogSingle001},
    { path: '/blog/power-distribution-safety-process-control', component: BlogSingle002},
    { path: '/blog/data-center-hpc-control-cooling-with-robotic-blade-changer', component: BlogSingle003},
    { path: '/blog/what-we-saw-at-the-robotics-convention-dallas-texas', component: BlogSingle004},
    { path: '/blog/osha-violation-3-steps-compliance', component: BlogSingle005},
    { path: '/blog/things-to-consider-microgrid', component: BlogSingle006},
    { path: '/contact-01', component: Contact01},
    { path: '/contact-us', component: Contact02},
]

export default routes;