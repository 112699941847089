import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
class SiteContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: [
                {
                    id: 1,
                    text01: 'Knowing that this is the area of the machine with which you and your employees will have the most interaction over the next 10 years, we always use the highest quality, yet cost-effective, time-tested industrial touchscreens in the LARGEST format practical for your application.',
                    text02: 'We use LARGE TEXT and few words. Simple designs and colors keep your interactions with the machine quick and easy.'
                },
                {
                    id: 2,
                    text01: 'Our lead software technician takes his time understanding the big picture of your automated process and the various ways you will interact with the control system.',
                    text02: 'Then he thoughtfully designs the User Interface, taking into account a decade of experience and utilizing best practices. We always give the client ample opportunities to provide input at this stage to ensure 100% satisfaction.'
                },
                {
                    id: 3,
                    text01: 'The Communication Interface is important, and if we do our jobs correctly, it will blend in with your daily processes without a thought.',
                    text02: 'Few people write positive reviews about Communication Interfaces, but we think that if they did, ours would be at the top of the list.'
                }
            ]
        }
    }
    
    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                    <div className="themesflat-row equalize sm-equalize-auto clearfix">
                        <div className="span_1_of_6 bg-f7f">
                            <div className="themesflat-spacer clearfix" data-desktop={60} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-content-box clearfix" data-margin="0 10px 0 43px"
                                data-mobilemargin="0 15px 0 15px">
                                <div className="themesflat-headings style-2 clearfix">
                                    <div className="sup-heading">SPECIAL SERVICES</div>
                                    <h2 className="heading font-size-28 line-height-39">COMMUNICATION INTERFACES</h2>
                                    <div className="sep has-width w80 accent-bg margin-top-20 clearfix" />
                                    <p className="sub-heading margin-top-33 line-height-24">Almost every job you could hire Linestar to do 
                                        will involve a communication interface of some kind. These days, touchscreens are easy to add to 
                                        almost any automation system. We can also add large screens and keyboards for more advanced controls.
                                        </p>
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={56} data-mobile={56} data-smobile={56} />
                        </div>
                        <div className="span_1_of_6 half-background style-2">
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={39} data-smobile={39} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title">GET CONTROL OVER YOUR PROCESSES</h5>
                        <p> The Communication Interface is an essential part of any modern control system. In the old days, these were large clunky buttons and switches 
                            that were confusing to operate. Today, you get finer granularity and control over all the details of your system with a large, easy-to-use 
                            touchscreen. We integrate the top quality devices at the lowest cost, and we design the interfaces customized to your process so that anyone 
                            on your team can operate the control system effectively, diagnose and resolve issues, and perform data acquisition to know the health and 
                            effectiveness of your entire process at all times.
                        </p>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <div className="item">
                            <div className="inner">
                                <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10} data-smobile={10} />
                                <h5 className="title">PROVEN SUCCESS:</h5>
                                <p>We have successfully completed projects in numerous states across the continental United States.
                                    Linestar Automation has a proven track record of success building applications for highly demanding clients:</p>
                                <div className="themesflat-spacer clearfix" data-desktop={8} data-mobile={8} data-smobile={8} />
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">US Military</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">US FDA</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">US Dept of Energy</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">Global Manufacturing</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={35} />
                            <div className="themesflat-content-box clearfix" data-margin="0 0 0 11px" data-mobilemargin="0 0 0 0">
                                <div className="inner pd35 bg-ffc">
                                    <h5 className="title text-white line-height-24 padding-left-7">CHECK OUT SOME OF OUR FANTASTIC  
                                        COMMUNICATION INTERFACES WORK FOR OTHER CLIENTS</h5>
                                    <div className="themesflat-spacer clearfix" data-desktop={16} data-mobile={16}
                                        data-smobile={16} />
                                    <div className="button-wrap has-icon icon-right size-14">
                                    <a 
                                            href="../../../../assets/img/gallery/6269b81990a346bfb51ca82ced895e337e74ce2b492831b1d31885aa7f23f9e5.pdf"
                                            className="themesflat-button bg-white color-333 w100 font-weight-400 no-letter-spacing pd26"
                                            download="Linestar.pdf"
                                        >
                                            <span>Download .PDF 
                                                <span className="icon">
                                                    <i className="fa fa-file-pdf-o" />
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                    <div className="themesflat-spacer clearfix" data-desktop={6} data-mobile={6} data-smobile={6} />
                                    <div className="button-wrap has-icon icon-right size-14">
                                        <a 
                                            href="../../../../assets/img/gallery/9ddbf9ae73b482ff90953aed2a15ee578d9455f0e3474a98c85faf287bc926d6.docx"
                                            className="themesflat-button bg-white color-333 w100 font-weight-400 no-letter-spacing pd26 "
                                            download="Linestar.docx"
                                        >
                                            <span>Download .DOC 
                                                <span className="icon">
                                                    <i className="fa fa-file-word-o" />
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title no-letter-spacing">CONTACT US FOR COMMUNICATION INTERFACE SERVICES TODAY</h5>
                        <div className="themesflat-spacer clearfix" data-desktop={27} data-mobile={27} data-smobile={27} />
                        <div className="themesflat-tabs style-2 title-w170 clearfix">
                            <ul className="tab-title clearfix ">
                                <li className="item-title active">
                                    <span className="inner">SIZING</span>
                                </li>
                                <li className="item-title">
                                    <span className="inner">CONTROLS</span>
                                </li>
                                <li className="item-title">
                                    <span className="inner">EASY</span>
                                </li>
                            </ul>
                            <div className="tab-content-wrap clearfix">
                                {
                                    this.state.tabs.map(data =>(
                                        <div className="tab-content" key={data.id}>
                                            <div className="item-content">
                                                <p>{data.text01}</p>
                                                <p>{data.text02}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                </div>
            </div>
        );
    }
}

export default SiteContent;