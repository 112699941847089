import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ServicesBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardbox: [
                {
                    id: 1,
                    colspan: [
                        {
                            idx: 1,
                            icon: 'autora-icon-build',
                            title: 'MOTION CONTROLS',
                            text: 'We automate your processes down to the finest detail to ensure uniform quality throughout your production.'
                        }
                    ],
                    colleft: [
                        {
                            idx: 1,
                            icon: 'autora-icon-hat-outline',
                            title: 'SAFETY SYSTEMS',
                            text: 'We make your business OSHA-compliant. With automated safety systems, your staff can perform at their best.',
                        }
                    ]
                },
                {
                    id: 2,
                    colspan: [
                        {
                            idx: 1,
                            icon: 'autora-icon-author-outline',
                            title: 'CONTROL SYSTEMS',
                            text: 'Keep your staff, building, and machinery running smoothly with smart control systems and automatic triggers.'
                        }
                    ],
                    colleft: [
                        {
                            idx: 1,
                            icon: 'autora-icon-build-home',
                            title: 'POWER DISTRIBUTION',
                            text: 'Protect your valuable investment in high-end machinery with fail-safe power distribution. Also for Microgrids.',
                        }
                    ]
                },
                {
                    id: 3,
                    colspan: [
                        {
                            idx: 1,
                            icon: 'autora-icon-building-outline',
                            title: 'DATA LOGGING',
                            text: 'Instantly know everything happening with your machines. We customize to your needs and guarantee data integrity.'
                        }
                    ],
                    colleft: [
                        {
                            idx: 1,
                            icon: 'autora-icon-hat-outline',
                            title: 'COMMUNICATION INTERFACES',
                            text: 'Get total control over your machines with easy touchscreen interfaces. We make your machines talk to each other.',
                        }
                    ]
                }
            ]
        }
    }
    render() {
        return (
            <div className="themesflat-row gutter-30 clearfix">
                {
                    this.state.cardbox.map(data => (
                        <div className="col span_1_of_4" key={data.id}>
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={35} data-smobile={35} />
                            {
                                data.colspan.map( spandata =>(
                                    <div className="themesflat-icon-box icon-left accent-color style-2 clearfix" key={spandata.idx}>
                                        <div className="icon-wrap">
                                            <i className={spandata.icon} />
                                        </div>
                                        <div className="text-wrap">
                                            <h5 className="heading"><Link to="#">{spandata.title}</Link></h5>
                                            <p className="sub-heading">{spandata.text}</p>
                                        </div>
                                    </div>
                                ))
                            }
                            <div className="themesflat-spacer clearfix" data-desktop={62} data-mobile={35} data-smobile={35} />
                            {
                                data.colleft.map(leftcol => (
                                    <div className="themesflat-icon-box icon-left accent-color style-2 clearfix" key={leftcol.idx}>
                                        <div className="icon-wrap font-size-35 line-height-50">
                                            <i className={leftcol.icon}/>
                                        </div>
                                        <div className="text-wrap">
                                            <h5 className="heading"><Link to="#">{leftcol.title}</Link></h5>
                                            <p className="sub-heading margin-top-18">{leftcol.text}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default ServicesBox;