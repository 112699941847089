import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
class SiteContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: [
                {
                    id: 1,
                    text01: 'Every project begins with a simple email or phone call. One of our expert design staff is waiting to listen to your new idea for automating your manufacturing process, quality control, or increasing reliability of your existing machines by adding system controls, power distribution, data logging, and more.',
                    text02: 'Someone is standing by to hear from you. Please give us a call or send and email and include as much detail as possible. Automation is the best way to increase your revenue.'
                },
                {
                    id: 2,
                    text01: 'Once our prototyping team understands your goals, we get right to work building the perfect custom solution in our warehouse. Our team of builders works quickly and has thousands of materials at hand from which to compose a new project.',
                    text02: 'We work closely with you to ensure that every prototype is exactly as designed to ensure 100% satisfaction. Depending on project complexity, we may communicate with you several times throughout this part of the process.'
                },
                {
                    id: 3,
                    text01: 'As soon as a prototype is complete, we present it to you over video to get your live feedback. Our goal is total perfection, so we listen closely during the Presentation phase. There may be one or more of our team members on the call so we can be sure to most accurately bring your concept into reality.',
                    text02: 'Clients report total satisfaction with our prototype on the first presentation about 99% of the time. In other cases, we quickly return to work while your feedback is fresh in our minds and come back with another design for your review in less than a week.'
                }
            ]
        }
    }
    
    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                    <div className="themesflat-row equalize sm-equalize-auto clearfix">
                        <div className="span_1_of_6 bg-f7f">
                            <div className="themesflat-spacer clearfix" data-desktop={60} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-content-box clearfix" data-margin="0 10px 0 43px"
                                data-mobilemargin="0 15px 0 15px">
                                <div className="themesflat-headings style-2 clearfix">
                                    <div className="sup-heading">SPECIAL SERVICES</div>
                                    <h2 className="heading font-size-28 line-height-39">PROTOTYPING</h2>
                                    <div className="sep has-width w80 accent-bg margin-top-20 clearfix" />
                                    <p className="sub-heading margin-top-33 line-height-24">Our team of industrial prototyping experts
                                        have over 70 years of combined experience. We listen carefully to understand your goals  
                                        and then use materials sourced to exact specifications to build the perfect solution for your needs.
                                        </p>
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={56} data-mobile={56} data-smobile={56} />
                        </div>
                        <div className="span_1_of_6 half-background style-2">
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={39} data-smobile={39} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title">SERVICE CONSULTANT</h5>
                        <p> Throughout our Prototyping process, you get a single point of contact who is responsible to you, the client.
                            We work to develop and understand project expectations and then manage those needs with a customized, qualified
                            design team. Innovation happens throughout a project, so your dedicated Linestar Service Consultant stays on your 
                            project through every step of the process. That ensures that whether you stop after a completed design, or whether you 
                            continue on with Linestar through prototyping, building, and installation, you have someone who understands the big picture. 
                            This approach is keeps projects moving quickly and also uncovers unique ideas and opportunities for improvement before the next phase begins.
                        </p>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <div className="item">
                            <div className="inner">
                                <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10} data-smobile={10} />
                                <h5 className="title">PROVEN SUCCESS:</h5>
                                <p>We have successfully completed projects in numerous states across the continental United States.
                                    Linestar Automation has a proven track record of success building applications for highly demanding clients:</p>
                                <div className="themesflat-spacer clearfix" data-desktop={8} data-mobile={8} data-smobile={8} />
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">US Military</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">US FDA</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">US Dept of Energy</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">Global Manufacturing</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={35} />
                            <div className="themesflat-content-box clearfix" data-margin="0 0 0 11px" data-mobilemargin="0 0 0 0">
                                <div className="inner pd35 bg-ffc">
                                    <h5 className="title text-white line-height-24 padding-left-7">CHECK OUT SOME OF OUR FANTASTIC  
                                        CUSTOM ROBOTICS WORK FOR OTHER CLIENTS</h5>
                                    <div className="themesflat-spacer clearfix" data-desktop={16} data-mobile={16}
                                        data-smobile={16} />
                                    <div className="button-wrap has-icon icon-right size-14">
                                    <a 
                                            href="../../../../assets/img/gallery/6269b81990a346bfb51ca82ced895e337e74ce2b492831b1d31885aa7f23f9e5.pdf"
                                            className="themesflat-button bg-white color-333 w100 font-weight-400 no-letter-spacing pd26"
                                            download="Linestar.pdf"
                                        >
                                            <span>Download .PDF 
                                                <span className="icon">
                                                    <i className="fa fa-file-pdf-o" />
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                    <div className="themesflat-spacer clearfix" data-desktop={6} data-mobile={6} data-smobile={6} />
                                    <div className="button-wrap has-icon icon-right size-14">
                                        <a 
                                            href="../../../../assets/img/gallery/9ddbf9ae73b482ff90953aed2a15ee578d9455f0e3474a98c85faf287bc926d6.docx"
                                            className="themesflat-button bg-white color-333 w100 font-weight-400 no-letter-spacing pd26 "
                                            download="Linestar.docx"
                                        >
                                            <span>Download .DOC 
                                                <span className="icon">
                                                    <i className="fa fa-file-word-o" />
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title no-letter-spacing">CONTACT US FOR DESIGN-BUILD SERVICES TODAY</h5>
                        <div className="themesflat-spacer clearfix" data-desktop={27} data-mobile={27} data-smobile={27} />
                        <div className="themesflat-tabs style-2 title-w170 clearfix">
                            <ul className="tab-title clearfix ">
                                <li className="item-title active">
                                    <span className="inner">CONCEPT</span>
                                </li>
                                <li className="item-title">
                                    <span className="inner">BUILDING</span>
                                </li>
                                <li className="item-title">
                                    <span className="inner">PRESENTATION</span>
                                </li>
                            </ul>
                            <div className="tab-content-wrap clearfix">
                                {
                                    this.state.tabs.map(data =>(
                                        <div className="tab-content" key={data.id}>
                                            <div className="item-content">
                                                <p>{data.text01}</p>
                                                <p>{data.text02}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                </div>
            </div>
        );
    }
}

export default SiteContent;