import React, { Component } from 'react';

class ServiceSlide extends Component {
    render() {
        return (
            <div className="row-services">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="themesflat-spacer clearfix" data-desktop={81} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-carousel-box data-effect clearfix" data-gap={30} data-column={3}
                                data-column2={2} data-column3={1} data-auto="true">
                                <div className="owl-carousel owl-theme">
                                    
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src="assets/img/gallery/2aa77b105ceb66e1c00183e68690535c65be6c244848089a5b939fe8e3ce400e.jpg" alt="Safety System" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h5 className="heading"><a href="/services/safety-systems">SAFETY SYSTEMS</a></h5>
                                                    <p className="letter-spacing-01">We specialize in designing unique solutions
                                                    to automate your processes, measure output, and ensure uniform quality. [...]</p>
                                                    <div className="elm-readmore">
                                                        <a href="/services/safety-systems">DETAILS</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src="assets/img/gallery/bcf6bd4242540e47d278b94058a6587695f04c620e7f783cdc5e7adc32c12c64.jpg" alt="power distribution" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h5 className="heading"><a href="/services/power-distribution">POWER DISTRIBUTION</a></h5>
                                                    <p className="letter-spacing-01">Take in power from various sources and intelligently 
                                                    allocate it where it needs to go with safety and efficiency. [...]</p>
                                                    <div className="elm-readmore">
                                                        <a href="/services/power-distribution">DETAILS</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src="assets/img/gallery/7f442137ad2300678e26ab4067f6bf5f6dc310c45740248cba06fc17ed212e3e.jpg" alt="altimage" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h5 className="heading"><a href="/services/control-systems">CONTROL SYSTEMS</a></h5>
                                                    <p className="letter-spacing-01">Keep your staff, building, and machinery 
                                                    safe and running smoothly with smart control systems and automatic triggers. [...]</p>
                                                    <div className="elm-readmore">
                                                        <a href="/services/control-systems">DETAILS</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src="assets/img/gallery/6bddc7697419ed3866743c9e6c1fad6e1cbdac45d674301da2422d51ac648fe0.jpg" alt="Numbers Data on Screen" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h5 className="heading"><a href="/services/data-logging">DATA LOGGING</a></h5>
                                                    <p className="letter-spacing-01">All our products come with some amount of data 
                                                        logging. We customize output for your needs and guarantee accuracy. [...]</p>
                                                    <div className="elm-readmore">
                                                        <a href="/services/data-logging">DETAILS</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src="assets/img/gallery/528de449f660c0ae0a74afe841a42defcfb7a03666616c8f83ea7628c1cefc3c.jpg" alt="touchscreen" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h5 className="heading"><a to="/services/communication-interfaces">COMMUNICATION INTERFACES</a></h5>
                                                    <p className="letter-spacing-01">We make your machines talk to each other and empower 
                                                        you to control everything from one easy-to-use touch screen. [...]</p>
                                                    <div className="elm-readmore">
                                                        <a href="/services/communication-interfaces">DETAILS</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src="assets/img/gallery/89614490d7533ac4a579d22aae8e7fa272e466913226d72cd4bed1c7e0b7d4ec.jpg" alt="Man with Keyboard" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h5 className="heading"><a to="/services/prototyping">PROTOTYPING</a></h5>
                                                    <p className="letter-spacing-01">Once designed, we build out a prototype of the solution. 
                                                        If you already have a design, we can prototype it for you. [...]</p>
                                                    <div className="elm-readmore">
                                                        <a href="/services/prototyping">DETAILS</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={15} data-mobile={15} data-smobile={15} />
                            <div className="themesflat-carousel-box data-effect clearfix" data-gap={30} data-column={3}
                                data-column2={2} data-column3={1} data-auto="true">
                                <div className="owl-carousel owl-theme">
                                    
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src="assets/img/gallery/276cc23810c460dfe9a1587c272877602096b7c97a1e5c656d5c0f6b86adfbff.jpg" alt="Microgrid" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h5 className="heading"><a href="#">MICROGRID SYSTEMS</a></h5>
                                                    <p className="letter-spacing-01">Linestar offers everything a home or small business
                                                        needs to safely operate its own micro-grid in case the worst happens. [...]</p>
                                                    <div className="elm-readmore">
                                                        <a href="/services/microgrid-controls">DETAILS</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src="assets/img/gallery/961fda2ed1f8953f9d47bc2d7f288ac47b77db0cc229fb17dfa467b10f3c9713.jpg" alt="blockchain" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h5 className="heading"><a href="/services/blockchain-storage">BLOCKCHAIN STORAGE</a></h5>
                                                    <p className="letter-spacing-01">We offer you the ability to store your data 
                                                        securely on a blockchain so its integrity is guaranteed. [...]</p>
                                                    <div className="elm-readmore">
                                                        <a href="/services/blockchain-storage">DETAILS</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src="assets/img/gallery/35fc56512bed88c7d7ea2f65bf60e3da9857109ee79aeba8ae8e0b456713a74e.jpg" alt="Computer Code" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h5 className="heading"><a href="/services/software-services">SOFTWARE SERVICES</a></h5>
                                                    <p className="letter-spacing-01">We can write custom software to enable better 
                                                        communication between all your machines to make time more productive. [...]</p>
                                                    <div className="elm-readmore">
                                                        <a href="/services/software-services">DETAILS</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src="assets/img/gallery/8f6ece025f119df84aa14e23b37ef454528120e2bf7507f6dee7d4eeecebd986.jpg" alt="DIY-er" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h5 className="heading"><a href="/services/parts-supplies-diy">PARTS, SUPPLIES, & DIY</a></h5>
                                                    <p className="letter-spacing-01">You might have the knowledge and skills to do it
                                                        yourself. In that case, we have parts on hand for all your robotics needs. [...]</p>
                                                    <div className="elm-readmore">
                                                        <a href="/services/parts-supplies-diy">DETAILS</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src="assets/img/gallery/8b183668ec8654f18130ba84ce050f2245fb8981c6e8b65f96440e7ae6b86050.jpg" alt="technician" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h5 className="heading"><a href="#">INSTALLATION & ASSEMBLY</a></h5>
                                                    <p className="letter-spacing-01">We have installed thousands of industrial machines
                                                        everywhere from large manufacturing warehouses to small doctor's offices. [...]</p>
                                                    <div className="elm-readmore">
                                                        <a href="/services/installation-assembly">DETAILS</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src="assets/img/gallery/6d4585bc96d865172ed3129598c87ed689375e37f5f028f731df35f4517e4c70.jpg" alt="machine
                                                    " />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h5 className="heading"><a href="#">CUSTOM ROBOTICS</a></h5>
                                                    <p className="letter-spacing-01">Our team has built custom robots for manufacturing,
                                                        quality assurance testing, pharmaceuticals, consumer goods, and more. [...]</p>
                                                    <div className="elm-readmore">
                                                        <a to="/services/custom-robotics">DETAILS</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={45} data-mobile={60} data-smobile={60} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ServiceSlide;