import React, { Component } from 'react';
import {Link} from 'react-router-dom'

class TabProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [
                {
                    id: 1,
                    classnames: 'project-item microgrid power',
                    srcimg: 'assets/img/gallery/83f0324736a885444080f70b08d33838bc79d97690a9be887f23ab15f3268c8f.jpg',
                    heading: 'SOL-ARK HOMEGRID',
                    name01: 'MicroGrid',
                    name02: 'Power Storage'
                },
                {
                    id: 2,
                    classnames: 'project-item robotics motion manufacturing',
                    srcimg: 'assets/img/gallery/227c8bbb7e41bf81eecbe4f994b9c79c9f141b3cd98bc56adc2860522314aec3.jpg',
                    heading: 'HOLOGRAPHIC IMAGE MACHINE',
                    name01: 'Motion Controls',
                    name02: 'Manufacturing'
                },
                {
                    id: 3,
                    classnames: 'project-item robotics data manufacturing',
                    srcimg: 'assets/img/gallery/0a11b47e222b49ef075de38fe3365d795a010e0508a286698c1cc3e3b7e56aba',
                    heading: 'ROBOTIC INSPECTION',
                    name01: 'Robotics',
                    name02: 'Data Logging'
                },
                {
                    id: 4,
                    classnames: 'project-item robotics control',
                    srcimg: 'assets/img/gallery/2fb6abbed7ea0ac262570b56d6a4867e7b8a90937c80e862f0ba01beb6f96ce4.jpg',
                    heading: '3D CONCRETE PRINTER',
                    name01: 'Robotics',
                    name02: 'Control System'
                },
                {
                    id: 5,
                    classnames: 'project-item robotics data motion',
                    srcimg: 'assets/img/gallery/1a3dc1a057f965358d25665af244a451988f4460236e42845590c6465f196c7e.jpg',
                    heading: 'ADHESIVE & UV CURING',
                    name01: 'Robotics',
                    name02: 'Motion Control'
                },
                {
                    id: 6,
                    classnames: 'project-item robotics data',
                    srcimg: 'assets/img/gallery/a3272b5580e638df1163e630da2ff31806fa79b44f21d002f0b8e14f451238cc.jpg',
                    heading: 'INSPECTION SYSTEM',
                    name01: 'Robotics',
                    name02: 'Data Logging'
                },
                {
                    id: 7,
                    classnames: 'project-item robotics data',
                    srcimg: 'assets/img/gallery/a3272b5580e638df1163e630da2ff31806fa79b44f21d002f0b8e14f451238cc.jpg',
                    heading: 'INSPECTION SYSTEM',
                    name01: 'Robotics',
                    name02: 'Data Logging'
                },
                {
                    id: 8,
                    classnames: 'project-item robotics data',
                    srcimg: 'assets/img/gallery/a3272b5580e638df1163e630da2ff31806fa79b44f21d002f0b8e14f451238cc.jpg',
                    heading: 'INSPECTION SYSTEM',
                    name01: 'Robotics',
                    name02: 'Data Logging'
                },
                {
                    id: 9,
                    classnames: 'project-item robotics data',
                    srcimg: 'assets/img/gallery/a3272b5580e638df1163e630da2ff31806fa79b44f21d002f0b8e14f451238cc.jpg',
                    heading: 'INSPECTION SYSTEM',
                    name01: 'Robotics',
                    name02: 'Data Logging'
                }
            ]
        }
    }

    render() {
        return (
            <div className="themesflat-project style-2 isotope-project has-margin mg15 data-effect clearfix">
                {
                this.state.projects.map(data =>(
                <div className={data.classnames} key={data.id}>
                    <div className="inner">
                        <div className="thumb data-effect-item has-effect-icon w40 offset-v-19 offset-h-49">
                            <img src={data.srcimg} alt="altimage" />
                            <div className="elm-link">
                                <Link to="#" className="icon-1 icon-search" />
                                    <Link to="#" className="icon-1" />
                            </div>
                            <div className="overlay-effect bg-color-3" />
                        </div>
                        <div className="text-wrap">
                            <h5 className="heading">
                                <Link to="#">{data.heading}</Link>
                            </h5>
                            <div className="elm-meta">
                                <span>
                                    <Link to="#">{data.name01}</Link></span>
                                <span>
                                    <Link to="#">{data.name02}</Link></span>
                            </div>
                        </div>
                    </div>
                </div>
                ))
                }
            </div>
        );
    }
}

export default (TabProject);