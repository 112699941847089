import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/c07f2be2b5c0a2c2c94cc68325c87fa6e5e993d957dd4b65f87579bb96bf2d28.jpg" alt="Nanogrid Bottom" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/5acfc21609f4ba315f46354415ccf7a478e8c0877ac3c4fbb58b8f5bf932a18a.jpg" alt="Nanogrid Top" />
                                </div>
                            </div>
                        </div>
                        {/* <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/73a26bd51757e7c1d76dbfdbdca46433f7be49124c57fd2f79b3fa9a73521548.jpg" alt="Control Enclosure for Drill Tit Tester Top" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/bbe4f951314e9e4d8ab7c6dfff7ffb65c23fa75bf59125cdbfb064185e0a938c.jpg" alt="Control Enclosure for Drill Tit Tester Bottom" />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">NANOGRID SYSTEM IN A GARAGE</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">This is a Nanogrid/Microgrid System installed in a garage.
                    </p>
                </div>
            </div>
        );
    }
}

export default ContentProject;