import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/16d4f5959ff1a851d85076ee3cabde8a3f045c58348b6aa4adb5676da7f5ea8f.jpg" alt="imagesalt" />
                                </div>
                            </div>
                        </div>
                        {/* <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/f4a6050b6f71117f5b286399fbb9b8e7134231f18b50716d0c371fc0a4265bcf.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/0cb8686314a1f862d371dc15641fac9d0d53c0256d24b0d8767414f4360df843.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                <img src="../../../../assets/img/gallery/b379a0198791e388871774882a311768fb340a825358527323dfa38f2b7e657c.jpg" alt="Yaskawa Controls Top" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                <img src="../../../../assets/img/gallery/8a6d2228407f0e8e3f3f5febec1c1a2045955f86870cdddfbc086f3463a349b1.jpg" alt="Yaskawa Controls Bottom" />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">CONTROL SYSTEM FOR CONCRETE STRUCTURE 3D PRINTER</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">
                        This is a machine that 3D prints concrete structures. It features a 6 Axis Robot 
                        control using Ethercat on Beckhoff Controller. 
                    </p>
                    <p> 
                        Featuring Control System, Power Distribution, and Motion Controls 
                        for the cutting-edge industry of concrete home manufacturing through 3D-printing. We also built in Data Logging 
                        so the operator always knows what is happening inside the system, when it is powered down, etc.
                    </p>
                    <p>
                        This helped the construction and home building industry move more quickly and save money by getting all their 
                        designs made perfectly to specifications.
                    </p>
                </div>
            </div>
        );
    }
}

export default ContentProject;