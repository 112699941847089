import React, { Component } from 'react';

class SingleContent001 extends Component {
    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    <article className="hentry data-effect">
                        <div className="post-media data-effect-item has-effect-icon offset-v-25 offset-h-24 clerafix"> 
                        {/* 840x385 */}
                            <a href="/blog/data-center-hpc-control-cooling-with-robotic-blade-changer"><img src="/assets/img/gallery/3eba07b0de45b4513b2ca58b2a505b4ec7ca45ec467dfc1ac78687167c8429f6.jpg" alt="altimage" /></a>
                            <div className="post-calendar">
                                <span className="inner">
                                    <span className="entry-calendar">
                                        <span className="day">30</span>
                                        <span className="month">MAY</span>
                                    </span>
                                </span>
                            </div>
                            <div className="overlay-effect bg-color-1" />
                            <div className="elm-a">
                                <a href="/blog/data-center-hpc-control-cooling-with-robotic-blade-change" className="icon-1" />
                            </div>
                        </div>
                        <div className="post-content-wrap clearfix">
                            <h2 className="post-title">
                                <span className="post-title-inner">
                                    <a href="/blog/data-center-hpc-control-cooling-with-robotic-blade-change">DATA CENTER HPC CONTROL AND COOLING WITH ROBOTIC BLADE CHANGER</a>
                                </span>
                            </h2>
                            <div className="post-meta">
                                <div className="post-meta-content">
                                    <div className="post-meta-content-inner">
                                        <span className="post-date item"><span className="inner"><span className="entry-date">May
                                                    30, 2024</span></span></span>
                                        <span className="post-by-author item"><span className="inner"><a href="#">By:
                                                    Frank Zeiler</a></span></span>
                                        {/* <span className="comment item"><span className="inner"><a href="#">3
                                                    Comments</a></span></span> */}
                                    </div>
                                </div>
                            </div>
                            <div className="post-content post-excerpt margin-bottom-43">
                                <p className="line-height-27 letter-spacing-005">
                                    This was a particularly interesting job. This data center with ultra-high performance computing (HPC) needed some automation.
                                    In particular, they needed to be able to swap out a server rack and automatically replace it with another one without shutting down
                                    or requiring manual labor. Data centers need to run 24/7/365 without interruption.
                                    </p>
                                    <p>
                                    We built a data logging system that shows production rates, and if the machine had to get shut down, info on what the problem was, 
                                    for betting tracking.
                                    </p>
                                    <h3>
                                    This product features a failsafe redundant control system as well as motion controls and power distribution.
                                    </h3>
                                <div className="box-excerpt clearfix">
                                    <div className="thumb">
                                    {/* 251x196 */}
                                        <img src="assets/img/gallery/e061f736758960f1742422821b55abe02d0ad47732a31a6c5ef4bf79ed3c3284.jpg" alt="altimage" />
                                    </div>
                                    <div className="text">
                                        <p className="line-height-27 letter-spacing-005">
                                        It sits in a 16 sq ft footprint, and it can house up to 2048 cores for ultra-high performance computing in a small package. 
                                        We made it so this thing can swap out one server from a rack and automatically replace it with another one without shutting 
                                        down and requiring manual labor. This helped the data center increase their productivity and reliability in a hugely scalable way.
                                        </p>
                                    </div>
                                </div>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                The client had all kinds of special requirements for keeping these things safe and making sure power is distributed across its various
                                moving parts properly. It is stuffed into a tightly compact space, and this customer needed us to design and implement motion controls,
                                safety, and power distribution so that this thing can run 24/7/365 without fail.
                                </p>
                                <h3>These machines are highly efficient, and we made sure they can run, stay safe, and last a long time making money for our client!</h3>
                            </div>
                            <div className="post-tags-socials clearfix">
                                <div className="post-tags ">
                                    <span>Tags :</span>
                                    <a href="/services/microgrid-services">Microgrids</a>
                                    <a href="/services/microgrid-services">Energy Storage</a>
                                    {/* <a href="/services/microgrid-services">Renewable</a> */}
                                </div>
                                <div className="post-socials ">
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=linestarautomation.com" className="facebook"><span className="fa fa-facebook-square" /></a>
                                    <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" className="twitter"><span className="fa fa-twitter" /></a>
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=linestarautomation.com" className="linkedin"><span className="fa fa-linkedin-square" /></a>
                                    {/* <a href="#" className="pinterest"><span className="fa fa-pinterest-p" /></a> */}
                                </div>
                            </div>
                        </div>
                    </article>
                    <div className="themesflat-pagination clearfix no-border padding-top-17">
                        <ul className="page-prev-next">
                            <li>
                                <a href="#" className="prev">
                                    Previous Article
                                </a>
                            </li>
                            <li className="text-right">
                                <a href="#" className="next">
                                    Next Article
                                </a>
                            </li>
                        </ul>
                    </div>
                    {/* <div id="comments" className="comments-area">
                        <h2 className="comments-title">3 COMMENTS</h2>
                        <ol className="comment-list">
                            <li className="comment">
                                <article className="comment-wrap clearfix">
                                    <div className="gravatar"><img alt="altimage" src="assets/img/gallery/24e4066d6808a26351f98397351f2e8c1f397db4903e7d7df703620375cb9a53.jpg" />
                                    </div>
                                    <div className="comment-content">
                                        <div className="comment-meta">
                                            <h6 className="comment-author">Arthur Murray</h6>
                                            <span className="comment-time">December 11, 2022 - at 11:40 am</span>
                                            <span className="comment-reply"><a className="comment-reply-a"
                                                    to="#">REPLY</a></span>
                                        </div>
                                        <div className="comment-text">
                                            <p>I am looking to build my own microgrid in Georgia. Anything I should know first
                                                before I start hooking things up to the grid? Thanks.
                                                 </p>
                                        </div>
                                    </div>
                                </article>
                                <ul className="children">
                                    <li className="comment">
                                        <article className="comment-wrap clearfix">
                                            <div className="gravatar"><img alt="altimage"
                                                    src="assets/img/gallery/bd1aa833b4f7d4e69add6c917c7294042a74c89577232586143cd5396df6d26c.jpg" /></div>
                                            <div className="comment-content">
                                                <div className="comment-meta">
                                                    <h6 className="comment-author">José Ortega</h6>
                                                    <span className="comment-time"> December 11, 2022 - at 12:31 am</span>
                                                    <span className="comment-reply"><a className="comment-reply-a"
                                                            to="#">REPLY</a></span>
                                                </div>
                                                <div className="comment-text">
                                                    <p>Call your local electric and gas companies and ask them first before you do
                                                        anything regarding hooking into the grid! They may be able to help you, and
                                                        they may help you avoid hurting yourself!
                                                        </p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                </ul>
                            </li>
                            <li className="comment">
                                <article className="comment-wrap  clearfix">
                                    <div className="gravatar"><img alt="altimage" src="assets/img/gallery/bed2ad3e1ed76ffd49e8a6b380178c6481ea5076ef26eb85d77c5ba065e02900.jpg" />
                                    </div>
                                    <div className="comment-content">
                                        <div className="comment-meta">
                                            <h6 className="comment-author">Michael Bowman</h6>
                                            <span className="comment-time">December 12, 2022 - at 4:28 pm</span>
                                            <span className="comment-reply"><a className="comment-reply-a"
                                                    to="#">REPLY</a></span>
                                        </div>
                                        <div className="comment-text">
                                            <p>How many kilowatt hours do I need to prepare for a medium-sized house, about 
                                                2300 sq ft? Do I need extra battery backups, or what? I am putting solar panels
                                                on the garage roof and want to be totally off the grid.</p>
                                        </div>
                                    </div>
                                </article>
                            </li>
                        </ol>
                        <div id="respond" className="comment-respond">
                            <h3 id="reply-title" className="comment-reply-title margin-bottom-31">Leave a Comment</h3>
                            <form action="#" method="post" id="commentform" className="comment-form">
                                <div className="text-wrap clearfix">
                                    <fieldset className="name-wrap">
                                        <input type="text" id="author" className="tb-my-input" name="author" tabIndex={1}
                                            placeholder="Name*" defaultValue={""} size={32} aria-required="true"/>
                                    </fieldset>
                                    <fieldset className="email-wrap">
                                        <input type="email" id="email" className="tb-my-input" name="email" tabIndex={2}
                                            placeholder="Email*" defaultValue={""} size={32} aria-required="true" />
                                    </fieldset>
                                </div>
                                <fieldset className="message-wrap">
                                    <textarea id="comment-message" name="comment" rows={8} tabIndex={4} placeholder="Message*"
                                        aria-required="true" defaultValue={""} />
                                    </fieldset>
                            <p className="form-submit">
                            <input name="submit" type="submit" id="comment-reply" className="submit" defaultValue="SEND US" />
                            <input type="hidden" name="comment_post_ID" defaultValue={100} id="comment_post_ID" />
                            <input type="hidden" name="comment_parent" id="comment_parent" defaultValue={0} />
                            </p>
                        </form>
                        </div>
                    </div> */}
                </div>
                <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
            </div>
        );
    }
}

export default SingleContent001;