import React, { Component } from 'react';

class SideBar extends Component {
    render() {
        return (
            <div id="sidebar">
                <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={0} data-smobile={0} />
                <div id="inner-sidebar" className="inner-content-wrap">
                    <div className="widget widget_list">
                        <div className="inner">
                            <ul className="list-wrap">
                                <li className="list-item">
                                    <a href="about-us"><span className="text">Who We Are</span></a>
                                </li>
                                <li className="list-item">
                                    <a href="about-team"><span className="text">Leadership Team</span></a>
                                </li>
                                <li className="list-item">
                                    <a href="about-careers"><span className="text">Careers</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="widget widget_instagram margin-top-51">
                        <h2 className="widget-title margin-bottom-30"><span>OUR GALLERY</span></h2>
                        <div className="instagram-wrap data-effect clearfix col3 g10">
                            <div className="instagram_badge_image has-effect-icon">
                                <a href="/projects/ups-system" target="_blank" className="data-effect-item">
                                    <span className="item"><img src="assets/img/gallery/84d8ad74135c0bd23220a54afa4645f37793556282dd28deebbe5cb45af49cd5.jpg"
                                            alt="altimage" /></span>
                                    <div className="overlay-effect bg-color-2" />
                                    <div className="elm-a">
                                        <span className="icon-3" />
                                    </div>
                                </a>
                            </div>
                            <div className="instagram_badge_image has-effect-icon">
                                <a href="/projects/medical-laser" target="_blank" className="data-effect-item">
                                    <span className="item"><img src="assets/img/gallery/9065c371fc580178573aa9527d90402644be8618cb2e52c0ddf70b6ef39e74d3.jpg"
                                            alt="altimage" /></span>
                                    <div className="overlay-effect bg-color-2" />
                                    <div className="elm-a">
                                        <span className="icon-3" />
                                    </div>
                                </a>
                            </div>
                            <div className="instagram_badge_image has-effect-icon">
                                <a href="/projects/uv-curing" target="_blank" className="data-effect-item">
                                    <span className="item"><img src="assets/img/gallery/af71e6dc13b0f6c229f3da54d33f7b94d8d5a3723941ed98bb1fb0b2841800dc.jpg"
                                            alt="altimage" /></span>
                                    <div className="overlay-effect bg-color-2" />
                                    <div className="elm-a">
                                        <span className="icon-3" />
                                    </div>
                                </a>
                            </div>
                            <div className="instagram_badge_image has-effect-icon">
                                <a href="/projects/winder-controls" target="_blank" className="data-effect-item">
                                    <span className="item"><img src="assets/img/gallery/8644ed71972606ec931c33fd9cf35bce8b0ffe72023f53e0b6efee24705bce62.jpg"
                                            alt="altimage" /></span>
                                    <div className="overlay-effect bg-color-2" />
                                    <div className="elm-a">
                                        <span className="icon-3" />
                                    </div>
                                </a>
                            </div>
                            <div className="instagram_badge_image has-effect-icon">
                                <a href="/projects/inspection-system" target="_blank" className="data-effect-item">
                                    <span className="item"><img src="assets/img/gallery/3d40a17922b6cd6cf3f1dc92aa197528ce0f3eff179d001f307d4a768e54e3d2.jpg"
                                            alt="altimage" /></span>
                                    <div className="overlay-effect bg-color-2" />
                                    <div className="elm-a">
                                        <span className="icon-3" />
                                    </div>
                                </a>
                            </div>
                            <div className="instagram_badge_image has-effect-icon">
                                <a href="/projects/cobots" target="_blank" className="data-effect-item">
                                    <span className="item"><img src="assets/img/gallery/5e630d82b772bad59dddd70dd3dc3de2eba341a3a51a42a978150a126858a811.jpg"
                                            alt="altimage" /></span>
                                    <div className="overlay-effect bg-color-2" />
                                    <div className="elm-a">
                                        <span className="icon-3" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="widget widget_lastest_twitter margin-top-40">
                        <h2 className="widget-title"><span>LATEST TWITTER</span></h2>
                        <ul className="lastest-posts clearfix">
                            <li className="clearfix">
                                <div className="icon-wrap has-width w30">
                                    <i className="ion-social-twitter" />
                                </div>
                                <div className="text">
                                    <p><a href="#">@Linestar</a> Completed work on a new machine today. Have a look!</p>
                                </div>
                            </li>
                            <li className="clearfix">
                                <div className="icon-wrap has-width w30">
                                    <i className="ion-social-twitter" />
                                </div>
                                <div className="text">
                                    <p><a href="#">@Linestar</a> The only stupid question is the one you don't ask. 
                                    Send us a message today and tell us about your dream.</p>
                                </div>
                            </li>
                            <li className="clearfix">
                                <div className="icon-wrap has-width w30">
                                    <i className="ion-social-twitter" />
                                </div>
                                <div className="text">
                                    <p><a href="#">@Linestar</a> Building a Micro-Grid? We have what you 
                                    need at the right price.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={60} data-smobile={60} />
            </div>
        );
    }
}

export default SideBar;