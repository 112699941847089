import React, { Component } from 'react';
//import {Link} from 'react-router-dom';
class SidebarEnergySwitch extends Component {
    render() {
        return (
            <div className="detail-info">
                <div className="content-info">
                    <div className="themesflat-headings style-2 clearfix">
                        <h2 className="heading line-height-62">PROJECT DETAILS</h2>
                        <div className="sep has-width w80 accent-bg clearfix">
                        </div>
                    </div>
                    <ul className="list-info has-icon icon-left">
                        <li><span className="text">Industry <span className="icon"><i className="fa fa-user" /></span></span><span
                                className="right">Renewable Energy</span></li>
                        {/* <li><span className="text">Budget <span className="icon"><i className="fa fa-usd" /></span></span><span
                                className="right">$1,500,000</span></li> */}
                        <li><span className="text">Surface Area <span className="icon"><i
                                        className="fa fa-search" /></span></span><span className="right">9 sq. ft </span></li>
                        <li><span className="text">Year completed <span className="icon"><i
                                        className="fa fa-calendar" /></span></span><span className="right">2016</span></li>
                        <li><span className="text">Location: <span className="icon"><i
                                        className="fa fa-folder-open" /></span></span><span className="right">Austin, Texas</span>
                        </li>
                        <li><span className="text">Tag <span className="icon"><i className="fa fa-tag" /></span></span>
                            <span className="right">
                                <a href="/services/power-distribution">Power Distribution</a>
                                <br></br>
                                <a href="/services/data-logging">Data Acquisition</a>
                                <br></br>
                                <a href="/services/microgrid-controls">Microgrid Controls</a>
                                <br></br>
                                <a href="/services/energy-storage">Energy Storage</a>
                            </span>
                        </li>
                    </ul>
                </div>{/* /.content-info */}
                <div className="themesflat-spacer clearfix" data-desktop={46} data-mobile={35} data-smobile={35} />
                <div className="content-info info-brochures">
                    <div className="themesflat-headings style-2 clearfix">
                        <h2 className="heading line-height-62">BROCHURES</h2>
                        <div className="sep has-width w60 accent-bg clearfix">
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={34} data-mobile={35} data-smobile={35} />
                    <div className="button-wrap has-icon icon-left size-14 pf21">
                    <a 
                            href="../../../../assets/img/gallery/6269b81990a346bfb51ca82ced895e337e74ce2b492831b1d31885aa7f23f9e5.pdf" 
                            className="themesflat-button font-default bg-light-white w100"
                            download="Linestar.pdf"
                        >
                            <span>Brochures.PDF 
                                <span className="icon">
                                <i className="fa fa-file-pdf-o" />
                                </span>
                            </span>
                        </a>
                    </div>
                    <div className="button-wrap has-icon icon-left size-14 pf21">
                        <a 
                            href="assets/img/gallery/9ddbf9ae73b482ff90953aed2a15ee578d9455f0e3474a98c85faf287bc926d6.docx" 
                            className="themesflat-button font-default bg-light-white w100"
                            download="Linestar.docx"
                        >
                            <span>Brochures.DOCX 
                                <span className="icon">
                                    <i className="fa fa-file-word-o" />
                                </span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default SidebarEnergySwitch;