import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/f44bf98a6a0678747fd7c9395fd3a396f2b618e7325f895977f15e0b39c6f0c0.jpg" alt="Garage 800x450 Black Bars" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/3782f294461878595a07e59c630d53a43f80ef59b82869c074046db6b6b2ad0b.jpg" alt="800x450 Black Bars" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/b222bc123b086d11de5294427e3becfce6f8d58f8ada3f01e5ca68136299d287.jpg" alt="800x450 Black Bars" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/5414edefa9dbee68feff30993fc0c122262ee58273899df16432f7eb32c99671.jpg" alt="800x450 Black Bars" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/83ae7dd804f2ad5cd69b17bab18116a01e787c6a821700ed852d197bbeac4f49.jpg" alt="800x450 Black Bars" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/c5c2bf1680a8b448183cd7d27992bbdcf36e4fdad958f310b3c80975b674401f.jpg" alt="800x450 Black Bars" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/725e01b7c6025a7a8ae58a206bacf99dcd1daa45aba36a9e0cd8bb2493106a1c.jpg" alt="800x450 Black Bars" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">ENERGY SWITCH FOR DEPT OF ENERGY PROJECT SUNSHOT</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">This was for a Department of Energy Project called Sunshot. A program they had a few years back.
                    </p>
                    <p>
                    It is a microgrid control system. It took in power from solar panels, stored power in batteries, and it could be used in the home 
                    or to sell power back to the city via the grid. It is 10 KW of energy, and it had a 10KWH battery.
                    </p>
                    <p>
                    The thing on the front is the user interface. It has a touchscreen user interface and an emergency stop-push button (power off) 
                    safety device in red. We called it the "Energy Switch." We built a total of three of them. They were about 9sq ft each.
                    </p>
                </div>
            </div>
        );
    }
}

export default ContentProject;