import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <footer id="footer" className="clearfix">
                <div id="footer-widgets" className="container">
                    <div className="themesflat-row gutter-30">
                        <div className="col span_1_of_3">
                            <div className="widget widget_text">
                                <div className="textwidget">
                                    <p>
                                        <img src="../../../../assets/img/gallery/c77d82e18fc1a2ea217e58e9e8c5170490b62dcbc73a4ca3b772c5b46ac3f1eb.jpg" alt="imagealt" width={170} height={34} />
                                    </p>
                                    <p className="margin-bottom-15">We have over 80+ years combined experience in automation.</p>
                                    <ul>
                                        <li>
                                            <div className="inner">
                                                <span className="fa fa-map-marker" />
                                                <span className="text">8711 BURNET ROAD, <span className="sl">AUSTIN, TEXAS</span></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="inner">
                                                <span className="fa fa-phone" />
                                                <span className="text">CALL US : +1 (512) 963 0939</span>
                                            </div>
                                        </li>
                                        <li className="margin-top-7">
                                            <div className="inner">
                                                <span className=" font-size-14 fa fa-envelope" />
                                                <span className="text">FZeiler@LineStarAutomation.com</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>{/* /.widget_text */}
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={35} />
                        </div>{/* /.col */}
                        <div className="col span_1_of_3">
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={0} />
                            <div className="widget widget_lastest">
                                <h2 className="widget-title"><span>RECENT POSTS</span></h2>
                                <ul className="lastest-posts data-effect clearfix">
                                    <li className="clearfix">
                                        <div className="thumb data-effect-item has-effect-icon">
                                            <img src="../../../../assets/img/gallery/b5de6b9d27c9fd67b6280b4c4bcb5e185b165187000f7997e3ae740e95a6a077.jpg" alt="imagealt" />
                                            <div className="overlay-effect bg-color-2" />
                                            <div className="elm-a">
                                                <a href="/blog/things-to-consider-microgrid" className="icon-2" />
                                            </div>
                                        </div>
                                        <div className="text">
                                            <h3><a href="/blog/things-to-consider-microgrid">DATA CENTER HPC CONTROL AND COOLING WITH ROBOTIC BLADE CHANGER</a></h3>
                                            <span className="post-date"><span className="entry-date">29 June 2022</span></span>
                                        </div>
                                    </li>
                                    <li className="clearfix">
                                        <div className="thumb data-effect-item has-effect-icon">
                                            <img src="../../../../assets/img/gallery/0be7b70f3349d4fc25409a69395799fc3f87bb0cf7f14c7565b0058867477a0c.jpg" alt="imagealt" />
                                            <div className="overlay-effect bg-color-2" />
                                            <div className="elm-a">
                                                <a href="/blog/things-to-consider-microgrid" className="icon-2" />
                                            </div>
                                        </div>
                                        <div className="text">
                                            <h3><a href="/blog/power-distribution-safety-process-control">WHY POWER DISTRIBUTION MATTERS IN ROBOTICS</a></h3>
                                            <span className="post-date"><span className="entry-date">29 August 2022</span></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>{/* /.widget_lastest */}
                        </div>{/* /.col */}
                        <div className="col span_1_of_3">
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={35} data-smobile={35} />
                            <div className="widget widget_tags">
                                <h2 className="widget-title margin-bottom-30"><span>TAGS</span></h2>
                                <div className="tags-list">
                                    <a href="/services/control-systems">Control Systems</a>
                                    <a href="/services/safety-systems">Safety Systems</a>
                                    <a href="/services/safety-systems">OSHA Compliance</a>
                                    <a href="/services/microgrid-controls">Microgrids</a>
                                    <a href="/services/energy-storage">Energy Storage</a>
                                    <a href="/services/data-logging">Data Logging</a>
                                </div>
                            </div>
                        </div>{/* /.col */}
                        <div className="col span_1_of_3">
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={35} data-smobile={35} />
                            <div className="widget widget_instagram">
                                <h2 className="widget-title margin-bottom-30"><span>RECENT PHOTOS</span></h2>
                                <div className="instagram-wrap data-effect clearfix col3 g10">
                                    <div className="instagram_badge_image has-effect-icon">
                                        <a href="/projects/sol-ark" target="_blank" className="data-effect-item">
                                            <span className="item"><img src="../../../../assets/img/gallery/6fc33f521d1dbb61f9eee2494354bd5a9be4dad3b046d1e97c1e4bef6110be53.jpg"
                                                    alt="imagealt" /></span>
                                            <div className="overlay-effect bg-color-2" />
                                            <div className="elm-a">
                                                <span className="icon-3" />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="instagram_badge_image has-effect-icon">
                                        <a href="/projects/sol-ark" target="_blank" className="data-effect-item">
                                            <span className="item"><img src="../../../../assets/img/gallery/3f3bb031058370eeccbe3cc66d5ffa925613ffa44637de7bb02cc80ed82eca8f.jpg"
                                                    alt="imagealt" /></span>
                                            <div className="overlay-effect bg-color-2" />
                                            <div className="elm-a">
                                                <span className="icon-3" />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="instagram_badge_image has-effect-icon">
                                        <a href="/projects/power-server" target="_blank" className="data-effect-item">
                                            <span className="item"><img src="../../../../assets/img/gallery/914f65c6479dd0a44f09f0dd886deea2c7c21c7380b43daa828e6d31b15d500d.jpg"
                                                    alt="imagealt" /></span>
                                            <div className="overlay-effect bg-color-2" />
                                            <div className="elm-a">
                                                <span className="icon-3" />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="instagram_badge_image has-effect-icon">
                                        <a href="/projects/power-server" target="_blank" className="data-effect-item">
                                            <span className="item"><img src="../../../../assets/img/gallery/bed2ad3e1ed76ffd49e8a6b380178c6481ea5076ef26eb85d77c5ba065e02900.jpg"
                                                    alt="imagealt" /></span>
                                            <div className="overlay-effect bg-color-2" />
                                            <div className="elm-a">
                                                <span className="icon-3" />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="instagram_badge_image has-effect-icon">
                                        <a href="/projects/power-server" target="_blank" className="data-effect-item">
                                            <span className="item"><img src="../../../../assets/img/gallery/24e4066d6808a26351f98397351f2e8c1f397db4903e7d7df703620375cb9a53.jpg"
                                                    alt="imagealt" /></span>
                                            <div className="overlay-effect bg-color-2" />
                                            <div className="elm-a">
                                                <span className="icon-3" />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="instagram_badge_image has-effect-icon">
                                        <a href="/projects/power-server" target="_blank" className="data-effect-item">
                                            <span className="item"><img src="../../../../assets/img/gallery/82566da9546c6c25ad3e70203484433edc977b415f0f999a01b01965f4323af7.jpg"
                                                    alt="imagealt" /></span>
                                            <div className="overlay-effect bg-color-2" />
                                            <div className="elm-a">
                                                <span className="icon-3" />
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;