import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/b2e74546e69bf7788209b0199b28011826db57e0b3ccfc6e60d48b5b23764064.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/19cda5b2e30037a143765095c8d36713c18c71fece7cfec4e53de1ab660d6c59.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/91f724bdeed2be56f00ce23f982dc589a8cac8f72ea5977ef93be6da7f971334.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/adb910eb5eb6c92e83f4e3892724b122166a779d775e8a38523ea2fc53dab427.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">POWER DISTRIBUTION AND SAFETY SYSTEM FOR LASER NOTCHING PRODUCTION LINE</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">This laser notching production line needed a power distribution and safety system.
                        It's done in Mexico and San Antonio, and the third shift was always underproducing. There are no 
                        supervisors on the third shift. We built a data logging system that shows production rates, and if the machine 
                        had to get shut down, info on what the problem was, for betting tracking.
                    </p>
                    <p>This helped the steel mill to quickly identify the problems occurring overnight and bring their third shift back 
                        to normal productivity levels.</p>
                </div>
            </div>
        );
    }
}

export default ContentProject;