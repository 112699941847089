import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/1019c0f0c8990530e97fcf332b0c6efd0eab645d630a6548a5388155a912e2ce.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/67003340ca700ee8cb4cf81f0dd71f9e568263ba5e407a7188bd755335e1fcab.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/de05aa20170cbf8e2756ac0b60d4eed3fdc839c3a0c8dae13f4c4ca028a04f87.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/c86dc8f2b158cd4012751bd5f4f3438689f3b31c57daa079ea8346fccf819716.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">POWER DISTRIBUTION, PLC / HMI CONTROL ON MEDICAL LASER POWER SYSTEMS</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">These are interesting machines we were hired to produce for a doctor's office that performs 
                        a novel method of hair restoration treatment. They required us to design and build a robot for them. First of all, 
                        it had to bring in a lot of power, and then it had to handle that power safely. Our team designed a solution that  
                        they loved, then we built it and installed it to their office.
                    </p>
                    <p>This machine is helping improve people's lives every day, and it is making the doctor's office good money!
                    </p>
                </div>
            </div>
        );
    }
}

export default ContentProject;