import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SliderService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slider: [
                {
                    id: 1,
                    srcimg: 'https://bitcoinfiles.org/t/7f442137ad2300678e26ab4067f6bf5f6dc310c45740248cba06fc17ed212e3e.jpg',
                    heading: 'SAFETY SYSTEMS',
                    text: 'We make your business OSHA-compliant. With automated safety systems, your staff can perform at their best.'
                },
                {
                    id: 2,
                    srcimg: 'assets/img/imagebox/image-box-2-370x215.jpg',
                    heading: 'MOTION CONTROLS',
                    text: 'Once designed, we build out a prototype of the solution. If you already have a design, we can prototype it for you.'
                },
                {
                    id: 3,
                    srcimg: 'assets/img/imagebox/image-box-3-370x215.jpg',
                    heading: 'CONTROL SYSTEMS',
                    text: 'Keep your staff, building, and machinery safe and running smoothly with smart control systems and automatic triggers.'
                },
                {
                    id: 4,
                    srcimg: 'assets/img/imagebox/image-box-1-370x215.jpg',
                    heading: 'POWER DISTRIBUTION',
                    text: 'Take in power from various sources and intelligently allocate it where it needs to go with safety and efficiency.'
                },
                {
                    id: 5,
                    srcimg: 'assets/img/imagebox/image-box-2-370x215.jpg',
                    heading: 'DATA LOGGING',
                    text: 'All our products come with some amount of data logging. We customize output for your needs and guarantee accuracy.'
                },
                {
                    id: 6,
                    srcimg: 'assets/img/imagebox/image-box-3-370x215.jpg',
                    heading: 'COMMUNICATION INTERFACES',
                    text: 'We make your machines talk to each other and empower you to control everything from one easy-to-use touch screen.'
                }
            ]
        }
    }
    
    render() {
        return (
            <div className="themesflat-carousel-box has-arrows arrow-center offset-v-111 offset-h-21 arrow-circle arrow-style-2 data-effect clearfix"
                data-gap={30} data-column={3} data-column2={2} data-column3={1} data-auto="true">
                <div className="owl-carousel owl-theme">
                    {
                        this.state.slider.map(data =>(
                            <div className="themesflat-image-box style-2 clearfix" key={data.id}>
                                <div className="image-box-item">
                                    <div className="inner">
                                        <div className="thumb data-effect-item">
                                            <img src={data.srcimg} alt="altimage" />
                                            <div className="overlay-effect bg-color-accent" />
                                        </div>
                                        <div className="text-wrap">
                                            <h5 className="heading"><Link to="#">{data.heading}</Link></h5>
                                            <p>{data.text}</p>
                                            <div className="elm-readmore">
                                                <Link to="#">DETAILS</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }             
                </div>
            </div>
        );
    }
}

export default SliderService;