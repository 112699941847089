import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
class SiteContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: [
                {
                    id: 1,
                    text01: 'Some customers require total data security and cannot for one instant suffer a data loss or corruption of their data. The blockchain is famously "immutible." Data cannot be changed.',
                    text02: 'Linestar now offers a new service using the blockchain to guarantee incorruptible data forever, giving those with the highest standards an option for your Data Acquisition and Logging needs.'
                },
                {
                    id: 2,
                    text01: 'We start by using traditional methods of Data Acquisition ("MQTT") to grab and report info from your machine in real-time. After the data is collected, it is securely transported and stored on the blockchain using your unique keys.',
                    text02: 'Your unique set of keys enables your account to encrypt and decrypt your data. You do this by logging into our secure portal at https://linestar.tech with your username and password.'
                },
                {
                    id: 3,
                    text01: 'We guarantee 100% immutibility of your data. The data that comes out of your machine is exactly the same data that gets stored, and there it is impossible for that data to change at any time.',
                    text02: 'This is especially important for our customers in the Military and Pharmaceutical industries. Everyone benefits from uncorruptible data. You can verifiably prove your data is authentic.'
                }
            ]
        }
    }
    
    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                    <div className="themesflat-row equalize sm-equalize-auto clearfix">
                        <div className="span_1_of_6 bg-f7f">
                            <div className="themesflat-spacer clearfix" data-desktop={60} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-content-box clearfix" data-margin="0 10px 0 43px"
                                data-mobilemargin="0 15px 0 15px">
                                <div className="themesflat-headings style-2 clearfix">
                                    <div className="sup-heading">SPECIAL SERVICES</div>
                                    <h2 className="heading font-size-28 line-height-39">BLOCKCHAIN STORAGE</h2>
                                    <div className="sep has-width w80 accent-bg margin-top-20 clearfix" />
                                    <p className="sub-heading margin-top-33 line-height-24">This is a new service for Linestar in 2022. 
                                        We can now read your data directly from your machines and send it encrypted to the blockchain, where 
                                        it will be securely stored forever. Then you can use our web portal to view your machine data anytime. 
                                        All changes go on the permanent record, so no one can maliciously alter your data. You get total transparency
                                        and uncorruptability.
                                        </p>
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={56} data-mobile={56} data-smobile={56} />
                        </div>
                        <div className="span_1_of_6 half-background style-2">
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={39} data-smobile={39} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title">SECURE DATA FOREVER</h5>
                            <p> 
                                We are successfully pulling data continuously from our own machines at Linestar Headquarters as a demo and storing the data 
                                on chain. You can see that demo and its live data feed here: <a href="https://linestar.tech">https://linestar.tech</a>. As 
                                a Linestar customer, you can log in to your web portal any time and see live read-outs of your own machine data. The data 
                                from your machines is guaranteed secure and incorruptible forever.
                            </p>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={39} data-smobile={39} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title">HOW IT WORKS (PRACTICALLY)</h5>
                            <p> 
                                The bottom line is that your machines produce data about their status and activity, and Linestar makes it so that the machines 
                                send their data to us, and we put that data on the blockchain. Then we provide you with an easy-to-use web portal where you can 
                                log in and securely view your machine data directly from the blockchain.
                            </p>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <div className="item">
                            <div className="inner">
                                <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10} data-smobile={10} />
                                <h5 className="title">PROVEN SUCCESS:</h5>
                                <p>We have successfully completed projects in numerous states across the continental United States.
                                    Linestar Automation has a proven track record of success building applications for highly demanding clients:</p>
                                <div className="themesflat-spacer clearfix" data-desktop={8} data-mobile={8} data-smobile={8} />
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">US Military</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">US FDA</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">US Dept of Energy</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">Global Manufacturing</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="item">
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={35} />
                            <div className="themesflat-content-box clearfix" data-margin="0 0 0 11px" data-mobilemargin="0 0 0 0">
                                <div className="inner pd35 bg-ffc">
                                    <h5 className="title text-white line-height-24 padding-left-7">CHECK OUT SOME OF OUR FANTASTIC  
                                        BLOCKCHAIN STORAGE WORK FOR OTHER CLIENTS</h5>
                                    <div className="themesflat-spacer clearfix" data-desktop={16} data-mobile={16}
                                        data-smobile={16} />
                                    <div className="button-wrap has-icon icon-right size-14">
                                        <a 
                                            href="../../../../assets/img/gallery/6269b81990a346bfb51ca82ced895e337e74ce2b492831b1d31885aa7f23f9e5.pdf"
                                            className="themesflat-button bg-white color-333 w100 font-weight-400 no-letter-spacing pd26"
                                            download="Linestar.pdf"
                                        >
                                            <span>Download .PDF 
                                                <span className="icon">
                                                    <i className="fa fa-file-pdf-o" />
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                    <div className="themesflat-spacer clearfix" data-desktop={6} data-mobile={6} data-smobile={6} />
                                    <div className="button-wrap has-icon icon-right size-14">
                                        <a 
                                            href="../../../../assets/img/gallery/9ddbf9ae73b482ff90953aed2a15ee578d9455f0e3474a98c85faf287bc926d6.docx"
                                            className="themesflat-button bg-white color-333 w100 font-weight-400 no-letter-spacing pd26 "
                                            download="Linestar.docx"
                                        >
                                            <span>Download .DOC 
                                                <span className="icon">
                                                    <i className="fa fa-file-word-o" />
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={39} data-smobile={39} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title">HOW IT WORKS (TECHNICALLY)</h5>
                            <p> 
                                Under the hood, what is happening is this: First, we attach small monitoring and communication devices your machines. 
                                Then, those devices collect data about your machine status and activity. Then, they securely sign your data using an 
                                Elliptic Curve Digital Signature Algorithm (ECDSA) before transmitting it over a secure network to Linestar. Then, 
                                Linestar stores that data to the BitcoinSV blockchain, a blockchain designed for enterprise storage and massive throughput. 
                            <br>
                            </br>
                            <br>
                            </br>
                                If your data requires a heightened level of security, we can also set your machines up to employ an Elliptic Curve 
                                Integrated Encryption Scheme (ECIES) to cryptographically encrypt your data before sending it over the network to 
                                Linestar. In these extreme cases, the data is unreadable by anyone but you, the key holder. To everyone else, it 
                                appears as mixed-up letters and numbers without context. But with our secure portal, you can enter your keys and 
                                decrypt your data. 
                            <br>
                            </br>
                            <br>
                            </br>
                                This is perfect for those in Military, Pharmaceutical, and Food Processing and Manufacturing who may want to preserve trade 
                                secrets and not expose your data to any competitors who might be scouring the blockchain for information. For everyone else, 
                                who maybe just wants to know what level of charge their batteries are at, or how much power their solar panels are producing, 
                                may decide to skip this level of security.
                            </p>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title no-letter-spacing">CONTACT US FOR DESIGN-BUILD SERVICES TODAY</h5>
                        <div className="themesflat-spacer clearfix" data-desktop={27} data-mobile={27} data-smobile={27} />
                        <div className="themesflat-tabs style-2 title-w170 clearfix">
                            <ul className="tab-title clearfix ">
                                <li className="item-title active">
                                    <span className="inner">WHY BLOCKCHAIN</span>
                                </li>
                                <li className="item-title">
                                    <span className="inner">HOW IT WORKS</span>
                                </li>
                                <li className="item-title">
                                    <span className="inner">OUR GUARANTEE</span>
                                </li>
                            </ul>
                            <div className="tab-content-wrap clearfix">
                                {
                                    this.state.tabs.map(data =>(
                                        <div className="tab-content" key={data.id}>
                                            <div className="item-content">
                                                <p>{data.text01}</p>
                                                <p>{data.text02}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={39} data-smobile={39} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title">SERVICE CONSULTANT</h5>
                        <p> Throughout our Blockchain Storage process, you get a single point of contact who is responsible to you, the client.
                            We work to develop and understand project expectations and then manage those needs with a customized, qualified
                            blockchain data expert. Your dedicated Linestar Service Consultant stays on your project through every step of the process.
                            That ensures that whether you only use our Blockchain Storage service, or whether you use all of our integrated services, 
                            you have someone you can talk to who understands the big picture of your goals. 
                        </p>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                </div>
            </div>
        );
    }
}

export default SiteContent;