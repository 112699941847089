import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/f1a2dda0bf41f914485b9c78f0f7a41a823cc18f0a24d42cb575d5b41fd8c9a3.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/gallery-2-800x450.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/gallery-3-800x450.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/gallery-4-800x450.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">HOME GRID SOL-ARK</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">
                    This is a machine is used in microgrids. If there's a storm or electrical outages, then it will back up the house power. 
                    It can power your home for 24-48 hours. It is a solar powered inverter with battery backup. 
                    </p>
                    <p>
                    We are integrating this product into microgrid systems with solar panels. The solar panels are an additional cost. These 
                    devices are usually mounted in your home garage, and the Sol-Ark itself is mounted above the battery.
                    </p>
                    <p>
                    When the sun is shining, the Sol-Ark charges up the batteries. When the power goes out, the Sol-Ark starts drawing power 
                    from the batteries to your house. A product like this is essential for a microgrid. Most people don't realize that when 
                    power goes out, even people with solar panels lose power because their solar panels are hooked into the grid. This can 
                    help you stay powered for the most critical times.
                    
                    </p>
                    <p>
                    
                    </p>
                </div>
            </div>
        );
    }
}

export default ContentProject;