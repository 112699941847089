import React, { Component } from 'react';

class SiteContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: [
                {
                    id:1,
                    srcimg: 'assets/img/gallery/cb5d81cfa38552d6de58c736bdf89aded5fbf6649f0eb7652a3c1ca584780eb7.jpg',
                    author: 'Frank Zeiler',
                    postions: 'President & Principal Engineer',
                    text: '40 years of machine design experience makes Frank Zeiler capable to tackle any challenge. He listens to your business needs and gets right to work designing the solution you have in mind. Frank founded Linestar to serve manufacturers that need jobs done fast. 12 years at Linestar.'
                }//,
                // {
                //     id:2,
                //     srcimg: 'assets/img/gallery/e022e0358e11146c693cfdd3021eb36ba07ead762db3e6e5dbd3c3eadd1ada76.jpg',
                //     author: 'Jason Dye',
                //     postions: 'Control Systems Engineer',
                //     text: 'With 10 years experience in Control Systems Design and Robotics Programming, Jason Dye manages projects and interfaces directly with clients on a daily basis. He ensures all projects are moving according to plan and provides Technical Support. 7 years at Linestar.'
                // },
                // {
                //     id:3,
                //     srcimg: 'assets/img/gallery/292aacfa8363c58b3bd93f5fc6f452a9590ab8e73e350d86d865d1e1c9850ca6.jpg',
                //     author: 'Susan Dodge',
                //     postions: 'Lead Designer for Control System Projects',
                //     text: 'Susan brings 30 years of industry experience to the table. She works directly with our clients to listen and understand their specific needs. Then she designs the perfect custom control systems and control panels using CAD. 6 years at Linestar.'
                // },
            ]
        }
    }
    
    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />
                    <div className="themesflat-headings style-2 clearfix">
                        <h2 className="heading">LEADERSHIP TEAM</h2>
                        <div className="sep has-width w80 accent-bg clearfix" />
                        <p className="sub-heading font-size-16 line-height-28 text-666 margin-top-27">With us you will have the peace of
                            mind knowing that your project is in the hands of a team with over 80 years combined experience
                            in the robotics and precision automation industry.</p>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={35} data-smobile={35} />
                    <div className="themesflat-content-box clearfix" data-margin="0 15px 0 0" data-mobilemargin="0 0 0 0">
                        <ol className="comment-list style-2">
                            {
                                this.state.comments.map(data =>(
                                    <li className="comment" key={data.id}>
                                        <article className="comment-wrap clearfix">
                                            <div className="gravatar"><img alt="imagealt" src={data.srcimg} />
                                            </div>
                                            <div className="comment-content">
                                                <div className="comment-meta">
                                                    <h6 className="comment-author">{data.author}</h6>
                                                    <span className="comment-position">{data.postions}</span>
                                                </div>
                                                <div className="comment-text">
                                                    <p>{data.text}</p>
                                                    <div className="socials">
                                                        {/* <Link to="#" className="facebook"><span className="fa fa-facebook-square" /></Link>
                                                        <Link to="#" className="twitter"><span className="fa fa-twitter" /></Link> */}
                                                        <a href="https://www.linkedin.com/in/frank-zeiler-b027985b" className="linkedin"><span className="fa fa-linkedin-square" /></a>
                                                        {/* <Link to="#" className="pinterest"><span className="fa fa-pinterest-p" /></Link> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                ))
                            }
                        </ol>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={83} data-mobile={60} data-smobile={60} />
                </div>
            </div>
        );
    }
}

export default SiteContent;