import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="assets/img/gallery/gallery-1-800x450.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="assets/img/gallery/gallery-2-800x450.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="assets/img/gallery/gallery-3-800x450.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="assets/img/gallery/gallery-4-800x450.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">PRODUCTION DATA LOGGING FOR STEEL MILL</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">This customer wanted to log productivity in a steel mill. They make covers for rolls 
                        of steel. It's done in Mexico and San Antonio, and the third shift was always underproducing. There are no 
                        supervisors on the third shift. We built a data logging system that shows production rates, and if the machine 
                        had to get shut down, info on what the problem was, for betting tracking.
                    </p>
                    <p>This helped the steel mill to quickly identify the problems occuring overnight and bring their third shift back 
                        to normal productivity levels.</p>
                </div>
            </div>
        );
    }
}

export default ContentProject;