import React, { Component } from 'react';

class RelateProjectZebraImaging extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: [
                {//images are 740x490
                    id: 1,
                    srcimg: '../../../../assets/img/gallery/f59fbcb6aacdd4167a2671ad46482ea7a35d8b09e2a0a70beb1c1cbd2358a09c.jpg',
                    heading: 'ROBOTIC INSPECTION SYSTEM',
                    meta: 'Motion Controls',
                    text: 'Power Distribution',
                    link: '/projects/inspection-system'
                },
                {
                    id: 2,
                    srcimg: '../../../../assets/img/gallery/2b6aa9316a69e13b227b4103906a9517a7294deabf2a1952674e357936c8abe9.jpg',
                    heading: 'ADHESIVE AND UV CURING',
                    meta: 'Motion Controls',
                    text: 'Power Distribution',
                    link: '/projects/3d-display'
                },
                {
                    id: 3,
                    srcimg: '../../../../assets/img/gallery/a9badd5e70e5d1a49d91583cf661c1c4d6892c63c12cf61b00548b2af326ae64.jpg',
                    heading: '3D HOLOGRAPHIC DISPLAY',
                    meta: 'Motion Controls',
                    text: 'Power Distribution',
                    link: '/projects/3d-display'
                },
                // {
                //     id: 4,
                //     srcimg: '../../../../assets/img/gallery/5ab23d56877215268d23ba8057e846c328ee647a15cd53add5a4f83a7b761ea1.jpg',
                //     heading: 'COBOT ROBOTIC TESTING',
                //     meta: 'Motion Controls',
                //     text: 'Power Distribution',
                //     link: '/projects/cobots'
                // },
                // {
                //     id: 5,
                //     srcimg: '../../../../assets/img/gallery/6d3a066f6b29110fa728cbacd899f1ee3c9a33077e344235046e19c3976a516d.jpg',
                //     heading: 'AUTOMATIC SERVER REPLACER',
                //     meta: 'Motion Controls',
                //     text: 'Power Distribution',
                //     link: '/projects/edge-box'
                // },
                // {
                //     id: 6,
                //     srcimg: '../../../../assets/img/gallery/8bd1314372df0de0e6ad511be4794676cbadf31fbda48c2ce235245129aa35c7.jpg',
                //     heading: '2-AXIS WINDER & UNWINDER',
                //     meta: 'Motion Controls',
                //     text: 'Power Distribution',
                //     link: '/projects/winder-controls'
                // },
            ]
        }
    }
    render() {
        return (
            <div className="themesflat-carousel-box data-effect has-arrows arrow-top arrow75 arrow-circle arrow-style-2 clearfix" data-gap={30} data-column={3} data-column2={2} data-column3={1}
                data-auto="false">
                <div className="owl-carousel owl-theme">
                    {
                        this.state.data.map(data => (
                            <div className="themesflat-project style-1 clearfix" key={data.id}>
                                <div className="project-item">
                                    <div className="inner">
                                        <div className="thumb data-effect-item has-effect-icon w40 offset-v-43 offset-h-46">
                                            <img src={data.srcimg} alt="altimage" />
                                            <div className="text-wrap text-center">
                                                <h5 className="heading"><a href={data.link}>{data.heading}</a></h5>
                                                <div className="elm-meta">
                                                    <span>
                                                        <a href="/services/motion-controls">{data.meta}</a></span>
                                                    <span>
                                                        <a href="/services/power-distribution">{data.text}</a></span>
                                                </div>
                                            </div>
                                            <div className="elm-link">
                                                <a href={data.link} className="icon-1 icon-search"></a>
                                                <a href={data.link} className="icon-1"></a>
                                            </div>
                                            <div className="overlay-effect bg-color-3" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default RelateProjectZebraImaging;