import React, { Component } from 'react';

class RelateProjectSolArk extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: [
                {//images are 740x490
                    id: 1,
                    srcimg: '../../../../assets/img/gallery/e3316b7f925ad6109ebb837368509afaa57e14d0b1753974d8f72e1fff195b0a.jpg',
                    heading: 'INDUSTRIAL 12KWH UPS',
                    meta: 'Energy Storage',
                    text: 'Microgrids',
                    link: '/projects/ups-system'
                },
                {
                    id: 2,
                    srcimg: '../../../../assets/img/gallery/5e630d82b772bad59dddd70dd3dc3de2eba341a3a51a42a978150a126858a811.jpg',
                    heading: 'HOME POWER SERVER',
                    meta: 'Energy Storage',
                    text: 'Microgrids',
                    link: '/projects/power-server'
                }
            ]
        }
    }
    render() {
        return (
            <div className="themesflat-carousel-box data-effect has-arrows arrow-top arrow75 arrow-circle arrow-style-2 clearfix" data-gap={30} data-column={3} data-column2={2} data-column3={1}
                data-auto="false">
                <div className="owl-carousel owl-theme">
                    {
                        this.state.data.map(data => (
                            <div className="themesflat-project style-1 clearfix" key={data.id}>
                                <div className="project-item">
                                    <div className="inner">
                                        <div className="thumb data-effect-item has-effect-icon w40 offset-v-43 offset-h-46">
                                            <img src={data.srcimg} alt="altimage" />
                                            <div className="text-wrap text-center">
                                                <h5 className="heading"><a href={data.link}>{data.heading}</a></h5>
                                                <div className="elm-meta">
                                                    <span>
                                                        <a href="/services/energy-storage">{data.meta}</a></span>
                                                    <span>
                                                        <a href="/services/microgrids">{data.text}</a></span>
                                                </div>
                                            </div>
                                            <div className="elm-link">
                                                <a href={data.link} className="icon-1 icon-search"></a>
                                                <a href={data.link} className="icon-1"></a>
                                            </div>
                                            <div className="overlay-effect bg-color-3" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default RelateProjectSolArk;