import React, { Component } from 'react';

class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: [
                {
                    id: 1,
                    text: 'Our focus is to exceed your goals, meet your timelines and successfully deliver within your budget. We provide our clients with top-notch design, prototyping, and manufacturing of custom robotic solutions.',
                    item: [
                        {
                            idx: 1,
                            content: 'We have successfully completed projects around the world.',
                        },
                        {
                            idx: 2,
                            content: 'We specialize in communication interfaces and control packages.',
                        },
                        {
                            idx: 3,
                            content: 'We write custom software to meet your data logging needs.',
                        }
                    ]
                },
                {
                    id: 2,
                    text: 'We design power distribution and control systems that keep your factory safe, productive, and reliable. You need switch gear and components to take a lot of power in and distribute it to smaller sub-systems. Plus the ability to shut the whole thing off instantly if you need to.',
                    item: [
                        {
                            idx: 1,
                            content: 'We build electrical systems that control a machine or process.',
                        },
                        {
                            idx: 2,
                            content: 'Mechanical engineering companies are our primary customers.',
                        },
                        {
                            idx: 3,
                            content: 'We make sure you get power, store power, and distribute power.',
                        }
                    ]
                },
                {
                    id: 3,
                    text: 'You want to know what your process is doing at all times. We make sure you know -- your production rates, if the machine had to get shut down, and info any problems or anomalies. Plus, the data is guaranteed reliable and immutable, meaning no one can change it without your knowledge.',
                    item: [
                        {
                            idx: 1,
                            content: 'Data acquisition is built into everything we do.',
                        },
                        {
                            idx: 2,
                            content: 'We can put your data on the blockchain for ultimate reliabilty.',
                        },
                        {
                            idx: 3,
                            content: 'We have built applications for data logging used by the FDA.',
                        }
                    ]
                }

            ]
        }
    }
    
    render() {
        return (
            <div className="col-md-6">
                <div className="themesflat-content-box clearfix" data-margin="0 18px 0 0" data-mobilemargin="0 0 0 0">
                    <div className="themesflat-headings style-1 clearfix">
                        <h2 className="heading">OVERVIEW</h2>
                        <div className="sep has-width w80 accent-bg margin-top-11 clearfix" />
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={38} data-mobile={35} data-smobile={35} />
                    <div className="themesflat-tabs style-1 clearfix">
                        <ul className="tab-title clearfix">
                            <li className="item-title active">
                                <span className="inner">Our Focus</span>
                            </li>
                            <li className="item-title">
                                <span className="inner">Your Power</span>
                            </li>
                            <li className="item-title">
                                <span className="inner">Your Data</span>
                            </li>
                        </ul>
                        <div className="tab-content-wrap clearfix">
                            {
                                this.state.tab.map(data => (
                                    <div className="tab-content" key={data.id}>
                                        <div className="item-content">
                                            <p>{data.text}</p>
                                            {
                                                data.item.map(index => (
                                                    <div className="themesflat-list has-icon style-1 icon-left ofset-padding-32 clearfix" key={index.idx}>
                                                        <div className="inner">
                                                            <span className="item">
                                                                <span className="icon"><i className="fa fa-check-square" /></span>
                                                                <span className="text">{index.content}</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>{/* /.tab-content-wrap */}
                    </div>{/* /.themesflat-tabs */}
                </div>{/* /.themesflat-content-box */}
            </div>
        );
    }
}

export default Overview;