import React, { Component } from 'react';

class SingleContent001 extends Component {
    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    <article className="hentry data-effect">
                        <div className="post-media data-effect-item has-effect-icon offset-v-25 offset-h-24 clerafix"> 
                        {/* 840x385 */}
                            <a href="/blog/power-distribution-safety-process-control"><img src="/assets/img/gallery/040ca2c2212653c94ca7e222bf549797b6811414b34ca053cc64e94195a4e433.jpg" alt="altimage" /></a>
                            <div className="post-calendar">
                                <span className="inner">
                                    <span className="entry-calendar">
                                        <span className="day">29</span>
                                        <span className="month">APR</span>
                                    </span>
                                </span>
                            </div>
                            <div className="overlay-effect bg-color-1" />
                            <div className="elm-a">
                                <a href="/blog/power-distribution-safety-process-control" className="icon-1" />
                            </div>
                        </div>
                        <div className="post-content-wrap clearfix">
                            <h2 className="post-title">
                                <span className="post-title-inner">
                                    <a href="/blog/power-distribution-safety-process-control">ROBOTICS EXPERT FRANK ZEILER ANSWERS QUESTIONS ABOUT POWER DISTRIBUTION</a>
                                </span>
                            </h2>
                            <div className="post-meta">
                                <div className="post-meta-content">
                                    <div className="post-meta-content-inner">
                                        <span className="post-date item"><span className="inner"><span className="entry-date">April
                                                    29, 2024</span></span></span>
                                        <span className="post-by-author item"><span className="inner"><a href="#">By:
                                                    Frank Zeiler</a></span></span>
                                        {/* <span className="comment item"><span className="inner"><a href="#">3
                                                    Comments</a></span></span> */}
                                    </div>
                                </div>
                            </div>
                            <div className="post-content post-excerpt margin-bottom-43">
                            <p className="line-height-27 no-margin letter-spacing-01">
                                    A lot of people in our industry don't do power distribution correctly. Frank Zeiler has 40+ years of professional
                                    experience doing power distribution. What follows below is an interview with Frank Zeiler on the topic of Power Distribution
                                    in the context of robotics and systems automation.
                                </p>
                                <br>
                                </br>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    <b>Power Distribution sounds really boring. Is it?</b> 
                                </p>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    Yes, but it's important. 
                                </p>
                                <br>
                                </br>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    <b>What exactly is "Power Distribution"?</b> 
                                </p>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    Well, it's splitting up the power coming into a machine or system into multiple feeds to make safe circuits.
                                </p>
                                <br>
                                </br>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    <b>How does a business know when it needs Power Distribution?</b> 
                                </p>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    Generally anything bigger than a wall outlet. It's really, <i>"When does a machine or process need power distribution?" </i> 
                                    Probably anything more than one kilowatt of power. So that it can be broken into one kilowatt chunks or modules. 
                                </p>
                                <br>
                                </br>
                                
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    <b>Do you ever sell Power Distribution as a one-off product or service, or do you only sell it as part of a package 
                                    with other stuff like Control Systems?</b> 
                                </p>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    Yeah it's pretty much all the start of the control system. The first part of a control system is power distribution because 
                                    the first thing that incoming power does is power up the control system part of the machine, but then there's things that are 
                                    part of it like motors and power supplies and heaters, process elements.
                                </p>
                                <br>
                                </br>
                                    <p className="line-height-27 no-margin letter-spacing-01">
                                    <b>Any memorable Power Distribution jobs?</b> 
                                    </p>
                                    <p className="line-height-27 no-margin letter-spacing-01">
                                        The TMG Otto 120M was 120KW with 100% redundancy, so there's really a 240KW system. This is something they do in data centers 
                                        a lot, where you have two independent power feeds. 
                                    </p>
                                <br>
                                </br>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    <b>How many people on your team are involved with Power Distribution?</b>
                                </p>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    Typically 4.
                                </p>
                                <br>
                                </br>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    <b>What is the process like for completing a Power Distribution job, start to finish?</b>
                                </p>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    That's kind of a long story...You can learn more about it on Linestar's <a href="/services/power-distribution">Power Distribution Services</a> page.
                                </p>
                                <br>
                                </br>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    <b>What should potential customers know about the Power Distribution service you provide?</b>
                                </p>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    Probably that we design power distribution to UL 508A codes. That's the standard for industrial control systems.
                                </p>
                                <br>
                                </br>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    <b>As a percentage of a total job's cost, about what portion does Power Distribution represent?</b>
                                </p>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    Probably 20%.
                                </p>
                                <br>
                                </br>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    <b>Can you skip the Power Distribution part to save money?</b>
                                </p>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    Not legally.
                                </p>
                                <br>
                                </br>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    <b>What are the most common misconceptions you face when talking with customers about power distribution?</b>
                                </p>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    Some customers think that a power strip is the power distribution system. You plug it in, and it's got 5 outlets. 
                                    I've actually had customers that built their machine with power strips, but you can't really do it that way.
                                </p>
                                <br>
                                </br>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    <b>Any unique challenges a person should be aware of before tackling some power distribution on his own?</b>
                                </p>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    Mainly knowledge of the UL codes.
                                </p>
                                <br>
                                </br>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    <b>What are the tools of the trade? What kind of materials do you use when doing Power Distribution jobs? Are there 
                                        standard products you have ready off the shelf?</b>
                                </p>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    Circuit breakers, contactors, bus bar systems, disconnect switches. Things like that.
                                </p>
                                <br>
                                </br>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    <b>Where do you build your power distribution solutions?</b>
                                </p>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    In our shop in Austin Texas.
                                </p>
                                <br>
                                </br>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    <b>Are power distributions easy to manage once installed, or do you need someone special to be in charge of them and understand how they work?</b>
                                </p>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    They're pretty much maintenance-free, but if a circuit breaker trips, they need a qualified electrician to service it. Electrician or engineer.
                                </p>
                                <br>
                                </br>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    <b>What happens if something goes wrong? Can customers call you, or do they fix it themselves, or what?</b>
                                </p>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    Most of the big plants we work with have in-house maintenance electricians. So depending on if they have qualified maintenance staff, sure, they 
                                    can fix it themselves if a circuit breaker trips or something like that.
                                </p>
                            </div>
                            <div className="post-tags-socials clearfix">
                                <div className="post-tags ">
                                    <span>Tags :</span>
                                    <a href="/services/microgrid-services">Microgrids</a>
                                    <a href="/services/microgrid-services">Energy Storage</a>
                                    {/* <a href="/services/microgrid-services">Renewable</a> */}
                                </div>
                                <div className="post-socials ">
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=linestarautomation.com" className="facebook"><span className="fa fa-facebook-square" /></a>
                                    <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" className="twitter"><span className="fa fa-twitter" /></a>
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=linestarautomation.com" className="linkedin"><span className="fa fa-linkedin-square" /></a>
                                    {/* <a href="#" className="pinterest"><span className="fa fa-pinterest-p" /></a> */}
                                </div>
                            </div>
                        </div>
                    </article>
                    <div className="themesflat-pagination clearfix no-border padding-top-17">
                        <ul className="page-prev-next">
                            <li>
                                <a href="#" className="prev">
                                    Previous Article
                                </a>
                            </li>
                            <li className="text-right">
                                <a href="#" className="next">
                                    Next Article
                                </a>
                            </li>
                        </ul>
                    </div>
                    {/* <div id="comments" className="comments-area">
                        <h2 className="comments-title">3 COMMENTS</h2>
                        <ol className="comment-list">
                            <li className="comment">
                                <article className="comment-wrap clearfix">
                                    <div className="gravatar"><img alt="altimage" src="assets/img/gallery/24e4066d6808a26351f98397351f2e8c1f397db4903e7d7df703620375cb9a53.jpg" />
                                    </div>
                                    <div className="comment-content">
                                        <div className="comment-meta">
                                            <h6 className="comment-author">Arthur Murray</h6>
                                            <span className="comment-time">December 11, 2022 - at 11:40 am</span>
                                            <span className="comment-reply"><a className="comment-reply-a"
                                                    to="#">REPLY</a></span>
                                        </div>
                                        <div className="comment-text">
                                            <p>I am looking to build my own microgrid in Georgia. Anything I should know first
                                                before I start hooking things up to the grid? Thanks.
                                                 </p>
                                        </div>
                                    </div>
                                </article>
                                <ul className="children">
                                    <li className="comment">
                                        <article className="comment-wrap clearfix">
                                            <div className="gravatar"><img alt="altimage"
                                                    src="assets/img/gallery/bd1aa833b4f7d4e69add6c917c7294042a74c89577232586143cd5396df6d26c.jpg" /></div>
                                            <div className="comment-content">
                                                <div className="comment-meta">
                                                    <h6 className="comment-author">José Ortega</h6>
                                                    <span className="comment-time"> December 11, 2022 - at 12:31 am</span>
                                                    <span className="comment-reply"><a className="comment-reply-a"
                                                            to="#">REPLY</a></span>
                                                </div>
                                                <div className="comment-text">
                                                    <p>Call your local electric and gas companies and ask them first before you do
                                                        anything regarding hooking into the grid! They may be able to help you, and
                                                        they may help you avoid hurting yourself!
                                                        </p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                </ul>
                            </li>
                            <li className="comment">
                                <article className="comment-wrap  clearfix">
                                    <div className="gravatar"><img alt="altimage" src="assets/img/gallery/bed2ad3e1ed76ffd49e8a6b380178c6481ea5076ef26eb85d77c5ba065e02900.jpg" />
                                    </div>
                                    <div className="comment-content">
                                        <div className="comment-meta">
                                            <h6 className="comment-author">Michael Bowman</h6>
                                            <span className="comment-time">December 12, 2022 - at 4:28 pm</span>
                                            <span className="comment-reply"><a className="comment-reply-a"
                                                    to="#">REPLY</a></span>
                                        </div>
                                        <div className="comment-text">
                                            <p>How many kilowatt hours do I need to prepare for a medium-sized house, about 
                                                2300 sq ft? Do I need extra battery backups, or what? I am putting solar panels
                                                on the garage roof and want to be totally off the grid.</p>
                                        </div>
                                    </div>
                                </article>
                            </li>
                        </ol>
                        <div id="respond" className="comment-respond">
                            <h3 id="reply-title" className="comment-reply-title margin-bottom-31">Leave a Comment</h3>
                            <form action="#" method="post" id="commentform" className="comment-form">
                                <div className="text-wrap clearfix">
                                    <fieldset className="name-wrap">
                                        <input type="text" id="author" className="tb-my-input" name="author" tabIndex={1}
                                            placeholder="Name*" defaultValue={""} size={32} aria-required="true"/>
                                    </fieldset>
                                    <fieldset className="email-wrap">
                                        <input type="email" id="email" className="tb-my-input" name="email" tabIndex={2}
                                            placeholder="Email*" defaultValue={""} size={32} aria-required="true" />
                                    </fieldset>
                                </div>
                                <fieldset className="message-wrap">
                                    <textarea id="comment-message" name="comment" rows={8} tabIndex={4} placeholder="Message*"
                                        aria-required="true" defaultValue={""} />
                                    </fieldset>
                            <p className="form-submit">
                            <input name="submit" type="submit" id="comment-reply" className="submit" defaultValue="SEND US" />
                            <input type="hidden" name="comment_post_ID" defaultValue={100} id="comment_post_ID" />
                            <input type="hidden" name="comment_parent" id="comment_parent" defaultValue={0} />
                            </p>
                        </form>
                        </div>
                    </div> */}
                </div>
                <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
            </div>
        );
    }
}

export default SingleContent001;