import React, { Component } from 'react';

class ContentBlog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blogs: [
                {
                    id: 1,
                    articles: 'hentry data-effect',
                    classnames:'post-media has-effect-icon offset-v-25 offset-h-24 data-effect-item clerafix',
                    srcimg: '/assets/img/gallery/9f35b990e6b23d80e1b825e466ff7ee34c740102ffdc42558ed7fa7325f19946.jpg',
                    title: 'THINGS TO CONSIDER WHEN BUILDING A MICROGRID',
                    day: '11',
                    month: 'AUG ',
                    author: ' Frank Zeiler',
                    comments: '3 ',
                    descriptions: 'So you have decided you want to build a home microgrid. Here is a list of 3 things you must consider before purchasing any equipment or breaking ground on your new endeavor. If you do these three things first, your project will go quicker, cheaper, and with greater success than starting without these things in mind.',
                    link: '/blog/things-to-consider-microgrid'
                },
                {
                    id: 2,
                    srcimg: '/assets/img/gallery/2a6fe95339006a030e3ca603afbbcaa51c409a66884987a27e5360f4bf8ea667.jpg',
                    articles: 'hentry data-effect',
                    classnames:'post-media data-effect-item has-effect-icon offset-v-25 offset-h-24clerafix',
                    title: 'OSHA VIOLATION? GET BACK IN COMPLIANCE IN 3 FAST STEPS',
                    day: '07',
                    month: 'JUL ',
                    author: ' Frank Zeiler',
                    comments: '4 ',
                    descriptions: 'Getting an OSHA violation can wreck your business and cost hundreds of thousands in lost revenue. Get back in compliance with 3 simple steps, and stay on the good side of the law forever. Our team of experts has helped dozens of businesses get back in the game fast, and stay in compliance through automated safety systems.',
                    link: '/blog/osha-violation-3-steps-compliance'
                },
                {
                    id: 3,
                    srcimg: '/assets/img/gallery/0be76ef593a32bea1bd7a6b442e4080a8028438e041836fed1b06906abf63a5b.jpg',
                    articles: 'hentry margin-top-33 padding-top-45 data-effect',
                    classnames:'post-media data-effect-item has-effect-icon offset-v-25 offset-h-24 clerafix',
                    title: 'WHAT WE SAW AT THE ROBOTICS CONVENTION IN DALLAS TEXAS',
                    day: '23',
                    month: 'JUN ',
                    author: ' Frank Zeiler',
                    comments: '10 ',
                    descriptions: 'From big-name companies to university students showing off their projects to impress scouting agents, there was a lot to see at the 2024 Annual Robotics Convention, held at the KBH Convention Center in Dallas, TX. Team Linestar was there to exhibit our microgrid products and network with other innovators in the industry.',
                    link: '/blog/what-we-saw-at-the-robotics-convention-dallas-texas'
                }
            ]
        }
    }
    
    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    {
                        this.state.blogs.map(data =>(
                            <article className={data.articles} key={data.id}>
                                <div className={data.classnames}>
                                    <a href={data.link}><img src={data.srcimg} alt="altimage" /></a>
                                    <div className="post-calendar">
                                        <span className="inner">
                                            <span className="entry-calendar">
                                                <span className="day">{data.day}</span>
                                                <span className="month">{data.month}</span>
                                            </span>
                                        </span>
                                    </div>
                                    <div className="overlay-effect bg-color-1" />
                                    <div className="elm-a">
                                        <a href={data.link} className="icon-1" />
                                    </div>
                                </div>
                                <div className="post-content-wrap clearfix">
                                    <h2 className="post-title">
                                        <span className="post-title-inner">
                                            <a href={data.link}>{data.title}</a>
                                        </span>
                                    </h2>
                                    <div className="post-meta">
                                        <div className="post-meta-content">
                                            <div className="post-meta-content-inner">
                                                <span className="post-date item"><span className="inner"><span className="entry-date">{data.month}  
                                                    {data.day}, 2022</span></span></span>
                                                <span className="post-by-author item"><span className="inner"><a href={data.link}>By: 
                                                     {data.author}</a></span></span>
                                                {/* <span className="comment item"><span className="inner"><a href={data.link}>{data.comments}
                                                             Comments</a></span></span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-content post-excerpt">
                                        <p>{data.descriptions}</p>
                                    </div>
                                    <div className="post-read-more">
                                        <div className="post-a">
                                            <a href={data.link}>READ MORE</a>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        ))
                    }
                    <div className="themesflat-pagination clearfix">
                        <ul>
                            <li><a href="/blog" className="page-numbers prev"><span className="fa fa-angle-left" /></a></li>
                            <li><a href="/blog" className="page-numbers current">1</a></li>
                            <li><a href="/blog/2" className="page-numbers">2</a></li>
                            <li><a href="/blog/2" className="page-numbers next"><span className="fa fa-angle-right" /></a></li>
                        </ul>
                    </div>
                </div>
                <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
            </div>
        );
    }
}

export default ContentBlog;