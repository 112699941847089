import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/c2cb060c6b979d10b8d8210fdbbd1fb8a017be743853dfac05fe7285a52e3f46.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/2a1a3c55bf9865253600f20203c7e88664dbe6baa1ebda366fa056fd6f83e3a2.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/78d973f9058f4495667ea7aaf9989291a1fa0c173ba9860f9f400e597e0389ac.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/7981bdbe48783e344aaf7a0d48ea488a6d0a07e575e648e95f71a064101fb9f7.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">4 AXIS TEST SYSTEM FOR MILITARY TESTING APPLICATION</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">This is a machine that tests.
                        It's done in Mexico and San Antonio, and the third shift was always underproducing. There are no 
                        supervisors on the third shift. We built a data logging system that shows production rates, and if the machine 
                        had to get shut down, info on what the problem was, for betting tracking.
                    </p>
                    <p>This helped the steel mill to quickly identify the problems occuring overnight and bring their third shift back 
                        to normal productivity levels.</p>
                </div>
            </div>
        );
    }
}

export default ContentProject;