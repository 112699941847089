import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class TabProjectFull extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [
                {//images are 440x280
                    id: 1,
                    classnames: 'project-item control manufacturing',
                    srcimg: 'assets/img/gallery/a1327e1e00a5de6a82caff13d41d4be8eb3966f26bdadd197c8a7311eb7a8f25.jpg',
                    heading: 'Control System for Vulcan 3D Concrete Structure printer',
                    name01: 'Control System',
                    name02: 'Manufacturing',
                    href: '/projects/3d-printer'
                },
                // {
                //     id: 2,
                //     classnames: 'project-item power motion data',
                //     srcimg: 'assets/img/gallery/47a284b2985206c9a9f4dddf7b67cacbb0aebf12d3e0f9b3540bd0494cd63e44.jpg',
                //     heading: 'Power distribution, safety, and process control for Edge Box Compact HPC solution',
                //     name01: 'Motion Controls',
                //     name02: 'Power Distribution',
                //     href: '/projects/edge-box'
                // },
                {
                    id: 3,
                    classnames: 'project-item motion data',
                    srcimg: 'assets/img/gallery/e061f736758960f1742422821b55abe02d0ad47732a31a6c5ef4bf79ed3c3284.jpg',
                    heading: 'Data center HPC control and cooling with Robotic blade changer',
                    name01: 'Motion Controls',
                    name02: 'Data Logging',
                    href: '/projects/otto'
                },
                // {
                //     id: 4,
                //     classnames: 'project-item control data',
                //     srcimg: 'assets/img/gallery/5c7e5eeb3201fa1507c6128dc65d7523f3c18f731b8b2f4dc2851cb791c9bdb2.jpg',
                //     heading: 'Process control for Two Phase cooling for HPC blades',
                //     name01: 'Data Logging',
                //     name02: 'Control System',
                //     href: '/projects/2-phase-cooling'
                // },
                {
                    id: 5,
                    classnames: 'project-item power data motion',
                    srcimg: 'assets/img/gallery/16594589d1a61fe144ac0acd823255b724bcb26debea1ac8cc0735052dc89335.jpg',
                    heading: 'Power, laser, and motion controls for Holographic Image processing machine',
                    name01: 'Power Distribution',
                    name02: 'Data Logging',
                    href: '/projects/zebra-imaging'
                },
                {
                    id: 6,
                    classnames: 'project-item power data',
                    srcimg: 'assets/img/gallery/427035ae6c9a4dec209ead90a0e27356e0b075781a9612831ae445f29ea2bed8.jpg',
                    heading: 'Power distribution, PLC HMI control on medical laser power systems 1',
                    name01: 'Power Distribution',
                    name02: 'Data Logging',
                    href: '/projects/medical-laser'
                },
                {
                    id: 7,
                    classnames: 'project-item motion data',
                    srcimg: 'assets/img/gallery/47aa2d63ccc1dbb4471163fbe2333e47cbd166eadfecee7d2d7ef9030ed821a7.jpg',
                    heading: 'Robotic testing of electronics using CoBots 2',
                    name01: 'Motion Control',
                    name02: 'Data Logging',
                    href: '/projects/cobots'
                },
                {
                    id: 8,
                    classnames: 'project-item energy power',
                    srcimg: 'assets/img/gallery/9e3f4a1481981d4ee94912c39671808c12bbc8022e819e064a8dfcccb5dae71c.jpg',
                    heading: 'Home Grid Sol-Ark',
                    name01: 'Energy',
                    name02: 'Power Distribution',
                    href: '/projects/sol-ark'
                },
                // {
                //     id: 9,
                //     classnames: 'project-item manufacturing data',
                //     srcimg: 'assets/img/gallery/192b95a99bc413f7a53366ab527828485dc5d6c809eda1b31417906e2d0b4b09.jpg',
                //     heading: 'Robotic testing of electronics using CoBots 1',
                //     name01: 'Manufacturing',
                //     name02: 'Data Logging',
                //     href: '/projects/cobots'
                // },
                {
                    id: 10,
                    classnames: 'project-item motion manufacturing',
                    srcimg: 'assets/img/gallery/08210c81b9f2939bfa5304a0f52a2b324bf9f50e6951a574bc87deb42e78dcd8.jpg',
                    heading: '2 Axis Wire Spool Winder Controls',
                    name01: 'Motion Control',
                    name02: 'Manufacturing',
                    href: '/projects/winder-controls'
                },
                // {
                //     id: 11,
                //     classnames: 'project-item motion power',
                //     srcimg: 'assets/img/gallery/093200bcd10fbfc87095abf36cd3f989eec2a7e0d888ab522eff6030f76bc304.jpg',
                //     heading: 'Redundant Controls for OTTO 120M',
                //     name01: 'Motion Control',
                //     name02: 'Power Distribution',
                //     href: '/projects/otto'
                // },
                {
                    id: 12,
                    classnames: 'project-item motion power',
                    srcimg: 'assets/img/gallery/59d7a503cba3c48adacf309388e7bda486d5e7e43e48f0a6bdb62558fd851ccc.jpg',
                    heading: 'Robotic assembly, adhesive application, and UV curing',
                    name01: 'Motion Control',
                    name02: 'Power Distribution',
                    href: '/projects/uv-curing'
                },
                {
                    id: 13,
                    classnames: 'project-item power energy',
                    srcimg: 'assets/img/gallery/efa49e7f80f04eca13016353ead86b94a35e74bdb068917681bc77dbe21d7350.jpg',
                    heading: '12 KWH Industrial UPS System 2',
                    name01: 'Power Distribution',
                    name02: 'Energy Storage',
                    href: '/projects/ups-system'
                },
                {
                    id: 14,
                    classnames: 'project-item control motion',
                    srcimg: 'assets/img/gallery/537440e75e5218ea51a6f6c1b6241e98004a0d969853e30c89e1b9a87bb0d03b.jpg',
                    heading: 'Laser notching production system motion controls',
                    name01: 'Control Systems',
                    name02: 'Motion Controls',
                    href: '/projects/laser-notching'
                },
                {
                    id: 15,
                    classnames: 'project-item control data',
                    srcimg: 'assets/img/gallery/a9dd432d893857798478ec9bfbfcdb27f2535d513dd63a554f144569911f0c29.jpg',
                    heading: 'Robotic Wafer inspection cells 2',
                    name01: 'Control Systems',
                    name02: 'Data Logging',
                    href: '/projects/robotic-wafer'
                },
                {
                    id: 16,
                    classnames: 'project-item power data energy',
                    srcimg: 'assets/img/gallery/cf5d878ae2a45244de4b8cbb572b7a985bd424817cf69f85429db3d6435b98b7.jpg',
                    heading: 'Power Server',
                    name01: 'Energy Storage',
                    name02: 'Power Distribution',
                    href: '/projects/power-server'
                },
                // {
                //     id: 17,
                //     classnames: 'project-item control data',
                //     srcimg: 'assets/img/gallery/b723a071cfbdf642831e447975fda89bf706fc95682a5ad7a3d7edb01b42ead9.jpg',
                //     heading: 'Robotic Wafer inspection cells 1',
                //     name01: 'Control Systems',
                //     name02: 'Data Logging',
                //     href: '/projects/robotic-wafer'
                // },
                {
                    id: 18,
                    classnames: 'project-item control data',
                    srcimg: 'assets/img/gallery/452e16faaa74194056187c1e0bb63fe4a29104e5d5f6cd34601fbec8984816a9.jpg',
                    heading: 'Power Distribution for 3D active Display 1',
                    name01: 'Control Systems',
                    name02: 'Data Logging',
                    href: '/projects/3d-display'
                },
                {
                    id: 19,
                    classnames: 'project-item control data',
                    srcimg: 'assets/img/gallery/27a8f81cf0427255e18c9291a26ca721d593b05de4a1e170aae0024bf90a5290.jpg',
                    heading: 'Robotic inspection system',
                    name01: 'Control Systems',
                    name02: 'Data Logging',
                    href: '/projects/inspection-system'
                },
                // {
                //     id: 20,
                //     classnames: 'project-item control data',
                //     srcimg: 'assets/img/gallery/0cb0b17ed1eba07e086295e2e9ec2d42c08d1e667baee89f7939a9e65fa110e6.jpg',
                //     heading: '6 Axis Vulcan Robot control using Ethercat on Beckhoff Controller',
                //     name01: 'Control Systems',
                //     name02: 'Data Logging',
                //     href: '/projects/3d-printer'
                // },
                // {
                //     id: 21,
                //     classnames: 'project-item energy power',
                //     srcimg: 'assets/img/gallery/a0307cd28335f7e09a9c95db2f9bd3d97397abbaf84420717f3d09de5b531189.jpg',
                //     heading: '12 KWH Industrial UPS system 1',
                //     name01: 'Energy Storage',
                //     name02: 'Power Distribution',
                //     href: '/projects/ups-system'
                // },
                // {
                //     id: 22,
                //     classnames: 'project-item control data',
                //     srcimg: 'assets/img/gallery/0cd3317b9e29a7f98f81a0d88d78bdd390f1df0bb39a2245eafc8668f8960b0b.jpg',
                //     heading: 'Power Distribution and Motion Controls',
                //     name01: 'Control Systems',
                //     name02: 'Data Logging',
                //     href: '/projects/otto'
                // },
                // {
                //     id: 23,
                //     classnames: 'project-item motion power',
                //     srcimg: 'assets/img/gallery/4b1f26111a87d9e0c31020ad0e6927e34c3fac56d5eca4933bd4abe6f9f690e1.jpg',
                //     heading: 'Power distribution and PLC HMI control on medical laser power systems',
                //     name01: 'Motion Control',
                //     name02: 'Power Distribution',
                //     href: '/projects/medical-laser'
                // },
                {
                    id: 24,
                    classnames: 'project-item control power',
                    srcimg: 'assets/img/gallery/78cee50f433441ad8c7e706fb0ba18eabe28b71cd1474df0bc3e06e244b4e30f.jpg',
                    heading: '4 axis test system for military testing application 2',
                    name01: 'Control Systems',
                    name02: 'Power Distribution',
                    href: '/projects/4-axis'
                },
                // {
                //     id: 25,
                //     classnames: 'project-item power manufacturing',
                //     srcimg: 'assets/img/gallery/ffe5a3acd772e3d9059fbd8c3e2cc191a01693b6247df2dc4c050beb4a42ece9.jpg',
                //     heading: 'Power Distribution for 3D active Display 2',
                //     name01: 'Power Distribution',
                //     name02: 'Manufacturing',
                //     href: '/projects/3d-display'
                // },
                {
                    id: 27,
                    classnames: 'project-item energy power',
                    srcimg: 'assets/img/gallery/957bcc950991d5bd77acf11d1cef13c766ac5a3afdc655a752905c8c066cdf97.jpg',
                    heading: 'Energy Switch for Dept of Energy Project Sunshot',
                    name01: 'Energy Storage',
                    name02: 'Power Distribution',
                    href: '/projects/energy-switch'
                },
                {
                    id: 28,
                    classnames: 'project-item manufacturing motion',
                    srcimg: 'assets/img/gallery/e8e7dd0980f4505c73c5e7960d43b4717945eadbece32b48fc8ce16af503506a.jpg',
                    heading: 'High Pressure Drill Bit Tester',
                    name01: 'Manufacturing',
                    name02: 'Motion Controls',
                    href: '/projects/nov-tank'
                },
                {
                    id: 29,
                    classnames: 'project-item energy power',
                    srcimg: 'assets/img/gallery/599b94e007e4027d336c2dd774278d76f49fa8f893a4f77fea3243abcecc0f3d.jpg',
                    heading: 'Home Power NanoGrid',
                    name01: 'Energy Storage',
                    name02: 'Power Distribution',
                    href: '/projects/nanogrid'
                },
                {
                    id: 30,
                    classnames: 'project-item energy power',
                    srcimg: 'assets/img/gallery/3e5c8cfaf9c84fcc9656640266e275ce2938bfcbbd310c4e0964c20bf2be4791.jpg',
                    heading: 'Home Power HomeGrid',
                    name01: 'Energy Storage',
                    name02: 'Power Distribution',
                    href: '/projects/homegrid'
                },

                {
                    id: 31,
                    classnames: 'project-item motion control',
                    srcimg: 'assets/img/gallery/3e5c8cfaf9c84fcc9656640266e275ce2938bfcbbd310c4e0964c20bf2be4791.jpg',
                    heading: 'Automated Magnet Mover',
                    name01: 'Motion Controls',
                    name02: 'Control Systems',
                    href: '/projects/magnet-mover'
                },
                // {
                //     id: 27,
                //     classnames: 'project-item control data',
                //     srcimg: 'assets/img/gallery/a302e6ff7c20075e979b61016868c614cdf2990a585a6cf6edead0731719b978.jpg',
                //     heading: '4 axis test system for military testing application 1',
                //     name01: 'Control Systems',
                //     name02: 'Data Logging',
                //     href: '/projects/4-axis'
                // },
                // {
                //     id: 28,
                //     classnames: 'project-item manufacturing power',
                //     srcimg: 'assets/img/gallery/f4bc602ff111c085ddb3fb7faea866f7a45eecd1f5248d5a49708733f70efc1e.jpg',
                //     heading: 'Laser notching production line, power distribution and safety system 2',
                //     name01: 'Control Systems',
                //     name02: 'Power Distribution',
                //     href: '/projects/power-safety'
                // }
            ]
        }
    }
    render() {
        return (
            <div className="themesflat-project style-1 isotope-project has-margin mg15 w25 data-effect clearfix">
                {
                this.state.projects.map(data =>(
                <div className={data.classnames} key={data.id}>
                    <div className="inner">
                        <div className="thumb data-effect-item has-effect-icon w40 offset-v-19 offset-h-49">
                            <img src={data.srcimg} alt="altimage" />
                            <div className="elm-link">
                                <a href={data.href} className="icon-1 icon-search" />
                                <a className="icon-1" href={data.href}></a>
                                     
                            </div>
                            <div className="overlay-effect bg-color-3" />
                        </div>
                        <div className="text-wrap">
                            <h5 className="heading">
                                <a href={data.href}>{data.heading}</a>
                            </h5>
                            <div className="elm-meta">
                                <span>
                                    <Link to="#">{data.name01}</Link></span>
                                <span>
                                    <Link to="#">{data.name02}</Link></span>
                            </div>
                        </div>
                    </div>
                </div>
                ))
                }
            </div>
        );
    }
}

export default TabProjectFull;