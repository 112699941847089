const bannerhome1 = [
    {
        id: 1,
        srcimg: 'assets/img/gallery/cdd686091668df80e3a3c9811e67010c33090df21935e5aac13d74367f774f26.jpg',
        classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600',
        classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700',
        datax: "['left','left','left','center']",
        datahset: "['34','34','34','0']",
        datavset1: "['-134','-134','-134','-134']",
        datavset2: "['-63','-63','-63','-63']",
        datavset3: "['2','2','2','2']",
        datavset: "['106','106','106','106']",
        classbtn: "tp-caption"
    },
    {
        id: 2,
        srcimg: 'assets/img/gallery/9569eb2170fb41884970500b2e88586127620a249a68b7b1b2aed0614db2869c.jpg',
        classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-center',
        classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-center',
        datax: "['center','center','center','center']",
        datahset: "['0','0','0','0']",
        datavset1: "['-134','-134','-134','-134']",
        datavset2: "['-63','-63','-63','-63']",
        datavset3: "['2','2','2','2']",
        datavset: "['106','106','106','106']",
        classbtn: "tp-caption text-center"
    },
    {
        id: 3,
        srcimg: 'assets/img/gallery/d322683bdbed4f44bdb74647140e58e445d08887fe07d3bd4fd54a5bff39d7d3.jpg',
        classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-right',
        classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-right',
        datax: "['right','right','right','center']",
        datahset: "['-14','-14','-14','0']",
        datavset1: "['-134','-134','-134','-134']",
        datavset2: "['-63','-63','-63','-63']",
        datavset3: "['2','2','2','2']",
        datavset: "['106','106','106','106']",
        classbtn: "tp-caption text-right"
    }
]

export default bannerhome1;