import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/c0058edfe4af6ce4ea7dd5fcabb07433a617d15de55882b9a430e5f773684f7c.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/559d47ba4d7d14fcf1521bdd3eee0d610bc8ac9bb776137561901b4079e842eb.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/gallery-3-800x450.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/gallery-4-800x450.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">ROBOTIC INSPECTION SYSTEM</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">
                        This machine performs automated inspection of electro-optical assemblies using a 2-axis positioning stage with 
                        high-definition cameras.     
                    </p>
                    <p>
                        This thing is about the size of a refrigerator. It is made to be sort of see-through. 
                        You want it to be dark inside because there are lights built into the camera. There's internal lighting. So you 
                        don't want a lot of lights from the room to interfere with the images. So there are internal LED lights.
                    </p>
                    <p>
                        The blue is really to filter out the Flourescent lighting from the room, but you can still look in and see what the 
                        machine is doing without opening up the door and letting a bunch of light in.
                        It comes with a screen and keyboard and mouse attached. That's where the user controls it from. It's running a 
                        Linux-based industrial PC. You raise the lid up to load the parts into it. The big red button is an emergency stop in case something is going haywire.
                    </p>
                    <p>
                        This machine does what a worker would be incapable of doing by himself. The worker would need a microscope and 
                        take the optical elements and visually check them, but it's pretty hard for a worker to do that. If a person were 
                        present to load the parts between inspections, this machine could run 24/7/365 for about a decade.
                    </p>
                </div>
            </div>
        );
    }
}

export default ContentProject;