import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/88abcc7c7d0f8eaeeab648e7731726e0e173f824fc374faca9037bc6dc9eed56.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/bbfe82351d726159c17b1b9719ca81838e26a90b37e74c99a0a3c19d3bf4b6b5.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/627ff468389bbab05c730d6041d517639859c985d210624102330c7c0cba8240.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/304c722d0a8fdd1ca7ae872ff4d3275f57dc2ee182d5d16e2b35e4016a7c2e90.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">UNINTERRUPTIBLE POWER SUPPLY (12 KWH) FOR INDUSTRIAL APPLICATIONS</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">
                        When your machines are off, you're losing money. Keep production on and operating at full capacity, even during power outages. Plus, protect
                        your valuable investments from damage due to unsafe power-offs with this industrial-sized uninterruptible power supply (UPS).
                        </p>
                    <p>    
                        This was the first energy storage system we built. 
                        This UPS was designed for a factory with machines that need to run and always have power. It is rated for 12 Kilowatt-hours (kWh).
                        It supplies up to 6 KW of power, so it can run at full power for up to 2 hours. That's plenty of time and power to save your business.
                    </p>
                    <p>
                        This UPS is a reliable backup source of power for any manufacturer operating big machines. It can apply 240-V single phase, which is  
                        for industrial processes. So when the power goes out at a factory, they don't have to shut their machine off. It could be used to 
                        run a robot. The intention behind designing it for 2 hours is that, if there is a power glitch at your factory, you have enough time to 
                        safely shut your process down without damaging your machines or leaving them in an unsafe condition around your staff. 
                    </p>
                    <p>
                        Most power outages last less than 1 hour, but in a factory, a power outage could mean disaster, broken machines, broken limbs, and the biggest 
                        thing -- loss of production. Stopped machines could mean losses of $100,000 an hour. This could be setup at your home and connected to a microgrid.
                        It could run a small American house for about 10 hours. This can be combined with some of the other projects like the Sol-Ark and Power Server seen 
                        below in "Related Projects".
                    </p>
                </div>
            </div>
        );
    }
}

export default ContentProject;