import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/6d42d1758c4eb4330be1a87bf89c610c14b6f5c90b154d42b5e3a0552971077b.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/b2d6f7d2df02718e253a5f14a87355facc0fb6cede342e4234f79a2b33272ef9.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/3e5c8cfaf9c84fcc9656640266e275ce2938bfcbbd310c4e0964c20bf2be4791.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/f6ae2d7cb8530daecf1c8062b71939f7dee37a2e51958b31a7c09ef72cc11c11.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">ROBOTIC TESTING OF ELECTRONICS USING COBOTS</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">
                    This type of robot is called a collaborative robot, or "CoBot." They are designed for people to be able to work closely 
                    around them. They have a lot of automated force-sensing so that if the robot runs into a person, it just stops. This prevents the people and machines 
                    from harm and keeps your operation running smoothly for a long time.
                    </p>
                    <p>
                    In this case, it is doing automated testing of electronic instruments called digital multimeters. They can be used for a wide range of labor-saving purposes, including production 
                    line testing. 
                    </p>
                    <p>
                    The return on investment of one of these things is incredible. A person working $30 an hour full time earns $60,000 per year. This robot costs the
                    same as that worker's annual salary. A full ROI in 1 year! Plus, this robot can work 24/7/365 and will last about 10 years!
                    </p>
                    <p>
                    Note: We did not design the robot. This is an off-the-shelf device. But we customized it for this business. We can do the same for you. We designed the mounting system 
                    and the conveyors and the end effectors (the thing that goes on the end of the robot -- the little gripper thing in the picture). There's an electrical actuator that we 
                    used for the gripper part and we designed the "fingers" and other parts it uses to interact with the electrical devices to test them and calibrate them.
                    </p>
                    <p>
                    This job involved all the services in our wheelhouse: Safety, Power Distribution, Motion Controls, Data Logging -- especialy data logging. That was a big 
                    thing with this one. 
                    </p>
                    <p>
                    We designed and developed its "Machine Vision." You can see in the picture above that it has a camera attached to it. The camera looks 
                    at the device and records the images it sees on the device. So what this one is doing is touching two metal probes to the multimeters, and those have an LED 
                    display. The actuator turns the knobs on the multimeter and then reads the voltage on it. The machine is actuating the electrical device and reading the 
                    feedback from it to detect any issues for repair or recalibration.
                    </p>
                    <p>
                    This unit is small, only about 8 square feet. We designed it to be portable, like a tabletop robot. So the owner could carry it around to different places and 
                    perform its services. We ended up building 6 of them for this client.
                    </p>
                </div>
            </div>
        );
    }
}

export default ContentProject;