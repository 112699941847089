import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
class SiteContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: [
                {
                    id: 1,
                    text01: 'Every project begins with a simple email or phone call. We love understanding your mission and goals for your microgrid project, and we collect as much info as possible to form a clear picture.',
                    text02: 'No matter what stage you are in -- microgrid completed or not yet begun -- our automation experts can offer you ways to improve efficiency and reliability at low cost.'
                },
                {
                    id: 2,
                    text01: 'Once we fully understand your project and goals, our Microgrid Controls experts get to work analyzing the perfect custom automation solution for your scenario.',
                    text02: 'The most important part of a microgrid that most people overlook is the communication interfaces -- making sure all your devices are talking with each other.'
                },
                {
                    id: 3,
                    text01: 'We build microgrids regularly out of our main shop in Austin, Texas. There, we can test components to ensure everything works together perfectly.',
                    text02: 'Once all the componenets are tested and ready for action, the Linestar team will come and perform the installation at your location or if you prefer to DIY, we ship you an assembled model.'
                }
            ]
        }
    }
    
    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                    <div className="themesflat-row equalize sm-equalize-auto clearfix">
                        <div className="span_1_of_6 bg-f7f">
                            <div className="themesflat-spacer clearfix" data-desktop={60} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-content-box clearfix" data-margin="0 10px 0 43px"
                                data-mobilemargin="0 15px 0 15px">
                                <div className="themesflat-headings style-2 clearfix">
                                    <div className="sup-heading">SPECIAL SERVICES</div>
                                    <h2 className="heading font-size-28 line-height-39">MICROGRID CONTROLS</h2>
                                    <div className="sep has-width w80 accent-bg margin-top-20 clearfix" />
                                    <p className="sub-heading margin-top-33 line-height-24">Our team of microgrid controls experts
                                        can help empower the home microgrid of your dreams. We know all the best batteries, power sources,
                                        and inverters. And we have controllers to automatically manage them.
                                        </p>
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={56} data-mobile={56} data-smobile={56} />
                        </div>
                        <div className="span_1_of_6 half-background style-2">
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={39} data-smobile={39} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title">SECURITY | RELIABILITY | INDEPENDENCE</h5>
                        <p> Home microgrids are becoming increasingly popular for the retail consumer. Formerly these powerhouses of reliability and independence were 
                            available only to hospitals, universities, and the super wealthy. Today, any person with the determination can purchase off-the-shelf materials
                            and assemble a working microgrid to power his home. Linestar makes it easy with time-tested product recommendations and some of our own products
                            we developed in-house through years of research and development. 
                        </p>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <div className="item">
                            <div className="inner">
                                <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10} data-smobile={10} />
                                <h5 className="title">PROVEN SUCCESS:</h5>
                                <p>We have successfully completed projects in numerous states across the continental United States.
                                    Linestar Automation has a proven track record of success building applications for highly demanding clients:</p>
                                <div className="themesflat-spacer clearfix" data-desktop={8} data-mobile={8} data-smobile={8} />
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">US Military</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">US FDA</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">US Dept of Energy</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">Global Manufacturing</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={35} />
                            <div className="themesflat-content-box clearfix" data-margin="0 0 0 11px" data-mobilemargin="0 0 0 0">
                                <div className="inner pd35 bg-ffc">
                                    <h5 className="title text-white line-height-24 padding-left-7">CHECK OUT SOME OF OUR FANTASTIC  
                                        MICROGRID WORK FOR OTHER CLIENTS</h5>
                                    <div className="themesflat-spacer clearfix" data-desktop={16} data-mobile={16}
                                        data-smobile={16} />
                                    <div className="button-wrap has-icon icon-right size-14">
                                    <a 
                                            href="../../../../assets/img/gallery/6269b81990a346bfb51ca82ced895e337e74ce2b492831b1d31885aa7f23f9e5.pdf"
                                            className="themesflat-button bg-white color-333 w100 font-weight-400 no-letter-spacing pd26"
                                            download="Linestar.pdf"
                                        >
                                            <span>Download .PDF 
                                                <span className="icon">
                                                    <i className="fa fa-file-pdf-o" />
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                    <div className="themesflat-spacer clearfix" data-desktop={6} data-mobile={6} data-smobile={6} />
                                    <div className="button-wrap has-icon icon-right size-14">
                                        <a 
                                            href="../../../../assets/img/gallery/9ddbf9ae73b482ff90953aed2a15ee578d9455f0e3474a98c85faf287bc926d6.docx"
                                            className="themesflat-button bg-white color-333 w100 font-weight-400 no-letter-spacing pd26 "
                                            download="Linestar.docx"
                                        >
                                            <span>Download .DOC 
                                                <span className="icon">
                                                    <i className="fa fa-file-word-o" />
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title no-letter-spacing">CONTACT US FOR MICROGRID CONTROL SERVICES TODAY</h5>
                        <div className="themesflat-spacer clearfix" data-desktop={27} data-mobile={27} data-smobile={27} />
                        <div className="themesflat-tabs style-2 title-w170 clearfix">
                            <ul className="tab-title clearfix ">
                                <li className="item-title active">
                                    <span className="inner">UNDERSTANDING</span>
                                </li>
                                <li className="item-title">
                                    <span className="inner">ANALYSIS</span>
                                </li>
                                <li className="item-title">
                                    <span className="inner">INSTALLATION</span>
                                </li>
                            </ul>
                            <div className="tab-content-wrap clearfix">
                                {
                                    this.state.tabs.map(data =>(
                                        <div className="tab-content" key={data.id}>
                                            <div className="item-content">
                                                <p>{data.text01}</p>
                                                <p>{data.text02}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                </div>
            </div>
        );
    }
}

export default SiteContent;