import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/593e45bea4d63b4ea52b926474c53e7e28625863a5a4da64d22f82d5b31156cc.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/6e0c40c05991212cb4c542a44337c16929f18df65f5b8fc99e175406616cce04.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/0360964d48ca0f997a03d0d8a6e0be8f0f6b0fa57b8e77273bcc32f3f253a030.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/77ecdf207c409336c400f49df977f03b7f1d39a99472f33839a2739132f39d0d.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">PROCESS CONTROL FOR 2-PHASE COOLING FOR HPC BLADES</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">This is a machine is used in data centers, and Linestar designed a way for it to swap
                        out existing computer servers in the data center and replace them with other ones entirely automated.
                        supervisors on the third shift. We built a data logging system that shows production rates, and if the machine 
                        had to get shut down, info on what the problem was, for betting tracking.
                    </p>
                </div>
            </div>
        );
    }
}

export default ContentProject;