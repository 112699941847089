import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/7c93de3ff6708904d5d06899559181f8f9abb4b1691bf3211c9af46b098cfe11.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/aa787c75ec39bcf0935da8754603b0664fb2a9ae25ed54bca3a5b72307cc88ab.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/f2a14dd7d2c9920583cfee59904571d088c8646ff035c18962af29f21dc9935a.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/1149617a28cc20647d4a2d1dc38b2b57f57a4cba55ed1dab5ec218af16f40150.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">POWER SERVER HOME ENERGY STORAGE AND POWER DISTRIBUTION</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">This device is so small that it fits in the palm of your hand. But it is so powerful, 
                        it can run your whole home or small business. Developed over years of research and intensive testing and prototyping, 
                        the Power Server by Linestar takes in all kinds of power supplies -- batteries, solar panels, gas generators -- and 
                        communicates between all of them and your home power needs. This little device can hook into as small or large an area 
                        as you have available and make sure that your lights stay on and powered up no matter what.
                    </p>
                    <p>Ask us about adding a Power Server to your home or business today. This small device packs a big punch and can save the 
                       productivity of your business when the power is out everywhere else.</p>
                </div>
            </div>
        );
    }
}

export default ContentProject;