import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
class SiteContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: [
                {
                    id: 1,
                    text01: 'Some customers think that a power strip is the power distribution system. You plug it in, and it\'s got 5 outlets. I\'ve actually had customers that built their machine with power strips, but you can\'t really do it that way.',
                    text02: 'Doing it right involves circuit breakers, contactors, bus bar systems, disconnect switches, and things like that.'
                },
                {
                    id: 2,
                    text01: 'Power Distribution usually represents about 20% of a job\'s total cost, but its value to your business is many times higher. Without proper Power Distribution, your machines will break, your staff will get injured, and the resulting fines and repairs will bankrupt you.',
                    text02: 'We save you money by carefully planning and assembling your power distribution unit in our shop in Austin, Texas using high quality parts built to last 10+ years.'
                },
                {
                    id: 3,
                    text01: 'Our Power Distribution units are pretty much maintenance-free, but if a circuit breaker trips, you need a qualified electrician or engineer to service it.',
                    text02: 'Most of the big plants we work with have in-house maintenance electricians. So depending on if you have qualified maintenance staff, you can fix it yourself if a circuit breaker trips or something like that.'
                }
            ]
        }
    }
    
    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                    <div className="themesflat-row equalize sm-equalize-auto clearfix">
                        <div className="span_1_of_6 bg-f7f">
                            <div className="themesflat-spacer clearfix" data-desktop={60} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-content-box clearfix" data-margin="0 10px 0 43px"
                                data-mobilemargin="0 15px 0 15px">
                                <div className="themesflat-headings style-2 clearfix">
                                    <div className="sup-heading">SPECIAL SERVICES</div>
                                    <h2 className="heading font-size-28 line-height-39">POWER DISTRIBUTION</h2>
                                    <div className="sep has-width w80 accent-bg margin-top-20 clearfix" />
                                    <p className="sub-heading margin-top-33 line-height-24">Generally anything bigger than a wall outlet needs
                                        proper power distribution to keep your machines and staff safe. We design to meet UL508A codes, the 
                                        standard for industrial control systems. 
                                        </p>
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={56} data-mobile={56} data-smobile={56} />
                        </div>
                        <div className="span_1_of_6 half-background style-2">
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={39} data-smobile={39} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title">POWER STARTS THE PROCESS</h5>
                            <p> 
                                Power Distribution is the process of splitting up the power coming into a machine or system into multiple feeds to make 
                                safe circuits. It is an essential component to building any Control System. Without power, your machines won't produce. 
                                But before that power can be fed to your machines, the incoming power first needs to turn on the Control System in charge 
                                of that machine. Then there are other components that also need ample power like motors and heaters and process elements 
                                and the power supplies that connect them. There is a lot involved, and it has to be done carefully and thoughtfully.
                            </p>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <div className="item">
                            <div className="inner">
                                <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10} data-smobile={10} />
                                <h5 className="title">PROVEN SUCCESS:</h5>
                                <p>We have successfully completed projects in numerous states across the continental United States.
                                    Linestar Automation has a proven track record of success building applications for highly demanding clients:</p>
                                <div className="themesflat-spacer clearfix" data-desktop={8} data-mobile={8} data-smobile={8} />
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">US Military</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">US FDA</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">US Dept of Energy</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">Global Manufacturing</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={35} />
                            <div className="themesflat-content-box clearfix" data-margin="0 0 0 11px" data-mobilemargin="0 0 0 0">
                                <div className="inner pd35 bg-ffc">
                                    <h5 className="title text-white line-height-24 padding-left-7">CHECK OUT SOME OF OUR FANTASTIC  
                                        POWER DISTRIBUTION WORK FOR OTHER CLIENTS</h5>
                                    <div className="themesflat-spacer clearfix" data-desktop={16} data-mobile={16}
                                        data-smobile={16} />
                                    <div className="button-wrap has-icon icon-right size-14">
                                    <a 
                                            href="../../../../assets/img/gallery/6269b81990a346bfb51ca82ced895e337e74ce2b492831b1d31885aa7f23f9e5.pdf"
                                            className="themesflat-button bg-white color-333 w100 font-weight-400 no-letter-spacing pd26"
                                            download="Linestar.pdf"
                                        >
                                            <span>Download .PDF 
                                                <span className="icon">
                                                    <i className="fa fa-file-pdf-o" />
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                    <div className="themesflat-spacer clearfix" data-desktop={6} data-mobile={6} data-smobile={6} />
                                    <div className="button-wrap has-icon icon-right size-14">
                                        <a 
                                            href="../../../../assets/img/gallery/9ddbf9ae73b482ff90953aed2a15ee578d9455f0e3474a98c85faf287bc926d6.docx"
                                            className="themesflat-button bg-white color-333 w100 font-weight-400 no-letter-spacing pd26 "
                                            download="Linestar.docx"
                                        >
                                            <span>Download .DOC 
                                                <span className="icon">
                                                    <i className="fa fa-file-word-o" />
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title no-letter-spacing">CONTACT LINESTAR'S POWER DISTRIBUTION EXPERTS TODAY</h5>
                        <div className="themesflat-spacer clearfix" data-desktop={27} data-mobile={27} data-smobile={27} />
                        <div className="themesflat-tabs style-2 title-w170 clearfix">
                            <ul className="tab-title clearfix ">
                                <li className="item-title active">
                                    <span className="inner">MISCONCEPTIONS</span>
                                </li>
                                <li className="item-title">
                                    <span className="inner">VALUE</span>
                                </li>
                                <li className="item-title">
                                    <span className="inner">MAINTENANCE</span>
                                </li>
                            </ul>
                            <div className="tab-content-wrap clearfix">
                                {
                                    this.state.tabs.map(data =>(
                                        <div className="tab-content" key={data.id}>
                                            <div className="item-content">
                                                <p>{data.text01}</p>
                                                <p>{data.text02}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                </div>
            </div>
        );
    }
}

export default SiteContent;