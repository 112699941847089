import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SiteContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iconbox : [
                {
                    id: 1,
                    icon: "autora-icon-quality",
                    title: "BEST QUALITY",
                    text: "Linestar is committed to meeting the highest quality standards and materials to automate industrial processes for global manufacturers."
                },
                {
                    id: 2,
                    icon: "autora-icon-time",
                    title: "ON TIME",
                    text: "Linestar works fast to fit your project's schedule. From idea phase to final installation, our engagements typically last 3-6 months."
                },
                {
                    id: 3,
                    icon: "autora-icon-author",
                    title: "EXPERIENCED",
                    text: "Linestar brings a combined 80+ years in the robotics industry to the table. We develop robotics for Pharmaceutical, Military, Food Processing, and more."
                }
            ],
            history: [
                {
                    id: 1,
                    content: [
                        {
                            id: 1,
                            year: '2010',
                            text: 'Linestar is founded in Austin, Texas. Our first job was designing and building high precision optomechanical holographic imaging systems for U.S. military applications.',
                            classnames: "flat-content-wrap",
                        },
                        {
                            id: 2,
                            year: '2019',
                            text: 'Linestar develops robotics for automated server maintenance at a data center, plus a Process Control System for their 2-phase liquid-cooling, high-performance computing system.',
                            classnames: "flat-content-wrap",
                        },
                    ]
                },
                {
                    id:2,
                    content: [
                        {
                            id: 3,
                            year: '2014',
                            text: 'Linestar branches into energy storage by designing and building military-grade UPS Power Backup Systems and Microgrid Control Systems for the US Department of Energy.',
                            classnames: "flat-content-wrap pd26",
                        },
                        {
                            id: 4,
                            year: '2022',
                            text: 'Linestar develops a data acquisition system that reads live machine data and saves it encrypted to the blockchain.',
                            classnames: "flat-content-wrap pd26",
                        },
                    ]
                }             
            ],
            accordions : [
                {
                    id: 1,
                    classnames: 'accordion-item active',
                    title: 'our mission',
                    text: 'Our mission is to design and build the highest quality machines to meet the custom needs of global manufacturers. Every Linestar design increases revenue for our clients by ensuring their machines safely produce consistent output while delivering up-to-the-minute data to help managers make smart decisions. If you can dream it, we can build it.'
                },
                {
                    id: 2,
                    classnames: 'accordion-item',
                    title: 'our vision',
                    text: 'We envision a world full of automation, where every business owner has dozens or hundreds of machines working for him around the clock to manufacture goods with as much labor-saving devices as possible. The businesses of the future will be entirely automated, with labor being an ever-shrinking expense for your business.'
                },
                {
                    id: 3,
                    classnames: 'accordion-item',
                    title: 'our technology',
                    text: 'We employ CAD for designing control systems and power distribution systems. We write and develop our own software that powers our creations. We use MQTT on the blockchain to securely remit encrypted machine data. We are always on the cutting edge of the latest technologies so our machines have lifespans that last decades.'
                }
            ]
        }
    }
    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />
                    <div className="themesflat-headings style-2 clearfix">
                        <h2 className="heading">OUR COMPANY</h2>
                        <div className="sep has-width w80 accent-bg clearfix" />
                        <p className="sub-heading line-height-24 text-777 margin-top-28 margin-right-12">Linestar is a small company 
                            that punches way above its weight class. Started by one man with a reputation for putting projects on the 
                            "fast track," Linestar was born out of the need by global manufacturers for fast custom automation.
                            Because we are smaller, we can work faster. From initial call, to idea phase, design, prototyping, manufacturing,  
                            and final installation, our time-tested process and experienced team cranks out reliable results for you in 
                            only 3 to 6 months. We take pride in every machine we produce and view each client as an opportunity to make 
                            the world a better place through imagining and building time-saving, money-saving, and labor-saving devices.</p>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={28} data-mobile={35} data-smobile={35} />
                    <div className="themesflat-row gutter-15 clearfix">
                        {
                            this.state.iconbox.map(data =>(
                                <div className="col span_1_of_4" key={data.id}>
                                    <div className="themesflat-content-box clearfix" data-margin="0 0px 0 0px" data-mobilemargin="0 0 0 0">
                                        <div
                                            className="themesflat-icon-box icon-top align-center has-width w95 circle light-bg accent-color border-light padding-inner pd33 style-1 clearfix">
                                            <div className="icon-wrap">
                                                <i className={data.icon} />
                                            </div>
                                            <div className="text-wrap">
                                                <h5 className="heading"><Link to="#">{data.title}</Link></h5>
                                                <div className="sep clearfix" />
                                                <p className="sub-heading">{data.text}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={45} data-mobile={35} data-smobile={35} />
                    <div className="themesflat-headings style-2 clearfix">
                        <h2 className="heading">OUR HISTORY</h2>
                        <div className="sep has-width w80 accent-bg clearfix" />
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={24} data-mobile={35} data-smobile={35} />
                    <div className="themesflat-row separator drank clearfix">
                        {
                            this.state.history.map(data => (
                                <div className="span_1_of_6 separator-solid" key={data.id}>
                                    {
                                        data.content.map(contents => (
                                            <div className={contents.classnames} key={contents.id}>
                                                <div className="title">{contents.year}</div>
                                                <p>{contents.text}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={38} data-mobile={35} data-smobile={35} />
                    <div className="themesflat-headings style-2 clearfix">
                        <h2 className="heading">OUR CORE VALUES</h2>
                        <div className="sep has-width w80 accent-bg clearfix" />
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={35} data-mobile={35} data-smobile={35} />
                    <div className="themesflat-content-box" data-margin="0 0 0 0" data-mobilemargin="0 0 0 0">
                        <div className="themesflat-accordions style-3 has-icon icon-left iconstyle-2 our-background clearfix">
                            {
                                this.state.accordions.map(data =>(
                                    <div className={data.classnames} key={data.id}>
                                        <h3 className="accordion-heading"><span className="inner">{data.title}</span></h3>
                                        <div className="accordion-content">
                                            <div>{data.text}</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                </div>
            </div>
        );
    }
}

export default SiteContent;