import React, { Component } from 'react';

class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accordion: [
                {
                    id: 1,
                    classname: 'accordion-item active',
                    heading: 'What can you Linestar for me?',
                    text: 'First, our team of dedicated specialists listen and understand your unique business challenges. Next, we design a solution that gives your business the consistent results you are seeking: better uniformity, reliability of outcome, safety, data about your process, automated manufacturing, etc. Then we prototype the solution, and finally, we implement it at your location.'
                },
                {
                    id: 2,
                    classname: 'accordion-item',
                    heading: 'What if I only need a design or prototype?',
                    text: 'We will work with you to learn exactly what your business needs to succeed. You are free to take as much or as little as we have to offer. If you only need a design, great! Maybe you love it and want to see it prototyped. If it works for you, and you want someone else to build it, that is perfectly fine. Whatever you need.'
                },
                {
                    id: 3,
                    classname: 'accordion-item',
                    heading: 'What industries does Linestar serve?',
                    text: 'The Linestar team has designed, prototyped, and installed robotic units including Control Systems, Communication Interfaces, Data Logging, Power Distribution, and more. We have served businesses in a wide range of industries including Medical, Pharmaceutical, Military, Food Processing, 3D Printing, Laser Imaging, Consumer Goods, and others.'
                }
            ]
        }
    }
    
    render() {
        return (
            <div className="col-md-6">
                <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={35} />
                <div className="themesflat-content-box" data-margin="0 0 0 18px" data-mobilemargin="0 0 0 0">
                    <div className="themesflat-headings style-1 clearfix">
                        <h2 className="heading">FAQs</h2>
                        <div className="sep has-width w80 accent-bg margin-top-11 clearfix" />
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={38} data-mobile={35} data-smobile={35} />
                    <div className="themesflat-accordions style-1 has-icon icon-left iconstyle-1 clearfix">
                        {
                            this.state.accordion.map(data => (
                                <div className={data.classname} key={data.id}>
                                    <h3 className="accordion-heading"><span className="inner">{data.heading}</span></h3>
                                    <div className="accordion-content">
                                        <div>{data.text}</div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

        );
    }
}

export default Faq;