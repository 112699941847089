import React, { Component } from 'react';

class SidebarBlog extends Component {
    render() {
        return (
            <div id="sidebar">
                <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={60} data-smobile={60} />
                <div id="inner-sidebar" className="inner-content-wrap">
                    <div className="widget widget_search">
                        <form action="#" method="get" role="search" className="search-form style-1">
                            <input type="search" className="search-field" placeholder="Search..." defaultValue={""} name="s"
                                title="Search for" />
                            <button className="search-submit" type="submit" title="Search">Search</button>
                        </form>
                    </div>{/* /.widget_search */}
                    {/* <div className="widget widget_follow">
                        <h2 className="widget-title"><span>FOLLOW US</span></h2>
                        <div className="follow-wrap clearfix col3 g8">
                            <div className="follow-item facebook">
                                <div className="inner">
                                    <span className="socials">
                                        <a href="#"><i className="fa fa-facebook" /></a>
                                        <span className="text">43217 <br /> likes</span>
                                    </span>
                                </div>
                            </div>
                            <div className="follow-item twitter">
                                <div className="inner">
                                    <span className="socials">
                                        <a href="#"><i className="fa fa-twitter" /></a>
                                        <span className="text">3752 flowers</span>
                                    </span>
                                </div>
                            </div>
                            <div className="follow-item google">
                                <div className="inner">
                                    <span className="socials">
                                        <a href="#"><i className="fa fa-google" /></a>
                                        <span className="text">432 contacter</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>/.widget_follow */}
                    <div className="widget widget_lastest">
                        <h2 className="widget-title"><span>RECENT POSTS</span></h2>
                        <ul className="lastest-posts data-effect clearfix">
                            <li className="clearfix">
                                <div className="thumb data-effect-item has-effect-icon ">
                                    <img src="../../../../assets/img/gallery/b5de6b9d27c9fd67b6280b4c4bcb5e185b165187000f7997e3ae740e95a6a077.jpg" alt="imagealt" />
                                    <div className="overlay-effect bg-color-2" />
                                    <div className="elm-a">
                                        <a href="/blog/data-center-hpc-control-cooling-with-robotic-blade-changer" className="icon-2" />
                                    </div>
                                </div>
                                <div className="text">
                                    <h3><a href="/blog/data-center-hpc-control-cooling-with-robotic-blade-changer">DATA CENTER HPC CONTROL AND COOLING WITH ROBOTIC BLADE CHANGER</a></h3>
                                    <span className="post-date"><span className="entry-date">30 September 2022</span></span>
                                </div>
                            </li>
                            <li className="clearfix">
                                <div className="thumb data-effect-item has-effect-icon">
                                    <img src="../../../../assets/img/gallery/914f65c6479dd0a44f09f0dd886deea2c7c21c7380b43daa828e6d31b15d500d.jpg" alt="imagealt" />
                                    <div className="overlay-effect bg-color-2" />
                                    <div className="elm-a">
                                        <a href="/blog/power-distribution-safety-process-control" className="icon-2" />
                                    </div>
                                </div>
                                <div className="text">
                                    <h3><a href="/blog/power-distribution-safety-process-control">POWER DISTRIBUTION, SAFETY, AND PROCESS CONTROL</a></h3>
                                    <span className="post-date"><span className="entry-date">29 August 2022</span></span>
                                </div>
                            </li>
                            <li className="clearfix">
                                <div className="thumb data-effect-item has-effect-icon">
                                    <img src="../../../../assets/img/gallery/b2c7cbba676f90c5c01e20a24d6ed1ab0ee2fce7f3e4ba5d214185f90f78dc82.jpg" alt="imagealt" />
                                    <div className="overlay-effect bg-color-2" />
                                    <div className="elm-a">
                                        <a href="/blog/microgrid-under-10-thousand-dollarsd" className="icon-2" />
                                    </div>
                                </div>
                                <div className="text">
                                    <h3><a href="/blog/microgrid-under-10-thousand-dollars">BUILD A MICROGRID FOR UNDER $10,000</a></h3>
                                    <span className="post-date"><span className="entry-date">14 July 2022</span></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="widget widget_tags margin-top-55">
                        <h2 className="widget-title"><span>TAGS</span></h2>
                        <div className="tags-list">
                            <a href="/services/control-systems">Control Systems</a>
                            <a href="/services/safety-systems">Safety Systems</a>
                            <a href="/services/safety-systems">OSHA Compliance</a>
                            <a href="/services/microgrid-controls">Microgrids</a>
                            <a href="/services/energy-storage">Energy Storage</a>
                            <a href="/services/data-logging">Data Logging</a>
                                </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SidebarBlog;