import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/14ebae325ff371a818d3812fff351bfd7be1803cb7beff1f08c3faf6bbbc69b6.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/25b0b812240a54dd9f31a6dcd1ab05977898a44e1f0e2346329a90276e3f3f41.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/09d02d5575c55caae8db91e3a187822917311cd38d4c7219e7b2dfdec9753e3c.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/8e3f1dca2f230cfb15506c27388c1a92ac93693b76ad14b4a26b6f345df73953.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">POWER DISTRIBUTION FOR 3D DISPLAY</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">
                        Picture Star Wars with Princess Lea coming out of the R2D2 -- where it's projecting a holographic image. That's what this thing does. 
                        This device diplays holographic images in 3D above that plate of glass on top. 
                    </p>
                    <p>
                        This was used by the US Military and to map territories and terrain using digital 3D rendering for pinpoint accuracy in para-military 
                        operations. Among other things. They also had some applications in construction where they would show a building layout. Pretty cool.
                    </p>
                    <p>
                        This thing had like 50 projectors built into it. Like regular projectors that your project onto a wall. Like a small half-inch square 
                        projector. But an array of them. With optics. So each projector is projecting a different part of this image. And they are made to focus 
                        on different heights on the glass. It will make a 3D image that looks like something is floating in the air basically.
                    </p>
                </div>
            </div>
        );
    }
}

export default ContentProject;