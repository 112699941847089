import React, { Component } from 'react';

class Sidebar extends Component {
    render() {
        return (
            <div id="sidebar">
                <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={0} data-smobile={0} />
                <div id="inner-sidebar" className="inner-content-wrap">
                    <div className="widget widget_list">
                        <div className="inner">
                            <ul className="list-wrap">
                                <li className="list-item">
                                    <a href="/services/control-systems"><span className="text">Control Systems</span></a>
                                </li>
                                <li className="list-item">
                                    <a href="/services/power-distribution"><span className="text">Power Distribution</span></a>
                                </li>
                                <li className="list-item">
                                    <a href="/services/data-logging"><span className="text">Data Acquisition</span></a>
                                </li>
                                <li className="list-item">
                                    <a href="/services/communication-interfaces"><span className="text">Communication Interfaces</span></a>
                                </li>
                                <li className="list-item">
                                    <a href="/services/motion-controls"><span className="text">Motion Controls</span></a>
                                </li>
                                <li className="list-item">
                                    <a href="/services/microgrid-controls"><span className="text">Microgrid Controls</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="widget widget_help align-center has-shadow no-sep has-border border-solid">
                        <div className="inner">
                            <h2 className="widget-title margin-bottom-14"><span>HOW CAN WE HELP?</span></h2>
                            <p className="text line-height-26 margin-bottom-23">
                                Earn more revenue today with automation. Call or email us now.
                            </p>
                            <div className="elm-button">
                                <a href="/contact-us" className="themesflat-button bg-accent pd30">GET IN TOUCH</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={60} data-smobile={60} />
            </div>
        );
    }
}

export default Sidebar;