import React, { Component } from 'react';

class Service extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slidecard: [ //370x250
                {
                    id:1,
                    srcimg: 'assets/img/gallery/88550fd5e51d5a22fbd853269661d9de3468f1c866ca65885a21449a9f9ec031.jpg',
                    heading: 'CONTROL SYSTEMS',
                    link: '/services/control-systems'
                },
                {
                    id:2,
                    srcimg: 'assets/img/gallery/82506ae89b456ffefd0403d5151aa5f33a622394bfa1230b5ffaf7068cc83df6.jpg',
                    heading: 'SAFETY SYSTEMS',
                    link: '/services/safety-systems'
                },
                {
                    id:3,
                    srcimg: 'assets/img/gallery/95a49bbd42717a80d6986181a8a9e8ade30fb9284ef0fb81f61a7de6228108d1.jpg',
                    heading: 'MICROGRID SYSTEMS',
                    link: '/services/microgrid-controls'
                },
                {
                    id:4,
                    srcimg: 'assets/img/gallery/78d4b3c405cd1c16a41b44b00bcad05a813b2d3eaef67314ca1e573fe010e8d6.jpg',
                    heading: 'POWER DISTRIBUTION',
                    link: '/services/power-distribution'
                },
                {
                    id:5,
                    srcimg: 'assets/img/gallery/ebd997729b37120f896828ca27daedb08fab2b9e2d6b429b2fbd93322debfa18.jpg',
                    heading: 'COMMUNICATION INTERFACES',
                    link: '/services/communication-interfaces'
                },
                {
                    id:6,
                    srcimg: 'assets/img/gallery/967a7fd5bd5f0e9560fcf66127b945e6c19715549af8ba312fb33267e06797c6.jpg',
                    heading: 'DATA LOGGING',
                    link: '/services/data-logging'
                }
            ]
        }
    }
    
    render() {
        return (
            <div className="themesflat-carousel-box data-effect clearfix" data-gap={30} data-column={3} data-column2={2}
                data-column3={1} data-auto="false">
                <div className="owl-carousel owl-theme">
                   
                        {
                            this.state.slidecard.map(data => (
                                <a href={data.link}><div className="themesflat-image-box style-1 has-icon icon-right w65 clearfix"key = {data.id}>
                                    <div className="image-box-item" >
                                        <div className="inner">
                                            <div className="thumb data-effect-item">
                                                <img src={data.srcimg} alt="altimage" />
                                                <div className="overlay-effect bg-color-accent" />
                                            </div>
                                            <div className="text-wrap">
                                                <h5 className="heading"><a href={data.link}>{data.heading}</a></h5>
                                                <span className="icon-wrap">
                                                    <i className="fa fa-angle-right" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div></a>
                            ))
                        }

                </div>
            </div>
        );
    }
}

export default Service;