import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/c0bee9425762f8e8ff2150821cff202c0d41a673767e96bf6ee573eaeb5d21df.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/323664e7400ca21a4c92ead71113b8fcfa82dbaac05598dc1f14c07fe1e67f00.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/8dba34bc14c4c9e5bf79a9601ab54bcb10f941fe992a5b8f3c96ab6eca8d25a2.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/e52dfcdc57d6a147bf711ff489b1ab6d869e7d00e0694d94c64ee010b4d12c10.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">2-AXIS WIRE SPOOL WINDER & UNWINDER CONTROLS</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">
                        One of them basically runs in reverse to the other one. This machine rotates the drum pictured above. 
                        You can't see it very well, but there's a mechanism at the top. it's kind of like a fishing rod does 
                        when it winds up. One axis is turning the drum. The other is controlling the wire as it winds and unwinds.
                    </p>
                    <p>
                        Take close-ups of the different components on this one: Auto-winder on top. Auto spooling. When you're unwinding, 
                        it doesn't really matter, but when you are winding it up, you want it to be in nice, even layers so the machine 
                        performs reliably and predictably when interacting with other machines. It keeps a nice even tension on the wire. 
                        Otherwise, the layers might get all tangled up or bunched up in the middle, which would be a pain for someone to 
                        unwind manually. 
                    </p>
                    <p>
                        This machine replaces a worker. I don't really know if they can even do this manually or not. They have a worker 
                        that watches over it, but he doesn't have to sit there the whole time because it's automatic. This machine can run 
                        24/7/365 if the factory has 3 shifts.
                    </p>
                    <p>
                        NOTE: This machine is controlled from the "Laser Notcher" (Link below)
                    </p>
                </div>
            </div>
        );
    }
}

export default ContentProject;