import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/7d921b30e38010f3de3b0e0d6310defef7aa7c0538189e29987fbdd5ab49a649.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/b2e74546e69bf7788209b0199b28011826db57e0b3ccfc6e60d48b5b23764064.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/19cda5b2e30037a143765095c8d36713c18c71fece7cfec4e53de1ab660d6c59.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/91f724bdeed2be56f00ce23f982dc589a8cac8f72ea5977ef93be6da7f971334.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">LASER NOTCHING PRODUCTION SYSTEM MOTION CONTROLS</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">
                        This is a machine that does laser notching for a company that manufactures precise electrical components. 
                    </p>
                    <p>    
                        Linestar designed and produced the control system for this machine involving motion controls, a touch-screen user interface, power distribution
                        data logging, and lots of safety systems built in. We didn't design the mechanical part of it (we usually don't), but we made it all work together.
                    </p>
                    <p>
                        This machine cuts two different gauges of wires that are pretty close to the same thickness. We added motion controls 1) to feed the wires through the
                        machine from a spool and 2) to position the laser. Then the laser would burn a notch in the insulation of the wire to prepare the it for a later process 
                        involving a different machine. This is called "Laser Ablation." The laser heats and burns the insulation off, or "ablates" it. 
                    </p>
                    <p>
                        This is a very sensitive processes, and the potential for dangerous accidents is high, so we built in a ton of safety. We also added a touch-screen for 
                        easy operation. You can see the touch screen at the top of these pictures, and the motion control and power distribution systems in the gray box below.
                    </p>
                </div>
            </div>
        );
    }
}

export default ContentProject;