import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class RelateProjectPowerSafety extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: [
                {//images are 740x490
                    id: 1,
                    srcimg: '../../../../assets/img/gallery/3768602bdb12ca4001eede9a1d7066b1cc9af3f4ff5103bb548c172c41f27a6a.jpg',
                    heading: 'HOME GRID SOL ARK',
                    meta: 'Energy Storage',
                    text: 'Power Distribution',
                    link: '/projects/sol-ark'
                },
                {
                    id: 2,
                    srcimg: '../../../../assets/img/gallery/30a9d76d7d3732b3708e20bfbd59e4213e5f69d690c6c2910ff5fc37f3bbf4a7.jpg',
                    heading: 'HOLOGRAPHIC IMAGING MACHINE',
                    meta: 'Motion Controls',
                    text: 'Manufacturing',
                    link: '/projects/zebra-imaging'
                },
                {
                    id: 3,
                    srcimg: '../../../../assets/img/gallery/f59fbcb6aacdd4167a2671ad46482ea7a35d8b09e2a0a70beb1c1cbd2358a09c.jpg',
                    heading: 'ROBOTIC INSPECTION SYSTEM',
                    meta: 'Control System',
                    text: 'Manufacturing',
                    link: '/projects/inspection-system'
                },
                {
                    id: 4,
                    srcimg: '../../../../assets/img/gallery/5e630d82b772bad59dddd70dd3dc3de2eba341a3a51a42a978150a126858a811.jpg',
                    heading: 'HOME POWER SERVER',
                    meta: 'Energy Storage',
                    text: 'Power Distribution',
                    link: '/projects/power-server'
                },
                {
                    id: 5,
                    srcimg: '../../../../assets/img/gallery/6d3a066f6b29110fa728cbacd899f1ee3c9a33077e344235046e19c3976a516d.jpg',
                    heading: 'AUTOMATIC SERVER REPLACER',
                    meta: 'Control System',
                    text: 'Power Distribution',
                    link: '/projects/edge-box'
                },
                {
                    id: 6,
                    srcimg: '../../../../assets/img/gallery/92aed1b59f11fa22e8064658ef4d4cd00fdbc90a33c867e5b432c31319f83bf2.jpg',
                    heading: 'ROBOTIC WAFER INSPECTION SYSTEM',
                    meta: 'Control System',
                    text: 'Manufacturing',
                    link: '/projects/robotic-wafer'
                },
            ]
        }
    }
    render() {
        return (
            <div className="themesflat-carousel-box data-effect has-arrows arrow-top arrow75 arrow-circle arrow-style-2 clearfix" data-gap={30} data-column={3} data-column2={2} data-column3={1}
                data-auto="false">
                <div className="owl-carousel owl-theme">
                    {
                        this.state.data.map(data => (
                            <div className="themesflat-project style-1 clearfix" key={data.id}>
                                <div className="project-item">
                                    <div className="inner">
                                        <div className="thumb data-effect-item has-effect-icon w40 offset-v-43 offset-h-46">
                                            <img src={data.srcimg} alt="altimage" />
                                            <div className="text-wrap text-center">
                                                <h5 className="heading"><a href={data.link}>{data.heading}</a></h5>
                                                <div className="elm-meta">
                                                    <span>
                                                        <Link to="#">{data.meta}</Link></span>
                                                    <span>
                                                        <Link to="#">{data.text}</Link></span>
                                                </div>
                                            </div>
                                            <div className="elm-link">
                                                <a href={data.link} className="icon-1 icon-search"></a>
                                                <a href={data.link} className="icon-1"></a>
                                            </div>
                                            <div className="overlay-effect bg-color-3" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default RelateProjectPowerSafety;