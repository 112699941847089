import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/3c2085e35a5a25e6a928c95bd249163127aee4982cf54d43fc64bbcb4f7e74fb.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/gallery-2-800x450.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/gallery-3-800x450.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/gallery-4-800x450.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">ROBOTIC ASSEMBLY, ADHESIVE APPLICATION AND UV CURING</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">
                        This is a machine that robotically assembles a holographic imaging product, applies adhesive to a piece of flat material, 
                        and then "cures" the adhesive using Ultra Violet light. This is done in Austin, Texas. 
                    </p>
                    <p>    
                        It is used for assembling optical systems for military and other applications. This is for a manufacturer for a 3D display.
                        You can see that it has a large screen attached as a communication interface. 
                    </p>
                    <p>
                        Picture <i>Star Wars</i> with Princess Lea coming out of the R2D2 -- where it's projecting a holographic image. The stuff 
                        that this robot manufactures is the stuff that gets fed into another machine like R2D2. A holographic display system (see 
                        below). This took about 4 months to complete.
                    </p>
                </div>
            </div>
        );
    }
}

export default ContentProject;