import React, { Component } from 'react';

class Partner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            partner: [
                {
                    id: 1,
                    img: 'assets/img/gallery/17323731f7392c6dfe013792b6d501a6c8c8fe7ef1dabe58f6cfcd0659e640a9.jpg',
                    imgcolor: 'assets/img/gallery/ba8cee5f342f3e34dc29d451e3d86fac5e161f23879024fa12804215defa4331.jpg'
                },
                {
                    id: 2,
                    img: 'assets/img/gallery/a8fdf8d829a07e2ca8320fed52e8ce11d9cd3b272a8b1e2d0aaada3622d45edc.jpg',
                    imgcolor: 'assets/img/gallery/a8fdf8d829a07e2ca8320fed52e8ce11d9cd3b272a8b1e2d0aaada3622d45edc.jpg'
                },
                {
                    id: 3,
                    img: 'assets/img/gallery/987eae006a2dea4057a6cbed0868fc5391f9c79437f98eabc0340c5a44ca4fb1.jpg',
                    imgcolor: 'assets/img/gallery/a70f07aea92ee0b3ef6fcadfc7cc5d7a79385ee37d08b3644f583ce28aeea748.jpg'
                },
                {
                    id: 4,
                    img: 'assets/img/gallery/47df4485bc85468a10e12b9da9023e25d11f9d1cce97cdbe604b7e5b1953783b.jpg',
                    imgcolor: 'assets/img/gallery/3ad962154cef2c75612ebc25a8a82ea73c93fea7a81cb07bf341773b5a61a4b7.jpg'
                },
                {
                    id: 5,
                    img: 'assets/img/gallery/24558b7e6b07f57de8d1a7a2fa654a95a3d3063f2c0dcb8f7222c56600aa90d3.jpg',
                    imgcolor: 'assets/img/gallery/ed26bc3f52647a4385d3e167fc6082f470a7e45e84aaecdbf94576117c9340aa.jpg'
                },
                {
                    id: 6,
                    img: 'assets/img/gallery/56289f4e3cad941aba5ad019581f4129a63e36b64d52538f88a762197776a964.jpg',
                    imgcolor: 'assets/img/gallery/5e5d92d95c732a90cdff4ac34365d8003ed1d35633a453a3fa1fb2840575bd78.jpg'
                },
                {
                    id: 7,
                    img: 'assets/img/gallery/3388283ce47b55368f119f472c65b52b53aa89638f53c5cd6763a29be1f238c6.jpg',
                    imgcolor: 'assets/img/gallery/8b183668ec8654f18130ba84ce050f2245fb8981c6e8b65f96440e7ae6b86050.jpg'
                },
                {
                    id: 8,
                    img: 'assets/img/gallery/d8b9c422a1f60f289d316913d2de642a6ad7bc8fcce15ed9019737949318c817.jpg',
                    imgcolor: 'assets/img/gallery/9493691601434061d7c75cbb8a980b6766d8d9adbd7ac3ef2c7230dea45e10de.jpg'
                },
                {
                    id: 9,
                    img: 'assets/img/gallery/05410c06b0b0ff15eb7083fe3d0a9dce492ec295ec8fc6220a1178d93ed8a4a4.jpg',
                    imgcolor: 'assets/img/gallery/7bb36a8788fb0fc588055120ad4612780d5540bc1892a9906efce770baf717a5.jpg'
                },
                {
                    id: 10,
                    img: 'assets/img/gallery/51df5c7cfcac4b7bab6fc4b7b5ced76becf3b24f5e38edbaad78c6cdf449586e.jpg',
                    imgcolor: 'assets/img/gallery/c2c3ac7a61d1be3f8738a930b4e26d9f9715f998ec9c6391676f8ca01c02e88c.jpg'
                }
            ]
        }
    }
    
    render() {
        return (
            <div className="row-partner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="themesflat-spacer clearfix" data-desktop={83} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-carousel-box clearfix" data-gap={5} data-column={5} data-column2={3}
                                data-column3={2} data-auto="true">
                                <div className="owl-carousel owl-theme">
                                    {
                                        this.state.partner.map(data =>(
                                            <div className="themesflat-partner style-1 align-center clearfix" key={data.id}>
                                                <div className="partner-item">
                                                    <div className="inner">
                                                        <div className="thumb">
                                                            <img src={data.img} alt="altimage"
                                                                className="partner-default" />
                                                            <img src={data.imgcolor} alt="altimage"
                                                                className="partner-color" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={66} data-mobile={60} data-smobile={60} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Partner;