import React, { Component } from 'react';

class SingleContent001 extends Component {
    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    <article className="hentry data-effect">
                        <div className="post-media data-effect-item has-effect-icon offset-v-25 offset-h-24 clerafix"> 
                        {/* 840x385 */}
                            <a href="/blog-single"><img src="/assets/img/gallery/57ad3b1b3d48ac02acad56e91b31c3bb5a63afc68e1baf7352a5b4e4e49eb6bb.jpg" alt="altimage" /></a>
                            <div className="post-calendar">
                                <span className="inner">
                                    <span className="entry-calendar">
                                        <span className="day">14</span>
                                        <span className="month">MAR</span>
                                    </span>
                                </span>
                            </div>
                            <div className="overlay-effect bg-color-1" />
                            <div className="elm-a">
                                <a href="/blog-single" className="icon-1" />
                            </div>
                        </div>
                        <div className="post-content-wrap clearfix">
                            <h2 className="post-title">
                                <span className="post-title-inner">
                                    <a href="/blog-single">BUILD A MICROGRID FOR UNDER $10,000</a>
                                </span>
                            </h2>
                            <div className="post-meta">
                                <div className="post-meta-content">
                                    <div className="post-meta-content-inner">
                                        <span className="post-date item"><span className="inner"><span className="entry-date">March
                                                    14, 2024</span></span></span>
                                        <span className="post-by-author item"><span className="inner"><a href="#">By:
                                                    Frank Zeiler</a></span></span>
                                        {/* <span className="comment item"><span className="inner"><a href="#">3
                                                    Comments</a></span></span> */}
                                    </div>
                                </div>
                            </div>
                            <div className="post-content post-excerpt margin-bottom-43">
                                <p className="line-height-27 letter-spacing-005">
                                    If you want to build a microgrid for under $10,000, it is totally possible. But it won't be as automatic as one that is 
                                    more expensive and requires rewiring your house. Think, "Backup power that you plug in manually when the power goes out."
                                    </p>
                                    <p>
                                        If you can set your expectations at a reasonable level, then you will see you can provide your family home or office
                                        with backup power for several days on quite a reasonable budget.
                                    </p>
                                    <h3>
                                        Our friends at <a href="https://theMicroGridStore.com">The MicroGrid Store</a> have you covered on all the essentials.
                                    </h3>

                                <div className="box-excerpt clearfix">
                                    <div className="thumb">
                                    {/* 251x196 */}
                                    <a href="https://themicrogridstore.com/product/microgrid-package-basic/"><img src="https://themicrogridstore.com/wp-content/uploads/2023/02/IMG_8484-scaled-300x300.jpg" alt="altimage" /></a>
                                    </div>
                                    <div className="text">
                                        <p className="line-height-27 letter-spacing-005">
                                            First, you are going to want to head over to <a href="https://theMicroGridStore.com">theMicroGridStore.com </a> 
                                            and pick up one or two of the biggest batteries you can afford. Also make sure you pick up a generator or inverter if
                                            you don't already have one. The MicroGrid Store sells pre-built packages, the smallest of which is just $9,999.
                                            </p>
                                            <br>
                                            </br>
                                            <p>
                                                The ones I recommend are the Delta Pro by EcoFlow and EcoFlow AC/DC inverter. Or, if you want to save money, you can
                                                buy the complete package from TheMicroGridStore.com. They build and test all their Microgrid & Nanogrid packages before 
                                                sending them out to you, fully assembled and ready to go. No thinking or skills necessary. They ship to you!
                                            </p>
                                    </div>
                                </div>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    <h3>When the power goes out, you're covered.</h3>
                                    Just plug in whatever you need into the backup batteries. They can power a refrigerator, lights, a hair dryer, and pretty much
                                    everything you need. You can even put the two of them together with a special cable attachment to power devices that require 
                                    240V of power, like a standard washing machine. You won't have to worry next time your lights go out.
                                    </p>
                            </div>
                            <div className="post-tags-socials clearfix">
                                <div className="post-tags ">
                                    <span>Tags :</span>
                                    <a href="/services/microgrid-services">Microgrids</a>
                                    <a href="/services/power-distribution">Power Distribution</a>
                                    {/* <a href="/services/microgrid-services">Renewable</a> */}
                                </div>
                                <div className="post-socials ">
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=linestarautomation.com" className="facebook"><span className="fa fa-facebook-square" /></a>
                                    <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" className="twitter"><span className="fa fa-twitter" /></a>
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=linestarautomation.com" className="linkedin"><span className="fa fa-linkedin-square" /></a>
                                    {/* <a href="#" className="pinterest"><span className="fa fa-pinterest-p" /></a> */}
                                </div>
                            </div>
                        </div>
                    </article>
                    <div className="themesflat-pagination clearfix no-border padding-top-17">
                        <ul className="page-prev-next">
                            {/* <li>
                                <a href="#" className="prev">
                                    Previous Article
                                </a>
                            </li> */}
                            <li className="text-right">
                                <a href="#" className="next">
                                    Next Article
                                </a>
                            </li>
                        </ul>
                    </div>
                    {/* <div id="comments" className="comments-area">
                        <h2 className="comments-title">3 COMMENTS</h2>
                        <ol className="comment-list">
                            <li className="comment">
                                <article className="comment-wrap clearfix">
                                    <div className="gravatar"><img alt="altimage" src="assets/img/gallery/24e4066d6808a26351f98397351f2e8c1f397db4903e7d7df703620375cb9a53.jpg" />
                                    </div>
                                    <div className="comment-content">
                                        <div className="comment-meta">
                                            <h6 className="comment-author">Arthur Murray</h6>
                                            <span className="comment-time">December 11, 2022 - at 11:40 am</span>
                                            <span className="comment-reply"><a className="comment-reply-a"
                                                    to="#">REPLY</a></span>
                                        </div>
                                        <div className="comment-text">
                                            <p>I am looking to build my own microgrid in Georgia. Anything I should know first
                                                before I start hooking things up to the grid? Thanks.
                                                 </p>
                                        </div>
                                    </div>
                                </article>
                                <ul className="children">
                                    <li className="comment">
                                        <article className="comment-wrap clearfix">
                                            <div className="gravatar"><img alt="altimage"
                                                    src="assets/img/gallery/bd1aa833b4f7d4e69add6c917c7294042a74c89577232586143cd5396df6d26c.jpg" /></div>
                                            <div className="comment-content">
                                                <div className="comment-meta">
                                                    <h6 className="comment-author">José Ortega</h6>
                                                    <span className="comment-time"> December 11, 2022 - at 12:31 am</span>
                                                    <span className="comment-reply"><a className="comment-reply-a"
                                                            to="#">REPLY</a></span>
                                                </div>
                                                <div className="comment-text">
                                                    <p>Call your local electric and gas companies and ask them first before you do
                                                        anything regarding hooking into the grid! They may be able to help you, and
                                                        they may help you avoid hurting yourself!
                                                        </p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                </ul>
                            </li>
                            <li className="comment">
                                <article className="comment-wrap  clearfix">
                                    <div className="gravatar"><img alt="altimage" src="assets/img/gallery/bed2ad3e1ed76ffd49e8a6b380178c6481ea5076ef26eb85d77c5ba065e02900.jpg" />
                                    </div>
                                    <div className="comment-content">
                                        <div className="comment-meta">
                                            <h6 className="comment-author">Michael Bowman</h6>
                                            <span className="comment-time">December 12, 2022 - at 4:28 pm</span>
                                            <span className="comment-reply"><a className="comment-reply-a"
                                                    to="#">REPLY</a></span>
                                        </div>
                                        <div className="comment-text">
                                            <p>How many kilowatt hours do I need to prepare for a medium-sized house, about 
                                                2300 sq ft? Do I need extra battery backups, or what? I am putting solar panels
                                                on the garage roof and want to be totally off the grid.</p>
                                        </div>
                                    </div>
                                </article>
                            </li>
                        </ol>
                        <div id="respond" className="comment-respond">
                            <h3 id="reply-title" className="comment-reply-title margin-bottom-31">Leave a Comment</h3>
                            <form action="#" method="post" id="commentform" className="comment-form">
                                <div className="text-wrap clearfix">
                                    <fieldset className="name-wrap">
                                        <input type="text" id="author" className="tb-my-input" name="author" tabIndex={1}
                                            placeholder="Name*" defaultValue={""} size={32} aria-required="true"/>
                                    </fieldset>
                                    <fieldset className="email-wrap">
                                        <input type="email" id="email" className="tb-my-input" name="email" tabIndex={2}
                                            placeholder="Email*" defaultValue={""} size={32} aria-required="true" />
                                    </fieldset>
                                </div>
                                <fieldset className="message-wrap">
                                    <textarea id="comment-message" name="comment" rows={8} tabIndex={4} placeholder="Message*"
                                        aria-required="true" defaultValue={""} />
                                    </fieldset>
                            <p className="form-submit">
                            <input name="submit" type="submit" id="comment-reply" className="submit" defaultValue="SEND US" />
                            <input type="hidden" name="comment_post_ID" defaultValue={100} id="comment_post_ID" />
                            <input type="hidden" name="comment_parent" id="comment_parent" defaultValue={0} />
                            </p>
                        </form>
                        </div>
                    </div> */}
                </div>
                <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
            </div>
        );
    }
}

export default SingleContent001;