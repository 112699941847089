import React, { Component } from 'react';

class SingleContent001 extends Component {
    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    <article className="hentry data-effect">
                        <div className="post-media data-effect-item has-effect-icon offset-v-25 offset-h-24 clerafix"> 
                        {/* 840x385 */}
                            <a href="/blog-single"><img src="/assets/img/gallery/2a6fe95339006a030e3ca603afbbcaa51c409a66884987a27e5360f4bf8ea667.jpg" alt="altimage" /></a>
                            <div className="post-calendar">
                                <span className="inner">
                                    <span className="entry-calendar">
                                        <span className="day">07</span>
                                        <span className="month">JUL</span>
                                    </span>
                                </span>
                            </div>
                            <div className="overlay-effect bg-color-1" />
                            <div className="elm-a">
                                <a href="/blog-single" className="icon-1" />
                            </div>
                        </div>
                        <div className="post-content-wrap clearfix">
                            <h2 className="post-title">
                                <span className="post-title-inner">
                                    <a href="/blog-single">OSHA VIOLATION? GET BACK IN COMPLIANCE IN 3 FAST STEPS</a>
                                </span>
                            </h2>
                            <div className="post-meta">
                                <div className="post-meta-content">
                                    <div className="post-meta-content-inner">
                                        <span className="post-date item"><span className="inner"><span className="entry-date">July
                                                    07, 2024</span></span></span>
                                        <span className="post-by-author item"><span className="inner"><a href="#">By:
                                                    Frank Zeiler</a></span></span>
                                        {/* <span className="comment item"><span className="inner"><a href="#">3
                                                    Comments</a></span></span> */}
                                    </div>
                                </div>
                            </div>
                            <div className="post-content post-excerpt margin-bottom-43">
                                <p className="line-height-27 letter-spacing-005">
                                <h3>How many $14,502 fines can your business afford before it is bankrupt?</h3>
                                </p>
                                <p>
                                The Occupational Safety and Health Administration ("OSHA") is an agency of the U.S. Department of Labor.
                                Their agents, called "compliance officers," may stop by your business or factory unannounced to inspect your operations and workspace.
                                </p>
                                <p>
                                <b>Fines for violations can range from $14,502 to $145,027 per violation, and $14,502 PER DAY for failures continuing beyond
                                the abatement date. (Source: OSHA.gov Jan 13, 2022)</b>
                                </p>
                                <h3>
                                Protect your business against OSHA violations! 
                                </h3>
                                <p>
                                Linestar's team of Safety Systems experts will protect your business from these fines, and most importantly keep your workplace
                                and staff safe. We employ the highest standards of safety and bring with us decades of experience when assessing your factory or business.
                                </p>
                                <p>
                                Most commonly, we will show up with one or two people the same week you call us. We will analyze your machines, your floor space,
                                and what safety mechanisms you have in place. Sort of like an OSHA inspection without the risk of fines. We will walk around with
                                you or your designee and tell you in real time about anything that could result in a violation.
                                </p>
                                <p>
                                Then we will tell you how to solve it. Our specialty is in "Machine Guarding." That means setting up systems that get automatically
                                triggered whenever an unsafe condition is about to occur, and pre-empting it. Sometimes by cutting power to the machine. Or sometimes
                                by causing the machine to return to a safe condition.
                                </p>
                                <p>
                                To do this, we employ all kinds of advanced (yet affordable) equipment that you will be glad you have. Not only because it is going
                                to save you money in the long run, but because you will have peace of mind knowing that your employees and equipment are going to
                                remain intact and in good working condition for a long time.
                                </p>
                                <div className="box-excerpt clearfix">
                                    <div className="thumb">
                                    {/* 251x196 */}
                                        <img src="assets/img/gallery/45408e2f6fddee6aa6feb561f3b2262d05f9a14f357634d90a49e79a2cf52b8a.jpg" alt="altimage" />
                                    </div>
                                    <div className="text">
                                        <p className="line-height-27 letter-spacing-005">
                                            <b>Popular examples of equipment you will see us add include:</b>
                                    <ul>
                                        <li>Safety Light Curtains</li>
                                        <li>Safety Area Scanners</li>
                                        <li>Safety Muting Systems</li>
                                        <li>Safety Controllers</li>
                                    </ul>
                                In our most advanced scenarios, we will install a special computer dedicated to monitoring all of your machine operations for safety.
                                We call these, "Safety Control Systems." 
                                </p>
                                    </div>
                                </div>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                <p>
                                <b>Reasons customers call us:</b>
                                    <ul>
                                        <li>Received an OSHA violation (50%)</li>
                                        <li>Recognize they have an unsafe condition (50%)</li>
                                    </ul>
                                </p>
                                <h3>What Linestar Can Do For You</h3>
                                </p>
                                <p>
                                We recently had a customer where we installed a safety light curtain. That is like a 2-foot tall beam that has lights going across it,
                                and sensors on the other side. Then we trigger some behavior when the beam of light is interrupted (for example, by a hand passing through
                                it). We change the machine control code so that your machine turns off when the safety light curtain is interrupted. This simple and
                                inexpensive solution keeps your staff safe from injuries, increases productivity in the workplace, and protects you from costly fines.
                                </p>
                                <p>
                                Another customer, we are adding what's called a, "Muting System." So that the operator can reach into the robotic work cells. As long as
                                the robot is back away from the operator. Pretty much all robot work cells, a person is going to be loading and unloading parts from. So
                                they have to be able to access the robotic "zone" to be able to load and unload parts. So the robot has to be either completely shut off,
                                or it has to be back in a position that is known to be safe. So we install censors that say, "The robot is back in its home position (or
                                safe position) so they can reach in and change parts out." So that's basically called Safety Muting System.
                                </p>
                                <h3>
                                Area Scanners 
                                </h3>
                                <p>
                                A Safety Area Scanner is like a little device that sits usually on the floor, and you can program it to where it is okay for you to break
                                certain zones of it, but there are other zones you can't break. If you go inside the danger zone, the robot will shut off if it is in a
                                dangerous position or moving at an unsafe speed.
                                </p>
                                <p>
                                According to OSHA.gov, "The employer may legally require OSHA to go to court to seek an inspection warrant before allowing entry. This can
                                delay the inspection."
                                </p>
                                <p>
                                Don't wait until a strange man is standing at your door with a clipboard showing you his badge. Call us now!
                                </p>
                            </div>
                            <div className="post-tags-socials clearfix">
                                <div className="post-tags ">
                                    <span>Tags :</span>
                                    <a href="/services/microgrid-services">Microgrids</a>
                                    <a href="/services/microgrid-services">Energy Storage</a>
                                    {/* <a href="/services/microgrid-services">Renewable</a> */}
                                </div>
                                <div className="post-socials ">
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=linestarautomation.com" className="facebook"><span className="fa fa-facebook-square" /></a>
                                    <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" className="twitter"><span className="fa fa-twitter" /></a>
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=linestarautomation.com" className="linkedin"><span className="fa fa-linkedin-square" /></a>
                                    {/* <a href="#" className="pinterest"><span className="fa fa-pinterest-p" /></a> */}
                                </div>
                            </div>
                        </div>
                    </article>
                    <div className="themesflat-pagination clearfix no-border padding-top-17">
                        <ul className="page-prev-next">
                            <li>
                                <a href="#" className="prev">
                                    Previous Article
                                </a>
                            </li>
                            <li className="text-right">
                                <a href="#" className="next">
                                    Next Article
                                </a>
                            </li>
                        </ul>
                    </div>
                    {/* <div id="comments" className="comments-area">
                        <h2 className="comments-title">3 COMMENTS</h2>
                        <ol className="comment-list">
                            <li className="comment">
                                <article className="comment-wrap clearfix">
                                    <div className="gravatar"><img alt="altimage" src="assets/img/gallery/24e4066d6808a26351f98397351f2e8c1f397db4903e7d7df703620375cb9a53.jpg" />
                                    </div>
                                    <div className="comment-content">
                                        <div className="comment-meta">
                                            <h6 className="comment-author">Arthur Murray</h6>
                                            <span className="comment-time">December 11, 2022 - at 11:40 am</span>
                                            <span className="comment-reply"><a className="comment-reply-a"
                                                    to="#">REPLY</a></span>
                                        </div>
                                        <div className="comment-text">
                                            <p>I am looking to build my own microgrid in Georgia. Anything I should know first
                                                before I start hooking things up to the grid? Thanks.
                                                 </p>
                                        </div>
                                    </div>
                                </article>
                                <ul className="children">
                                    <li className="comment">
                                        <article className="comment-wrap clearfix">
                                            <div className="gravatar"><img alt="altimage"
                                                    src="assets/img/gallery/bd1aa833b4f7d4e69add6c917c7294042a74c89577232586143cd5396df6d26c.jpg" /></div>
                                            <div className="comment-content">
                                                <div className="comment-meta">
                                                    <h6 className="comment-author">José Ortega</h6>
                                                    <span className="comment-time"> December 11, 2022 - at 12:31 am</span>
                                                    <span className="comment-reply"><a className="comment-reply-a"
                                                            to="#">REPLY</a></span>
                                                </div>
                                                <div className="comment-text">
                                                    <p>Call your local electric and gas companies and ask them first before you do
                                                        anything regarding hooking into the grid! They may be able to help you, and
                                                        they may help you avoid hurting yourself!
                                                        </p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                </ul>
                            </li>
                            <li className="comment">
                                <article className="comment-wrap  clearfix">
                                    <div className="gravatar"><img alt="altimage" src="assets/img/gallery/bed2ad3e1ed76ffd49e8a6b380178c6481ea5076ef26eb85d77c5ba065e02900.jpg" />
                                    </div>
                                    <div className="comment-content">
                                        <div className="comment-meta">
                                            <h6 className="comment-author">Michael Bowman</h6>
                                            <span className="comment-time">December 12, 2022 - at 4:28 pm</span>
                                            <span className="comment-reply"><a className="comment-reply-a"
                                                    to="#">REPLY</a></span>
                                        </div>
                                        <div className="comment-text">
                                            <p>How many kilowatt hours do I need to prepare for a medium-sized house, about 
                                                2300 sq ft? Do I need extra battery backups, or what? I am putting solar panels
                                                on the garage roof and want to be totally off the grid.</p>
                                        </div>
                                    </div>
                                </article>
                            </li>
                        </ol>
                        <div id="respond" className="comment-respond">
                            <h3 id="reply-title" className="comment-reply-title margin-bottom-31">Leave a Comment</h3>
                            <form action="#" method="post" id="commentform" className="comment-form">
                                <div className="text-wrap clearfix">
                                    <fieldset className="name-wrap">
                                        <input type="text" id="author" className="tb-my-input" name="author" tabIndex={1}
                                            placeholder="Name*" defaultValue={""} size={32} aria-required="true"/>
                                    </fieldset>
                                    <fieldset className="email-wrap">
                                        <input type="email" id="email" className="tb-my-input" name="email" tabIndex={2}
                                            placeholder="Email*" defaultValue={""} size={32} aria-required="true" />
                                    </fieldset>
                                </div>
                                <fieldset className="message-wrap">
                                    <textarea id="comment-message" name="comment" rows={8} tabIndex={4} placeholder="Message*"
                                        aria-required="true" defaultValue={""} />
                                    </fieldset>
                            <p className="form-submit">
                            <input name="submit" type="submit" id="comment-reply" className="submit" defaultValue="SEND US" />
                            <input type="hidden" name="comment_post_ID" defaultValue={100} id="comment_post_ID" />
                            <input type="hidden" name="comment_parent" id="comment_parent" defaultValue={0} />
                            </p>
                        </form>
                        </div>
                    </div> */}
                </div>
                <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
            </div>
        );
    }
}

export default SingleContent001;