import React, { Component } from 'react';

class SingleContent001 extends Component {
    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    <article className="hentry data-effect">
                        <div className="post-media data-effect-item has-effect-icon offset-v-25 offset-h-24 clerafix"> 
                        {/* 840x385 */}
                            <a href="/blog-single"><img src="/assets/img/gallery/0be76ef593a32bea1bd7a6b442e4080a8028438e041836fed1b06906abf63a5b.jpg" alt="altimage" /></a>
                            <div className="post-calendar">
                                <span className="inner">
                                    <span className="entry-calendar">
                                        <span className="day">23</span>
                                        <span className="month">JUN</span>
                                    </span>
                                </span>
                            </div>
                            <div className="overlay-effect bg-color-1" />
                            <div className="elm-a">
                                <a href="/blog-single" className="icon-1" />
                            </div>
                        </div>
                        <div className="post-content-wrap clearfix">
                            <h2 className="post-title">
                                <span className="post-title-inner">
                                    <a href="/blog-single">WHAT WE SAW AT THE ROBOTICS CONVENTION IN DALLAS TEXAS</a>
                                </span>
                            </h2>
                            <div className="post-meta">
                                <div className="post-meta-content">
                                    <div className="post-meta-content-inner">
                                        <span className="post-date item"><span className="inner"><span className="entry-date">June
                                                    23, 2024</span></span></span>
                                        <span className="post-by-author item"><span className="inner"><a href="#">By:
                                                    Frank Zeiler</a></span></span>
                                        {/* <span className="comment item"><span className="inner"><a href="#">3
                                                    Comments</a></span></span> */}
                                    </div>
                                </div>
                            </div>
                            <div className="post-content post-excerpt margin-bottom-43">
                                <p className="line-height-27 letter-spacing-005">
                                    The largest robotics competition in the world takes place every year in Dallas, Texas. Middle School and high school students compete
                                    to showcase their skills and compete against one another for the grand prize. The event is called the VEX Robotics Competition and 
                                    is sponsored by tons of companies including Google, Texas Instruments, Tesla, and more.
                                    </p>
                                <div className="box-excerpt clearfix">
                                    <div className="thumb">
                                    {/* 251x196 */}
                                        <img src="assets/img/gallery/45408e2f6fddee6aa6feb561f3b2262d05f9a14f357634d90a49e79a2cf52b8a.jpg" alt="altimage" />
                                    </div>
                                    <div className="text">
                                        <p className="line-height-27 letter-spacing-005">
                                            Linestar stops by to see the impressive strategy and teamwork of over 3,000 teams from 36 nations. The events take place at the 
                                            Kay Bailey Hutchison Convention Center in Dallas, Texas.
                                            </p>
                                    </div>
                                </div>
                                <p className="line-height-27 no-margin letter-spacing-01">
                                    Events feature robotics competitions, aerial drone competitions, virtual aerial drones, factory automation competitions, and more.
                                    </p>
                            </div>
                            <div className="post-tags-socials clearfix">
                                <div className="post-tags ">
                                    <span>Tags :</span>
                                    <a href="/services/microgrid-services">Microgrids</a>
                                    <a href="/services/microgrid-services">Energy Storage</a>
                                    {/* <a href="/services/microgrid-services">Renewable</a> */}
                                </div>
                                <div className="post-socials ">
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=linestarautomation.com" className="facebook"><span className="fa fa-facebook-square" /></a>
                                    <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" className="twitter"><span className="fa fa-twitter" /></a>
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=linestarautomation.com" className="linkedin"><span className="fa fa-linkedin-square" /></a>
                                    {/* <a href="#" className="pinterest"><span className="fa fa-pinterest-p" /></a> */}
                                </div>
                            </div>
                        </div>
                    </article>
                    <div className="themesflat-pagination clearfix no-border padding-top-17">
                        <ul className="page-prev-next">
                            <li>
                                <a href="#" className="prev">
                                    Previous Article
                                </a>
                            </li>
                            <li className="text-right">
                                <a href="#" className="next">
                                    Next Article
                                </a>
                            </li>
                        </ul>
                    </div>
                    {/* <div id="comments" className="comments-area">
                        <h2 className="comments-title">3 COMMENTS</h2>
                        <ol className="comment-list">
                            <li className="comment">
                                <article className="comment-wrap clearfix">
                                    <div className="gravatar"><img alt="altimage" src="assets/img/gallery/24e4066d6808a26351f98397351f2e8c1f397db4903e7d7df703620375cb9a53.jpg" />
                                    </div>
                                    <div className="comment-content">
                                        <div className="comment-meta">
                                            <h6 className="comment-author">Arthur Murray</h6>
                                            <span className="comment-time">December 11, 2022 - at 11:40 am</span>
                                            <span className="comment-reply"><a className="comment-reply-a"
                                                    to="#">REPLY</a></span>
                                        </div>
                                        <div className="comment-text">
                                            <p>I am looking to build my own microgrid in Georgia. Anything I should know first
                                                before I start hooking things up to the grid? Thanks.
                                                 </p>
                                        </div>
                                    </div>
                                </article>
                                <ul className="children">
                                    <li className="comment">
                                        <article className="comment-wrap clearfix">
                                            <div className="gravatar"><img alt="altimage"
                                                    src="assets/img/gallery/bd1aa833b4f7d4e69add6c917c7294042a74c89577232586143cd5396df6d26c.jpg" /></div>
                                            <div className="comment-content">
                                                <div className="comment-meta">
                                                    <h6 className="comment-author">José Ortega</h6>
                                                    <span className="comment-time"> December 11, 2022 - at 12:31 am</span>
                                                    <span className="comment-reply"><a className="comment-reply-a"
                                                            to="#">REPLY</a></span>
                                                </div>
                                                <div className="comment-text">
                                                    <p>Call your local electric and gas companies and ask them first before you do
                                                        anything regarding hooking into the grid! They may be able to help you, and
                                                        they may help you avoid hurting yourself!
                                                        </p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                </ul>
                            </li>
                            <li className="comment">
                                <article className="comment-wrap  clearfix">
                                    <div className="gravatar"><img alt="altimage" src="assets/img/gallery/bed2ad3e1ed76ffd49e8a6b380178c6481ea5076ef26eb85d77c5ba065e02900.jpg" />
                                    </div>
                                    <div className="comment-content">
                                        <div className="comment-meta">
                                            <h6 className="comment-author">Michael Bowman</h6>
                                            <span className="comment-time">December 12, 2022 - at 4:28 pm</span>
                                            <span className="comment-reply"><a className="comment-reply-a"
                                                    to="#">REPLY</a></span>
                                        </div>
                                        <div className="comment-text">
                                            <p>How many kilowatt hours do I need to prepare for a medium-sized house, about 
                                                2300 sq ft? Do I need extra battery backups, or what? I am putting solar panels
                                                on the garage roof and want to be totally off the grid.</p>
                                        </div>
                                    </div>
                                </article>
                            </li>
                        </ol>
                        <div id="respond" className="comment-respond">
                            <h3 id="reply-title" className="comment-reply-title margin-bottom-31">Leave a Comment</h3>
                            <form action="#" method="post" id="commentform" className="comment-form">
                                <div className="text-wrap clearfix">
                                    <fieldset className="name-wrap">
                                        <input type="text" id="author" className="tb-my-input" name="author" tabIndex={1}
                                            placeholder="Name*" defaultValue={""} size={32} aria-required="true"/>
                                    </fieldset>
                                    <fieldset className="email-wrap">
                                        <input type="email" id="email" className="tb-my-input" name="email" tabIndex={2}
                                            placeholder="Email*" defaultValue={""} size={32} aria-required="true" />
                                    </fieldset>
                                </div>
                                <fieldset className="message-wrap">
                                    <textarea id="comment-message" name="comment" rows={8} tabIndex={4} placeholder="Message*"
                                        aria-required="true" defaultValue={""} />
                                    </fieldset>
                            <p className="form-submit">
                            <input name="submit" type="submit" id="comment-reply" className="submit" defaultValue="SEND US" />
                            <input type="hidden" name="comment_post_ID" defaultValue={100} id="comment_post_ID" />
                            <input type="hidden" name="comment_parent" id="comment_parent" defaultValue={0} />
                            </p>
                        </form>
                        </div>
                    </div> */}
                </div>
                <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
            </div>
        );
    }
}

export default SingleContent001;