const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
        namesub: [
            {
                // id: 1,
                // sub: 'Home',
                // links: '/'
            // },
            // {
            //     id: 2,
            //     sub: 'Home 02',
            //     links: '/home-02'
            }
        ]
    },
    {
        id: 2,
        name: 'About Us',
        links: '/about-us',
        namesub: [
            {
                id: 1,
                sub: 'Company Overview',
                links: '/about-us'
            },
            {
                id: 2,
                sub: 'Company Details',
                links: '/about-detail'
            },
            {
                id: 3,
                sub: 'Leadership',
                links: '/about-team'
            },
            {
                id: 4,
                sub: 'Careers',
                links: '/about-careers'
            }
        ],
    },{
        id: 3,
        name: 'Services',
        links: '/services',
        namesub: [
            {
                id: 1,
                sub: 'Control Systems',
                links: '/services/control-systems'
            },
            {
                id: 2,
                sub: 'Safety Systems',
                links: '/services/safety-systems'
            },
            {
                id: 3,
                sub: 'Microgrids',
                links: '/services/microgrid-controls'
            },
            {
                id: 4,
                sub: 'More',
                links: '/services'
            }
        ],
    },
    {
        id: 4,
        name: 'Projects',
        links: '/projects',
        namesub: [
            {
                id: 1,
                sub: 'Power Distribution',
                links: '/projects/otto'
            },
            {
                id: 2,
                sub: 'Control Systems',
                links: '/projects/3d-printer'
            },
            {
                id: 3,
                sub: 'Microgrids',
                links: '/projects/sol-ark'
            },
            {
                id: 4,
                sub: 'All',
                links: '/projects'
            }
        ],
    },
    // {
    //     id: 5,
    //     name: 'Page',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Page Testimonial',
    //             links: '/page-testimonial'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Page Pricing',
    //             links: '/page-pricing'
    //         }
    //     ],
    // },
    {
        id: 6,
        name: 'Blog',
        links: '/blog',
        namesub: [
            {
                // id: 1,
                // sub: 'Blog',
                // links: '/blog'
            },
            // {
            //     id: 2,
            //     sub: 'Blog Single',
            //     links: '/blog-single'
            // }
        ],
    },
    {
        id: 7,
        name: 'Contact',
        links: '/contact-us',
        namesub: [
            // {
            //     id: 1,
            //     sub: 'Contact 1',
            //     links: '/contact-01'
            // },
            {
                // id: 2,
                // sub: 'Contact',
                // links: '/contact-us'
            }
        ],
    }
]

export default menus;