import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
class SiteContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: [
                {
                    id: 1,
                    text01: 'We send out one or two people to analyze your machine safety, floor space utilization, and signage and markings. We take measurements and learn what safety mechanisms you already have in place.',
                    text02: 'It is sort of like an OSHA inspection without the risk of fines. We tell you in real-time about anything that could pose a safety hazard or result in a costly violation.'
                },
                {
                    id: 2,
                    text01: 'Then we will tell you how to solve it. Our specialty is in "Machine Guarding." That means setting up systems that get automatically triggered whenever an unsafe condition is about to occur, and pre-empting it.',
                    text02: 'Sometimes this means cutting power to the dangerous machine. Other times it means causing the machine to return to a safe condition.'
                },
                {
                    id: 3,
                    text01: 'Our installation team works quickly to provide you with all kinds of advanced (yet affordable) equipment that you will be glad you have.',
                    text02: 'Not only because it is going to save you money in the long run, but because you will have peace of mind knowing that your employees and equipment are going to remain intact and in good working condition for a long time.'
                }
            ]
        }
    }
    
    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                    <div className="themesflat-row equalize sm-equalize-auto clearfix">
                        <div className="span_1_of_6 bg-f7f">
                            <div className="themesflat-spacer clearfix" data-desktop={60} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-content-box clearfix" data-margin="0 10px 0 43px"
                                data-mobilemargin="0 15px 0 15px">
                                <div className="themesflat-headings style-2 clearfix">
                                    <div className="sup-heading">SPECIAL SERVICES</div>
                                    <h2 className="heading font-size-28 line-height-39">SAFETY SYSTEMS</h2>
                                    <div className="sep has-width w80 accent-bg margin-top-20 clearfix" />
                                    <p className="sub-heading margin-top-33 line-height-24">Got an OSHA violation or unsafe condition? 
                                        We can help. Linestar's Safety Systems experts have the right solution for your business, no matter
                                        how large or small. We are waiting for your call. Don't wait until someone gets hurt! 
                                        </p>
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={56} data-mobile={56} data-smobile={56} />
                        </div>
                        <div className="span_1_of_6 half-background style-2">
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={39} data-smobile={39} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title">How many $145,027 OSHA fines can your business afford before it is bankrupt?</h5>
                        <p> 
                            The Occupational Safety and Health Administration ("OSHA") is an agency of
                            the U.S. Department of Labor. Their agents, called "compliance officers," may stop by 
                            your business or factory unannounced to inspect your operations and workspace.
                        </p>
                        <p>
                            Fines for violations can range from <b>$14,502 to $145,027 per violation</b>, and <b>$14,502 <i>PER 
                            DAY</i></b> for failures continuing beyond the abatement date. <i>(Source: OSHA.gov Jan 13, 2022)</i>
                        </p>
                        <p>
                            
                        </p>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <div className="item">
                            <div className="inner">
                                <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10} data-smobile={10} />
                                <h5 className="title">LINESTAR CAN SAVE YOU:</h5>
                                    <p>
                                        Linestar's Safety Systems experts protect your business from these fines, 
                                        and most importantly keep your workplace and staff safe. We employ the highest 
                                        standards of safety and have decades of experience securing factories and businesses.
                                    </p>
                                <div className="themesflat-spacer clearfix" data-desktop={8} data-mobile={8} data-smobile={8} />
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">Safety Light Curtains</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">Safety Area Scanners</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">Safety Muting Systems</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">Safety Control Systems</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="item">
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={35} />
                            <div className="themesflat-content-box clearfix" data-margin="0 0 0 11px" data-mobilemargin="0 0 0 0">
                                <div className="inner pd35 bg-ffc">
                                    <h5 className="title text-white line-height-24 padding-left-7">CHECK OUT SOME OF OUR FANTASTIC  
                                        SAFETY SYSTEMS WORK FOR OTHER CLIENTS</h5>
                                    <div className="themesflat-spacer clearfix" data-desktop={16} data-mobile={16}
                                        data-smobile={16} />
                                    <div className="button-wrap has-icon icon-right size-14">
                                    <a 
                                            href="../../../../assets/img/gallery/6269b81990a346bfb51ca82ced895e337e74ce2b492831b1d31885aa7f23f9e5.pdf"
                                            className="themesflat-button bg-white color-333 w100 font-weight-400 no-letter-spacing pd26"
                                            download="Linestar.pdf"
                                        >
                                            <span>Download .PDF 
                                                <span className="icon">
                                                    <i className="fa fa-file-pdf-o" />
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                    <div className="themesflat-spacer clearfix" data-desktop={6} data-mobile={6} data-smobile={6} />
                                    <div className="button-wrap has-icon icon-right size-14">
                                        <a 
                                            href="../../../../assets/img/gallery/9ddbf9ae73b482ff90953aed2a15ee578d9455f0e3474a98c85faf287bc926d6.docx"
                                            className="themesflat-button bg-white color-333 w100 font-weight-400 no-letter-spacing pd26 "
                                            download="Linestar.docx"
                                        >
                                            <span>Download .DOC 
                                                <span className="icon">
                                                    <i className="fa fa-file-word-o" />
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title no-letter-spacing">CONTACT US TO PROTECT YOUR BUSINESS TODAY</h5>
                        <div className="themesflat-spacer clearfix" data-desktop={27} data-mobile={27} data-smobile={27} />
                        <div className="themesflat-tabs style-2 title-w170 clearfix">
                            <ul className="tab-title clearfix ">
                                <li className="item-title active">
                                    <span className="inner">INSPECTION</span>
                                </li>
                                <li className="item-title">
                                    <span className="inner">SOLUTION</span>
                                </li>
                                <li className="item-title">
                                    <span className="inner">INSTALLATION</span>
                                </li>
                            </ul>
                            <div className="tab-content-wrap clearfix">
                                {
                                    this.state.tabs.map(data =>(
                                        <div className="tab-content" key={data.id}>
                                            <div className="item-content">
                                                <p>{data.text01}</p>
                                                <p>{data.text02}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    
                    <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={39} data-smobile={39} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title">SAFETY LIGHT CURTAINS</h5>
                        <p> 
                            We recently had a customer where we installed a safety light curtain. That is like a 2-foot tall beam that has lights 
                            going across it, and sensors on the other side. Then we trigger some behavior when the beam of light is interrupted (for 
                            example, by a hand passing through it). We change the machine control code so that your machine turns off when the safety 
                            light curtain is interrupted. This simple and inexpensive solution keeps your staff safe from injuries, increases productivity 
                            in the workplace, and protects you from costly fines.                       
                        </p>
                    </div>

                    <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={39} data-smobile={39} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title">SAFETY MUTING SYSTEMS</h5>
                        <p> 
                            Another customer, we are adding what's called a, "Muting System." So that the operator can reach into the robotic work cells. 
                            As long as the robot is back away from the operator. Pretty much all robot work cells, a person is going to be loading and 
                            unloading parts from. So they have to be able to access the robotic "zone" to be able to load and unload parts. So the robot 
                            has to be either completely shut off, or it has to be back in a position that is known to be safe. So we install censors that say, 
                            "The robot is back in its home position (or safe position) so they can reach in and change parts out." So that's basically called 
                            a Safety Muting System.
                        </p>
                    </div>

                    <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={39} data-smobile={39} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title">SAFETY AREA SCANNERS</h5>
                        <p> 
                            A Safety Area Scanner is like a little device that sits usually on the floor, and you can program it to where it is okay for you to 
                            break certain zones of it, but there are other zones you can't break. If you go inside the danger zone, the robot will shut off if 
                            it is in a dangerous position or moving at an unsafe speed.
                        </p>
                    </div>
                    

                    <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={39} data-smobile={39} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title">Don't wait until a man with a clipboard and badge is at your door. Call us now!</h5>
                        
                    </div>

                    <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                </div>
            </div>
        );
    }
}

export default SiteContent;