import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/ceecf7074213b6e3a50050d6874782f5670f10c701e2fe61e99f2de9002dada7.jpg" alt="zebra-imaging" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/gallery-2-800x450.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/gallery-3-800x450.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/gallery-4-800x450.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">POWER, LASER, AND MOTION CONTROLS FOR HOLOGRAPHIC IMAGE PROCESSING MACHINE</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">This was one of Linestar's first big projects. We designed and built this high-precision
                        optomechanical holographic imaging system for U.S. Military applications. To serve this customer's particular needs, 
                        our team designed a unique motion control and power distribution system to automate a laser and get accurate readings 
                        over and over for years without failure or recalibration.
                    </p>
                    <p>This helped the military to better analyze the lay of the land of remote territories before deploying soldiers to
                        locations abroad. The holographic products that this machine manufactures works particularly well inside of tanks. The 
                        user could roll up these maps and travel with them in a compact space. Then they unravel the holographic maps and shine 
                        a flashlight on them to illuminate the 3D visualization of the area's topography. </p>
                        
                    <p>This machine earned about $1000 per output. It had a terrific return on investment.
                    </p>
                </div>
            </div>
        );
    }
}

export default ContentProject;