import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/1dd92efba93050abbbea277392582caeeb687966b5e92cd8f5e09244c124e687.jpg" alt="High Pressure Drill Bit Tester Bottom" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/ec77f55459609ff651dd5b0a9257dc22b80d03d31988fecf6d31c1ce7f4bc3fd.jpg" alt="High Pressure Drill Bit Tester Top Chamber" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/73a26bd51757e7c1d76dbfdbdca46433f7be49124c57fd2f79b3fa9a73521548.jpg" alt="Control Enclosure for Drill Tit Tester Top" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/bbe4f951314e9e4d8ab7c6dfff7ffb65c23fa75bf59125cdbfb064185e0a938c.jpg" alt="Control Enclosure for Drill Tit Tester Bottom" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">HIGH PRESSURE DRILL BIT TESTER</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">This is a system for testing drill bits under high pressure. That silver looking cylinder on top is 
                        a pressure chamber that can hold up to 20,000 PSI of pressure. Down below it is a mechanical drive that is a motor and a gearbox
                        that drives a shaft through the center of it and spins a rock sample that they use to test the drill bits cutting into rock. 
                    </p>
                    <p>
                        It has a 100KW Servo Motor. A Servo is something that basically has a command and feedback. It is kind of a control system term. 
                        That's about the size of a car engine almost, but it's an electric motor.
                    </p>
                    <p>
                        It is rather unique in that there isn't really anything like this around, and so the company needed to design it from scratch. The 
                        cost of something that can hold 20,000 PSI is pretty significant. Notice the picture attached is the Control Enclosure for the Drill 
                        Bit Test System.
                    </p>
                    <p>
                        Up on the very top part of the system is the power distribution. It had a 400 Amp at 480 Volt 3-phase power distribution. The second 
                        row of stuff is power supplies and circuit breakers. The next row down is the PLC. Programmable Logic Controller. The big thing on the 
                        bottom on the right is the Servo drive. It is like the amplifier or power controller for the motor. The big switch on the side is the 
                        disconnect switch. It is interlocked with the door so you can't open the door without turning the power off. That is a safety mechanism. 
                    </p>
                </div>
            </div>
        );
    }
}

export default ContentProject;