import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/d93aedb3e10a8ff900e428a909fad7ed1b4421c52a880c64c85ce9fa7544541e.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/6e0c40c05991212cb4c542a44337c16929f18df65f5b8fc99e175406616cce04.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/0360964d48ca0f997a03d0d8a6e0be8f0f6b0fa57b8e77273bcc32f3f253a030.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="../../../../assets/img/gallery/77ecdf207c409336c400f49df977f03b7f1d39a99472f33839a2739132f39d0d.jpg" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">POWER DISTRIBUTION, SAFETY, AND PROCESS CONTROL FOR COMPACT HIGH PERFORMANCE COMPUTING (HPC)</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">
                        This machine does something really unique. 
                    </p>
                    <p>    
                        It sits in a 16 sq ft footprint, and it can house up to 2048 cores for ultra-high 
                        performance computing in a small package. We made it so this thing can swap out one server from a rack and automatically replace 
                        it with another one without shutting down and requiring manual labor. This helped the data center increase their productivity 
                        and reliability in a hugely scalable way.
                    </p>
                    <p>    
                        The client had all kinds of special requirements for keeping these things safe and making sure power is distributed across its 
                        various moving parts properly. It is stuffed into a tightly compact space, and this customer needed us to design and implement 
                        motion controls, safety, and power distribution so that this thing can run 24/7/365 without fail. 
                    </p>
                    <p>
                         These machines are highly efficient, and we made sure they can run, stay safe, and last a long time making money for our client!
                    </p>
                </div>
            </div>
        );
    }
}

export default ContentProject;